export const abbreviations = {
    id: 'ID',
    sku: 'SKU',
    no: 'No.',
    of: 'of',
    msrp: 'MSRP'
}

export function getAbbreviatedCapitalized(phrase){
    return phrase.split(/_|-| /)
            .map(word => {
                if(word in abbreviations) return abbreviations[word]
                return word.charAt(0).toUpperCase() + word.substring(1)
            })
            .join(' ')
}

export function formatPriceField(value){
    value.match(/^\d(\.\d)*$/)
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
}

export function generateProperLocationString({
    location_name,
    address1,
    address2,
    city,
    state,
    country,
    zip = ''
}){
    if(location_name === "") {
        return ''
    }
    if (zip === '') {
        if(Boolean(address2)){
            return `${location_name}-${address1},${address2},${city},${state},${country}`
        }
        return `${location_name}-${address1}, ${city}, ${state}, ${country}`
    } else {
        if(Boolean(address2)){
            return `${location_name}-${address1}, ${address2}, ${city}, ${state}, ${country}, ${zip}`
        }
        return `${location_name}-${address1}, ${city}, ${state}, ${country}, ${zip}`
    }
}

export const moduleWiseActivityApiMap = [
    {name: 'Assets', api: '/assets/get-all-activities', nameInActivity: 'asset', key: 'serial_number'},
    {name: 'Manufacturers', api: '/manufacturers/activities', nameInActivity: 'manufacturer', key: 'manufacturer_name'},
    {name: 'Asset Categories', api: '/category/activities', nameInActivity: 'asset_category', key: 'category_name'},
    {name: 'Models', api: '/models/activities', nameInActivity: 'model', key: 'model_name'},
    {name: 'Accessories', api: '/accessory/activities', nameInActivity: 'accessory', key: 'accessory_name'},
    {name: 'Model Attributes', api: '/model-custom-attributes/activities', nameInActivity: 'custom_attributes', key: 'attribute_name'},
    {name: 'Asset Statuses', api: '/asset-status/activities', nameInActivity: 'asset_status', key: 'status_name'},
    {name: 'Accessory Categories', api: '/accessory-category/activities', nameInActivity: 'accessory_category', key: 'category_name'},
    {name: 'Contracts', api: '/contracts/get-all-activities', nameInActivity: 'contract', key: 'contract_number'},
    {name: 'Payment Terms', api: '/payment-term/activities', nameInActivity: 'payment_term', key: 'payment_term_name'},
    {name: 'Contract Tags', api: '/contract-status/activities', nameInActivity: 'contract_status', key: 'status_name'},
    {name: 'Contract Types', api: '/contracts/activities', nameInActivity: 'contract_type', key: 'contract_type_name'},
    {name: 'Service Providers', api: '/service-provider/activities', nameInActivity: 'service_provider', key: 'service_provider_name'},
    {name: 'Service Level Agreements', api: '/service-level-agreement/activities', nameInActivity: 'service_level_agreement', key: 'service_level_agreement_name'},
    {name: 'Service Tickets', api: '/service/get-all-activities', nameInActivity: 'service_request', key: 'ticket_id'},
    {name: 'Roles', api: '/user-role/activities', nameInActivity: 'role', key: 'role_name'},
    {name: 'Locations', api: '/shipping/activities', nameInActivity: 'shipping', key: 'location_name'},
    {name: 'Location Types', api: '/location-type/activities', nameInActivity: 'location_type', key: 'location_type_name'},
    {name: 'Departments', api: '/department/activities', nameInActivity: 'department', key: 'department_name'},
    {name: 'Users', api: '/user/all-activities', nameInActivity: 'user', key: 'user_name'},
    // {name: 'Insights Log', api: '/zabbix/get-all-incident', nameInActivity: 'insights_log', key: 'problem_id'},
  ]