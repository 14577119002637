import _ from "@lodash";
import React, { useCallback , useEffect, useState, useRef } from "react";
import axios from "@fuse/utils/axios";
import Icon from "@material-ui/core/Icon";
import { Button, TextField , Row, Col , Popover, CardContent, Card } from "@material-ui/core";
import useToast from "@fuse/hooks/useToast";
import useRouter from "@fuse/hooks/useRouter";
import Tooltip from "@material-ui/core/Tooltip";

import { makeStyles , ThemeProvider } from "@material-ui/core/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import AlphabeticalSorting from "@fuse/utils/alphabeticalSort";
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer';
import {
  openFormViewerDialog,
  setFromData,
} from "../../assets/store/formViewDialogReducer";

import { useDispatch, useSelector } from "react-redux";
import { addAssetId } from "../../users/store/dialogReducer";
import { setfilterlist, setrowGroupModel } from "../../assets/store/filterParamsReducer";
import { setSerialNumberForServiceRequest } from "../../service-request/store/filterParamsReducer";
import { Stack, Box, Typography } from '@mui/material'
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { statusColors } from "@fuse/utils/colorName";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { isArray } from "lodash";
import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import Menu from '@mui/material/Menu';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import { setAssetQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import { Button, Icon, IconButton, makeStyles, Modal, Paper, TextField } from '@material-ui/core';

const useStyles = makeStyles({
  layoutRoot: {},
  head: {
    position: "sticky",
    top: 0,
  },
  root: {
    // minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardGen: {
    width: "20%",
    height: "100%",
  },
  searchbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    margin: "5px 5px 5px 0",
    height: "28px",
  },
  activeTab: {
    background: "rgba(107, 44, 87, 0.04);",
    borderRadius: "0",
  },
  buttonSquare: {
    borderRadius: "5px",
  },
  btn: {
    background: "#30C90A",
    width: "120px",
    borderRadius: "17px",
    color: "#fff",
  },
  icon: {
    fontSize: "18px",
    color: "#61656F",
    display: "inline-block",
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
      style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
      },
  },
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ListView(props) {
  const table_name = "ASSETS";
  const transform_words = {
    id: "ID",
    sku: "SKU",
    eol: "EOL",
    eosl: "EOSL",
    no: "No.",
  };
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");
  const [tabState, setTabState] = useState("information");
  const router = useRouter();
  // const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const [locationMenu, setLocationMenu] = useState([]);
  const [usersMenu, setUsersMenu] = useState([]);
  const [aggridParam, setAggridParam] = useState([])
  const [filterApplied, setFilterApplied] = useState(false);
  const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
  // const rowGroupPanelShow = "always";
  const tableHeaders = [
    "serial_no","asset_tag","name","user_name","status","contract_number",
    "contract_status","model_name","model_number","category","manufacturer",
    "location_name","address","notes","created_date","updated_date","source","network_status"
  ];
  const [modalData, setModalData] = useState(null);
  const [statusMenu, setStatusMenu] = useState([]);
  const [assetId, setAssetId] = useState(null);

  const [toggle, setToggle] = useState(true)
    const [toggleTwo, setToggleTwo] = useState(true)
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const dispatch = useDispatch();
    const toast = useToast(dispatch);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };
    const [allChangedataYes, setallChangedataYes] = useState('')
    const [allChangedataNo, setallChangedataNo] = useState('')
    const [open, setOpen] = useState(false)
    const handleOpenDialog = () => {
        setOpen(true)
    }
    const close = () => {
        setOpen(false)
    }
    const [groupByName, setGroupByName] = useState([]);

    const handleChangeOne = (event) => {
        const {
            target: { value },
        } = event;
        setGroupByName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const names = [
        'Assets Tag',
        'Serial Number',
        'Asset Name',
        'User',
        'Status',
        'Contact Number',
    ];
    const [age, setAge] = useState('');
    // const [openFilter, setOpenFilter] = useState(false);

    const handleChangeExport = (event) => {
        setAge(event.target.value);
    };
    const [filterEl, setFilterEl] = useState(null);
    const dropdownClick =(event) => {
        setFilterEl(filterEl ? null : event.currentTarget)
    }
    const handleFilterClose = () => {
        setFilterEl(null)
    }
    const openFilter = Boolean(filterEl);
    const id = 'simple-popper'
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
  const asset_quick_filter = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.asset_quick_filter
  );
  const asset_id_service_request_redirection = useSelector(
    (state) => state.assetsApp.filterParamsReducer.asset_id
  );
  const user_store_query_data = useSelector(
    (state) => state.usersApp.dialogReducer
  );
  // const filterModel = useSelector((state) => state.assetsApp.filterParamsReducer.filter);
  // Get Query params for redirection
  const manufacturer_id = router.query?.manufacturer_id ?? ''
	const model_id = router.query?.model_id ?? ''
	const category_id = router.query?.category_id ?? ''
	const contract_header_id = router.query?.contract_header_id ?? ''
  const location_id = router.query?.location_id ?? ''

  function TabButton(props) {
    const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
    const isActive = tab == tabState;
    return (
      <Button
        {...restProps}
        onClick={() => tabStateChange(tab)}
        className={restProps.className && isActive ? activeClass : ""}
      >
        {props.children}
      </Button>
    );
  }
  
  function Close() {
    setOpen(false);
    dispatch(addAssetId(null));
    setTabState("information");
  }

  function TabContainer(props) {
    const { tabState, tab } = props;
    const shouldRender = tab == tabState;
    return shouldRender ? props.children : <></>;
  }

  const generateHeaderLabel = (header) => {
    if (header == "serial_no") {
      return "Serial Number";
    }
    if (header == "name") {
      return "Asset Name";
    }
    if (header == "model") {
      return "Model Name";
    }
    if (header == "contract_no") {
      return "Contract Number";
    }
    if(header === "location_name") {
      return "Location";
    }

    if(header === "user_name") {
      return "User";
    }
    return header
      .split("_")
      .map((word) => {
        if (word in transform_words) return transform_words[word];
        return word.charAt(0).toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  function gotoServiceRequestPage(serialNumber) {
    if (!serialNumber) return;

    const asset_redirection = true;
    router.push(`/service-tickets`);
    dispatch(setSerialNumberForServiceRequest(serialNumber));
  }

  /** Get details for an individual asset to set in edit form*/
  async function getAssetDetails(asset_id){
    let asset = null
    const { data } = (await axios.get(`assets/${asset_id}`)).data

    asset = (()=>{
      return {
        id: data.id,
        name: data.asset_name,
        serial_no: data.serial_number,
        asset_tag: data.asset_tag,
        country_code: data.country_code,
        status_value: data.asset_status,
        model_id: data.model_id,
        shipping_location_details: data.shipping,
        notes: data.notes,
        note_style: data.note_style,
        image_name: data.image_name,
        preview_image_link: data.image_link,
        assigned_user_details: data.assigned_user,
        deployed_date: data.deployed_date,
        recovery_date: data.recovery_date,
        contract_header: data.contract_header,
        order_number: data.order_number,
        purchase_cost: data.purchase_cost,
        purchase_date: data.purchase_date,
        install_date: data.install_date,
        warranty_end_date: data.warranty_end_date
      }
    })()

    return asset;
  }

  const handelClone = async (data) => {
    const asset = await getAssetDetails(data.id)
    const copy = { ...asset };
    (copy.serial_no = ""), (copy.asset_tag = "");
    copy.assigned_user_details = null;
    copy.cloneItem = true;
    dispatch(setFromData(copy));
    dispatch(openFormViewerDialog());
  };

  /** Handle In-line editing */
  const onCellValueChanged = (e) => {
    /**
     * In line editable field
     * asset_name,user_name,asset_status,location
    */

    let entity_name = ''; let entity_value = null; const asset_id = e.data.id;

    /** Asset name */
    if (e.colDef.field == "name") {
      entity_name = 'asset_name'
      entity_value = e.newValue;

      if (!entity_value.length) {
        toast.error("Asset Name is required");
        refreshAssetList()
        return;
      }
    }

    /** Asset Status */
    if (e.colDef.field == "status") {
      refreshAssetList()
      // entity_name = 'status_id'
      // entity_value = statusMenu.find((item) => item.label == e.newValue?.split(",")[0]).id;
    }
    
    /** Address */
    if (e.colDef.field == "address") {
      entity_name = 'ship_to_locations_id'
      entity_value = locationMenu.find(
        (option) =>`${option.address1}, ${option.city}, ${option.state}, ${option.country}` ==
          e.newValue
      ).id;
    }

    /** User Name */
    if (e.colDef.field == "user_name") {
      entity_name = 'user_id'
      entity_value = usersMenu.find(
        (item) =>
          `${item.first_name} ${item.last_name}(${item.email})` == e.newValue
      ).id;
    }

    const payload = {
      entity_name,
      entity_value,
      asset_id
    };
    
    if(!entity_name.length) return

    axios
      .post(`/assets/inline-edit`, payload)
      .then((results) => {
        toast.success("Asset updated successfully.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelEdit = async (data) => {
    const asset = await getAssetDetails(data.id)
    dispatch(setFromData(asset));
    dispatch(openFormViewerDialog());
  };

  const handleClickOpen = (asset_id) => {
    setAssetId(asset_id);
    setOpen(true);
  };

  const handleArchive = (data) => {
    setModalData(data);
  };

  function handleClose() {
    setModalData(null);
  }

  function handleConfirm() {
    axios.delete(`assets/archive-asset/${modalData.id}`, {
      data: {
        assigned_user: modalData.assigned_user_details,
        assigned_contract: modalData.contract_header,
      },
    }).then(() => {
      handleClose();
      refreshAssetList()
      toast.success("Asset archived Successfully.");
    }).catch((err) => {
      console.log("err", err.response);
      if (
        err.response.data.message ===
        "Asset involved with a contract or assigned to an User"
      ) {
        toast.error(err.response.data.message);
      }
    });
  }

  function flattenAssets(assets){
		return assets.map((asset)=>{
			const {
				asset_table_id, serial_number, asset_tag, asset_name,user_name,
				asset_status_name, asset_status_color, contract_number, contract_status_name,
				contract_status_color, model_name, model_no, category_name, manufacturer_name,
				location_name, address, notes, created_at, updated_at,source,connection_status,
        childcount=undefined
			} = asset;
			return {
				id: asset_table_id,
				serial_no: serial_number,
				asset_tag,
				name: asset_name,				
        user_name,
				status: asset_status_name,
				asset_status_color,
        contract_number,
        contract_status: contract_status_name,
				contract_status_color,
				model_name,
				model_number: model_no,
				category: category_name,
				manufacturer: manufacturer_name,
				location_name,
        address,
				notes,
				created_date: dateReformatTo_mmm_dd_yyyy(created_at),
				updated_date: dateReformatTo_mmm_dd_yyyy(updated_at),
        source,
        network_status: connection_status,
        childCount: childcount
			}
		})
	}

  /** This useCallback function, when invoked, refreshes the asset list in the grid.
  *It checks if the grid API is available before attempting to refresh.
  */
  const refreshAssetList = useCallback(()=> {
    if(!gridRef.current) return
    gridRef.current.api.refreshServerSideStore({ purge: false })
  }, [gridRef]);

  /** This function handles the fetching of asset data from the server.
  It sends a POST request to the '/assets' endpoint with parameters for server-side data retrieval.
  available property in params object
    {
    "startRow": 0,
    "endRow": 100,
    "rowGroupCols": [],
    "valueCols": [],
    "pivotCols": [],
    "pivotMode": false,
    "groupKeys": [],
    "filterModel": {},
    "sortModel": []
    }
  */
  function onFetchAsset(params){
    const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
    const quick_filter = asset_quick_filter || ""
    axios.post(`/assets?manufacturer_id=${manufacturer_id}&model_id=${model_id}&category_id=${category_id}&contract_header_id=${contract_header_id}&location_id=${location_id}&quick_filter=${quick_filter}`,{
      dataSourceParams: {
        startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
      }
    }).then((res)=>{
      if(res.data.data.rows.length === 0) gridRef.current.api.showNoRowsOverlay();
      params.successCallback(flattenAssets(res.data.data.rows), res.data.data.lastRow);
    }).catch((err)=>{
      console.log('error',err)
      params.failCallback();
    })
  }

  /** This object represents the data source for the grid.
  *It defines a `getRows` function that is invoked by the grid to fetch rows from the server.
  */
  const datasource = {
    getRows(params) {
      // Call the function to fetch asset data from the server.
      onFetchAsset(params)
    }
  };

  const onGridReady = (params) => {
    setAggridParam(params)
    // setGridApi(params)
    if (router.query.report_name == undefined) {
      // params?.api.setServerSideDatasource(datasource);
      // loadInitialState(params)
      dispatch(setfilterlist({}));
      // dispatch(setrowGroupModel([]));
      loadInitialState(params);
      dispatch(setrowGroupModel([]));
    } 
    else {
      getReport(params);
      // loadInitialState(params)
    }
  };

  const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
    if (router.query.report_name == undefined) {
      saveView(rows);
      dispatch(setrowGroupModel(rows))
    }
		
	}

  const onColumnPinned = (params) => {
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
      dispatch(setrowGroupModel(rows))
    }
    
  };

  function onSortChanged(params){
    const rows = params.columnApi.getColumnState();
    if (router.query.report_name == undefined) {
      dispatch(setrowGroupModel(rows))
      saveView(rows);     
    }
    
  }

  function onColumnVisible(params) {
    const rows = params.columnApi.getColumnState();
    dispatch(setrowGroupModel(rows))
    const total_no_of_columns = rows.length;
    let total_hidden_columns = 0;
    rows.forEach((row) => {
      const { hide } = row;
      if (hide) {
        ++total_hidden_columns;
      }
    });
    if (total_hidden_columns < total_no_of_columns) {
      if (router.query.report_name == undefined) {
        saveView(rows);
      }
    }
  }

  function onFilterChanged(event) {
    const rows = event.api.getFilterModel();
    // stop rerender to show no overlay text
    dispatch(setfilterlist(rows));
    saveView("N/A", rows,gridRef.current.filterModelArr)
    // saveView(rows);
    // validation for no-overlay
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  function onColumnRowGroupChanged(event) {
    event.columns.map(item=>{
      item.indexOf(item.colId)
    })
    // const groupedColumns = gridRef.current.api.getRowGroupColumns();

    //   if (groupedColumns && groupedColumns.length > 0) {
    //     setRowGroupPanelShow('never')
    //   }
    if (router.query.report_name == undefined) {
      const rows = event?.columnApi?.getColumnState();
      // const first_row = rows[0];
      // if (first_row.colId != "ag-Grid-AutoColumn") {
      //   saveView(rows);
			// 	dispatch(setrowGroupModel(rows));
      // } else {
      //   dispatch(setrowGroupModel(rows));
      //   saveView(rows);
      // }
    }
  }

  function valueFormatter(params) {
    return dateReformatTo_mmm_dd_yyyy(params.value)
  }

  function getReport(event) {
    const {report_name} = router.query;
    const table_name = "asset";
    axios
      .get(
        `/reports/view-report?table_name=${table_name}&report_name=${report_name}`
      )
      .then((results) => {
        const model = JSON.parse(results.data.data.filter);
        event.api.setFilterModel(model);
        const row_model = JSON.parse(results.data.data.row_model);
        event.columnApi.setColumnState(row_model);
        event?.api.setServerSideDatasource(datasource);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

  function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
          const filter = JSON.parse(data?.filter);
          if(Object.keys(filter)?.length){
            setFilterApplied(true)
          }
          gridRef.current = {...gridRef.current,filterModelArr:filter}

          params?.columnApi.setColumnState(order);
          params?.api.setFilterModel(filter);
          // gridRef.current.api.setFilterModel(filterModel);
          params?.api.setServerSideDatasource(datasource);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        params?.api.setServerSideDatasource(datasource);
      })
  }

  const getChildCount = data => {
    // here child count is stored in the 'childCount' property
    return data.childCount;
  };

  const fetchStatus = () => {
    axios.get(`asset-status?type=hardware`).then((response) => {
      const status = AlphabeticalSorting(response.data.data, "status_name");
      setStatusMenu(
        status.map((item) => {
          return {
            id: item.id,
            label: item.status_name,
            color: item.status_color.code,
          };
        })
      );
    }).catch((err) => {
      console.log("136=>", err);
    });
  };

  const fetchLocationSuggestions = (value = "") => {
    axios.get(`assets/fetch-location-suggestion?value=${value}`).then((response) => {
      const locations = AlphabeticalSorting(
        response.data.data.locations,
        "location_name"
      );
      setLocationMenu(locations);
    }).catch((err) => {
      console.log("136=>", err);
    });
  };

  const fetchUserSuggestions = () => {
    axios.get("/assets/fetch-users-suggestion").then((response) => {
      const users = AlphabeticalSorting(response.data.data.users, "first_name");
      const user_info = [];
      users.forEach((item) => {
        user_info.push(`${item.first_name} ${item.last_name}(${item.email})`);
      });
      setUsersMenu(users);
    }).catch((err) => {
      console.log("164=>", err);
    });
  };

  useEffect(() => {
    if (user_store_query_data.asset_id) {
      setOpen(true);
      setAssetId(user_store_query_data.asset_id);
    }

    if (asset_id_service_request_redirection) {
      setOpen(true);
      setAssetId(asset_id_service_request_redirection);
    }
    fetchStatus();
    fetchLocationSuggestions();
    fetchUserSuggestions();
    dispatch(setServiceTicketsCount(null))
  }, []);

  useEffect(() => {
    if(!gridRef.current || asset_quick_filter == null ) return
    gridRef.current.api.setServerSideDatasource(datasource);
  }, [asset_quick_filter]);

  useEffect(() => {
    const onBtStopEditing = () => {
      gridRef?.current?.api?.stopEditing();
    };
    window.addEventListener("click", onBtStopEditing);
    return () => {
      window.removeEventListener("click", onBtStopEditing);
      dispatch(setAssetQuickFilter(null))
    };
  }, []);

  useEffect(()=>{
    if(filterApplied){
      toast.success("Filters have been applied")
    }
  },[filterApplied])

  /** Add refreshAssetList function as an Event Listener
   * to call it from Layout file or from any other places.
   */
  useEffect(()=>{
    setTimeout(()=>{
      window.addEventListener("refreshAssetList", refreshAssetList);
    },0)

    return () => {
      window.removeEventListener("refreshAssetList", refreshAssetList);
    };
  },[refreshAssetList]);

  useEffect(()=>{
    if(!router.params?.serial_no) return
    handleClickOpen(router.params.serial_no)
  },[])

  useEffect(() => {
    if (gridRef.current) {
      const {columnApi} = gridRef.current;
      // Now you can access columnApi and perform your actions
    }
  }, [gridRef]);

  const createGroup = ()=>{
    // var columnApi = (gridRef.current.columnApi)
    // console.log("columnApi", columnApi)
    // columnApi.columnModel.groupAutoColumns(['serial_no']);
    // let param = aggridParam?.columnApi.getColumnState()
  //   params?.columnApi.getColumnState()
  //   const params = aggridParam
  //   param.unshift({
  //     "colId": "ag-Grid-AutoColumn",
  //     "width": 200,
  //     "hide": false,
  //     "pinned": null,
  //     "sort": null,
  //     "sortIndex": null,
  //     "aggFunc": null,
  //     "rowGroup": false,
  //     "rowGroupIndex": null,
  //     "pivot": false,
  //     "pivotIndex": null,
  //     "flex": null
  // })
  
  // setAggridParam(params)
  // param?.columnApi.setColumnState(param);
  if (gridRef.current) {
    // setRowGroupPanelShow('always')
    // const columnApi = gridRef.current.columnApi;
    // gridRef.current.setHeaderHeight();
    // Your grouping logic
    // columnApi.setColumnVisible('serial_no', true);
    // columnApi.setColumnPinned('serial_no', 'left');
    // columnApi.setRowGroupColumns(['serial_no',]);

    if (gridRef.current) {
      // Get the grid API
      const gridApi = gridRef.current.api;

      // Define the filter model (replace 'A' with your filter value)
      const filterModel = {
        // and: [
          // {
            // or: [
            //   {
            //     serial_no: {
            //       type: 'contains',
            //       filter: '654',
            //     },
            //   },
            //   {
            //     user_name: {
            //       type: 'contains',
            //       filter: 'Una',
            //     },
            //   },
            // ],
          // }
          // {
          //   name: {
          //     type: 'equals',
          //     filter: 'egr',
          //   },
          // },
          // Add more conditions as needed
        // ],
        // serial_no: {
        //   filterType: 'text',
        //   type: 'contains',
        //   filter: 'Ar',
        // },
        // asset_tag: {
        //         filterType: 'text',
        //         type: 'contains',
        //         filter: 'Adsfvsr',
        //       },
        // or: [
        //   {
        //     asset_tag: {
        //       filterType: 'text',
        //       type: 'contains',
        //       filter: 'Adsfvsr',
        //     },
        //   },
        //   {
        //     asset_tag: {
        //       filterType: 'text',
        //       type: 'equals',
        //       filter: '123',
        //     },
        //   },
        //   // Add more conditions as needed
        // ],
  //       filterType: 'text',
  // operator: 'AND',
  // conditions: [
  //   {
  //     filterType: 'text',
  //     type: 'contains',
  //     colId: 'serial_no',
  //     filter: 'A',
  //   },
  //   {
  //     filterType: 'text',
  //     type: 'equals',
  //     colId: 'serial_no',
  //     filter: '123',
  //   },
  //   // Add more conditions for additional columns
  // ],
    asset_tag: {
      filterType: "text",
      operator: "AND",
      condition1: {
          filterType: "text",
          type: "equals",
          filter: "Adsfvsr"
      },
      condition2: {
          filterType: "text",
          type: "equals",
          filter: "frg"
      }
    },
    serial_no: {
      filterType: "text",
      operator: "AND",
      condition1: {
          filterType: "text",
          type: "contains",
          filter: "g"
      },
      condition2: {
          filterType: "text",
          type: "contains",
          filter: "g"
      }
    }
  };
      // Apply the filter model to the first column
      gridApi.setFilterModel(filterModel);
    }
  }
  // onColumnRowGroupChanged()
  // onGridReady()

  }

  // for new design filter and group by
  const [columnHeaderName, setColumnHeaderName] = useState([
    "serial_no","asset_tag","name","user_name","status","contract_number",
    "contract_status","model_name","model_number","category","manufacturer",
    "location_name","address","notes","created_date","updated_date","source","network_status"
  ])
  const [columnHeaderValue, setColumnHeaderValue] = useState([
    "Serial Number","Asset Tag","Asset Name","User","Status","Contract Number",
  ])

  const [serialNoCheck, setSerialNoCheck] = useState([false, false, false, false, false, false])
  const [groupColumn, setGroupColumn] = useState([])
  const [columnName, setColumnName] = useState('')
  const [filterActionName, setFilterActionName] = useState('')
  const [filterInputValue, setFilterInputValue] = useState('')
  const [filterActionNameTwo, setFilterActionNameTwo] = useState('')
  const [filterInputValueTwo, setFilterInputValueTwo] = useState('')
  const [condition, setCondition] = useState('')
  const [allFilter, setAllFilter] = useState([
    {
      column: '',
      action: '',
      inputVal: '',
      operator: '',
      actionTwo: '',
      inputValTwo: ''
    }
  ])

  const [columnHeaderAndValueMap, setColumnHeaderAndValueMap] = useState({
    "Serial Number":"serial_no","Asset Tag":"asset_tag","Asset Name":"name","User":"user_name","Status":"status","Contract Number":"contract_number"
  })
  const [filterAction, setFilterAction] = useState({"Contains":"contains", "Not Contains": "notContains",
"Equal":"equals", "Not Equal": "notEqual", "Starts With": "startsWith", "Ends With": "endsWith"})

  function onColumnRowGroupChanged(event) {
    const indexArr = [false, false, false, false, false, false]
    event.columns.map(item=>{
      const index = columnHeaderName.indexOf(item.colId)
      indexArr[index] = true
      // setSerialNoCheck(prevArray => {
      //   const newArray = [...prevArray];
      //   newArray[index] = true;
      //   return newArray;
      // });
    })
    setSerialNoCheck(indexArr)
    // const groupedColumns = gridRef.current.api.getRowGroupColumns();

    //   if (groupedColumns && groupedColumns.length > 0) {
    //     setRowGroupPanelShow('never')
    //   }
    if (router.query.report_name == undefined) {
      const rows = event?.columnApi?.getColumnState();
      // const first_row = rows[0];
      // if (first_row.colId != "ag-Grid-AutoColumn") {
      //   saveView(rows);
			// 	dispatch(setrowGroupModel(rows));
      // } else {
      //   dispatch(setrowGroupModel(rows));
      //   saveView(rows);
      // }
    }
  }

  const handleGroupColumnCheck = (index, key) =>{
    if (gridRef.current) {
      
      const selectedCol = groupColumn
      const index_ = selectedCol.indexOf(columnHeaderName[index])
      
      if(index_ != -1){
        selectedCol.splice(index_, 1)
      }else{
        selectedCol.push(columnHeaderName[index])
      }
      setGroupColumn(selectedCol);
      // dropdownClick("uhkug")
      // handleFilterClose()
      // columnApi.setColumnPinned('serial_no', 'left');
      
      
  
      // if (gridRef.current) {
      //   // Get the grid API
      //   const gridApi = gridRef.current.api;
  
      //   // Define the filter model (replace 'A' with your filter value)
      //   const filterModel = {
      //     // serial_no: {
      //     //   filterType: 'text',
      //     //   type: 'contains',
      //     //   filter: 'Ar',
      //     // },
      //     user_name:{
      //       filterType: 'text',
      //       type: 'contains',
      //       filter: 'A',
      //     }
      //   };
  
      //   // Apply the filter model to the first column
      //   gridApi.setFilterModel(filterModel);
      // }
    }
    refreshAssetList()
  }

  const handleGroupChange = () =>{
    
    setRowGroupPanelShow('always')
    const {columnApi} = gridRef.current;
    groupColumn.map(item=>{
      columnApi.setColumnVisible(item, true);     
    })
    columnApi.setRowGroupColumns(groupColumn);
    

    
  }

  const handleColumnSelect = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].column = event.target.value;
      return [...prevFilters];
    });
    // setColumnName(event.target.value)
  }

  const handleFilterActionSelect = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].action = event.target.value;
      return [...prevFilters];
    });
    setFilterActionName(event.target.value)
  }

  const handleFilterInput = (event, index) => {
    // setAllFilter(event.target.value)
    setAllFilter(prevFilters => {
      prevFilters[index].inputVal = event.target.value;
      return [...prevFilters];
    });
    setFilterInputValue(event.target.value)
  }
  const handleFilterInput2 = (e, index) => {
    setFilterInputValue(e.target.value)
  }

  const handleAndOrBtn = (event, operator, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].operator = operator.toUpperCase();
      return [...prevFilters];
    });
    setCondition(operator.toUpperCase())
  }

  const handleFilterActionSelectTwo = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].actionTwo = event.target.value;
      return [...prevFilters];
    });
    setFilterActionNameTwo(event.target.value)
  }

  const handleFilterInputTwo = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].inputValTwo = event.target.value;
      return [...prevFilters];
    });
    setFilterInputValueTwo(event.target.value)
  }

  const handleApplyFilter = (event) => {
    if (gridRef.current) {
      const filterModel = {}
      // Get the grid API
      const gridApi = gridRef.current.api;
      // for both side filled
      const filterModelArr = {}
      allFilter.forEach(item => {
          if(item.inputVal && item.inputValTwo){
          
            filterModelArr[columnHeaderAndValueMap[item.column]] =  {
              filterType: "text",
              operator: item.operator,
              condition1:{
                filterType: "text",
                type: filterAction[item.action],
                filter: item.inputVal
              },
              condition2: {
                filterType: "text",
                type:filterAction[item.actionTwo],
                filter: item.inputValTwo
            }
            }
          }else if(item.inputVal){
            filterModelArr[columnHeaderAndValueMap[item.column]] = {
              filterType: "text",
              type:filterAction[item.action],
              filter: item.inputVal
            }
          }

      })
      // if(filterInputValue && filterInputValueTwo){
      //   filterModel = {
      //     [columnHeaderAndValueMap[columnName]]:{
      //       filterType: "text",
      //       operator: condition,
      //       condition1: {
      //         filterType: "text",
      //         type: filterAction[filterActionName],
      //         filter: filterInputValue
      //     },
      //     condition2: {
      //         filterType: "text",
      //         type:filterAction[filterActionNameTwo],
      //         filter: filterInputValueTwo
      //     }
      //     }
            
      //   }

      // }else if(filterInputValue){
      //   filterModel = {
      //     [columnHeaderAndValueMap[columnName]]:{
      //       filterType: "text",
      //       type: filterAction[filterActionName],
      //       filter: filterInputValue
      //     }
            
      //   }
      // }
      gridApi.setFilterModel(filterModelArr);
    
    }
    
    
  }
  const clearFilter = () => {
    const gridApi = gridRef.current.api;
    gridApi.setFilterModel({});
    setAllFilter([{
      column: '',
      action: '',
      inputVal: '',
      operator: '',
      actionTwo: '',
      inputValTwo: ''
    }])
  }

  const frameworkComponents = {
    customDropdownEditor: CustomDropdownEditor,
    customFilter: CustomFilter,
  };

  const addAnotherFilter = () => {
    setAllFilter(prevArray => [...prevArray, {
      column: '',
      action: '',
      inputVal: '',
      operator: '',
      actionTwo: '',
      inputValTwo: ''
    }])
  }

  const handleGroupByCancel = () => {
    setGroupColumn([])
    const {columnApi} = gridRef.current;
    columnApi.setRowGroupColumns(groupColumn);
  }
//   const [columnDefs, setColumnDefs] = useState([
//     { field: "serial_no", checkboxSelection: true },
// ]);
// const rowSelection = 'multiple';

// const [filterElOne, setFilterElOne] = useState(null);
// const dropdownClickOne =(event) => {
//   setFilterEl(filterElOne ? null : event.currentTarget)
// }
// const handleFilterOneClose = () => {
//   setFilterElOne(null)
// }
// const openFilterOne = Boolean(filterElOne);
// const idOne = 'simple-popperOne'

  return (
    <>
    <div>
    {/* <Popover
     id={idOne}
     open={openFilterOne}
     anchorEl={filterElOne}
      // value={value}
      // onChange={(e) => onCellValueChange(e.target.value)}
       onClose={handleFilterOneClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{ marginTop: "1px" }}
    >
        <div className='dropdown_listGroup'>
          <div>
            <Typography className='group_columnText'>Group Column</Typography>
          </div>
          <div style={{ height: '180px', overflowY: 'scroll' }}>
            <div className='checkbox_list'>
              <span style={{ marginRight: '5px' }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_2657_16034)">
                    <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2657_16034">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </Popover> */}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ marginTop: '8px' }}>
                    <Accordion className='accordione_iconPosition'>
                        <AccordionSummary
                            className='accordine_icon'
                            expandIcon={<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" fill="white" />
                                <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" stroke="#562658" />
                                <g clipPath="url(#clip0_2544_16819)">
                                    <path d="M21 15.4546V21.4546L16 17.4546L11 21.4546V15.4546L16 11.4546L21 15.4546Z" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2544_16819">
                                        <rect width="24" height="24" fill="white" transform="translate(4 4.45459)" />
                                    </clipPath>
                                </defs>
                            </svg>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        />
                        <AccordionDetails>
                            <Box>
                                <Typography>Map</Typography>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Typography className='allAssets_text'>All Assets<span className='devices_text'> (Devices : 1,4567)</span></Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ backgroundColor: '#e9ebed', padding: '8px', borderRadius: '8px', marginBottom: '10px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='category_tabs'>
                            <Tab className="tabs_exampleText" label="Assets" {...a11yProps(0)} />
                            <Tab className="tabs_exampleText" label="Contract" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <div className="filter_icon">
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2624_404)">
                                    <path d="M4.43778 3.80176H14.092C14.199 3.83927 14.2959 3.90076 14.3755 3.98152C14.455 4.06229 14.5149 4.16018 14.5508 4.2677C14.5866 4.37523 14.5974 4.48953 14.5822 4.60185C14.5671 4.71417 14.5264 4.82153 14.4634 4.91571L10.7502 9.0002V14.1986L7.77964 11.9707V9.0002L4.06646 4.91571C4.00341 4.82153 3.96274 4.71417 3.94759 4.60185C3.93243 4.48953 3.94319 4.37523 3.97903 4.2677C4.01487 4.16018 4.07484 4.06229 4.15436 3.98152C4.23388 3.90076 4.33083 3.83927 4.43778 3.80176Z" stroke="#562658" strokeWidth="1.43" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2624_404">
                                        <rect width="17.8232" height="17.8232" fill="white" transform="translate(0.353516 0.0883789)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='filter_icon'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2624_2809)">
                                    <path d="M13.5444 3.05859H4.63273C3.81244 3.05859 3.14746 3.72357 3.14746 4.54386V13.4555C3.14746 14.2758 3.81244 14.9408 4.63273 14.9408H13.5444C14.3646 14.9408 15.0296 14.2758 15.0296 13.4555V4.54386C15.0296 3.72357 14.3646 3.05859 13.5444 3.05859Z" stroke="#562658" strokeWidth="1.42586" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.08838 3.05859V14.9408" stroke="#562658" strokeWidth="1.42586" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2624_2809">
                                        <rect width="17.8232" height="17.8232" fill="white" transform="translate(0.176758 0.0883789)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <Box sx={{ display: 'flex', alignItems: "center", marginRight: '5px' }}>
                            {/* <FormControl sx={{ m: 1, width: 105 }} className='group_formcontrol'>
                                <InputLabel id="demo-multiple-checkbox-label">Group By</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    className='group_field'
                                    multiple
                                    value={groupByName}
                                    onChange={handleChangeOne}
                                    input={<OutlinedInput label="Group By" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name} className='scroll_menuItems'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_2657_16034)">
                                                    <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" stroke-width="1.28" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2657_16034">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Checkbox checked={groupByName.indexOf(name) > -1} />
                                            <ListItemText className='menuList_itemText' primary={name} />
                                        </MenuItem>
                                    ))}
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className='cancel_button'>Cancel</Button>
                                        <Button className='create_button'>Create</Button>
                                    </Box>
                                </Select>
                            </FormControl> */}
                            <Button className='group_field' onClick={dropdownClick}>
                                Group By <Icon>
                                    expand_more
                                </Icon>
                            </Button>
                            <Popover
                                id={id}
                                open={openFilter}
                                anchorEl={filterEl}
                                onClose={handleFilterClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                style={{ marginTop: "1px" }}
                            >
                                <div className='dropdown_listGroup'>
                                    <div>
                                        <Typography className='group_columnText'>Group Column</Typography>
                                    </div>
                                    <div style={{height:'180px', overflowY:'scroll'}} className="thinscrollbar_holder">
                                    {columnHeaderValue.map((item ,index)=>{
                                      return (
                                        <div className='checkbox_list'>
                                          <span style={{marginRight:'5px'}}>
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <g clipPath="url(#clip0_2657_16034)">
                                                      <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                                  </g>
                                                  <defs>
                                                      <clipPath id="clip0_2657_16034">
                                                          <rect width="16" height="16" fill="white" />
                                                      </clipPath>
                                                  </defs>
                                              </svg>
                                          </span>
                                          <div style={{display:'flex', alignItems:'center'}}>
                                          <Checkbox className='checkbox_padding' checked={serialNoCheck[index]}  onChange={()=>{
                                             setSerialNoCheck(prevArray => {
                                              const newArray = [...prevArray];
                                              newArray[index] = !newArray[index];
                                              return newArray;
                                            });
                                            handleGroupColumnCheck(index, item)}} />
                                      
                                              <Typography className='list_text'>{item}</Typography>
                                          </div>
                                          </div>
                                      )
                                    })
                                    }
                                    </div>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'6px' }}>
                                        <Button onClick={handleGroupByCancel} className='cancel_button w-full'>Cancel</Button>
                                        <Button className='create_button w-full' onClick={handleGroupChange}>Create</Button>
                                    </Box>
                                </div>
                            </Popover>

                        </Box>
                        <Box sx={{ minWidth: 108 }}>
                            <FormControl fullWidth className='export_formcontrol'>
                                <InputLabel id="demo-simple-select-label">Export As</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className='export_field'
                                    value={age}
                                    label="Export As"
                                    onChange={handleChangeExport}
                                >
                                    <MenuItem value={10}>CSV</MenuItem>
                                    <MenuItem value={20}>Excel</MenuItem>
                                    <MenuItem value={30}>PDF</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                </Box>
                <CustomTabPanel className='custom_tab' value={value} index={0}>
                    <Box className="filter_box">
                        <Typography sx={{ color: "#1D2939", fontSize: '12px', fontWeight: '500', lineHeight: '16px', fontFamily: '"Inter", sans-serif', marginBottom: '10px' }}>FILTER(1)</Typography>
                        <Box sx={{ backgroundColor: "#FCFCFD", border: '1px solid #EAECF0', padding: '10px', borderRadius: "4px" }}>
                            {allFilter.map((item, index)=>{
                            return (<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ minWidth: 120, marginRight: '5px' }}>
                                        <FormControl fullWidth className='serialNumberText_field'>
                                            <InputLabel id="demo-simple-select-label">Serial Number</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                className='serialNumber_field'
                                                id="demo-simple-select"
                                                value={item.column}
                                                label="Column"
                                                onChange={(e)=>{handleColumnSelect(e,index)}}
                                            >
                                                {Object.keys(columnHeaderAndValueMap).map(item=>(<MenuItem value={item}>{item}</MenuItem>))}
                                               
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ minWidth: 120, marginRight: '5px' }}>
                                        <FormControl fullWidth className='serialNumberText_field'>
                                            <InputLabel id="demo-simple-select-label">Start With</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className='serialNumber_field'
                                                value={item.action}
                                                label="Start With"
                                                onChange={(e)=>{handleFilterActionSelect(e,index)}}
                                            >
                                                {Object.keys(filterAction).map(item=>(<MenuItem value={item}>{item}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl style={{width: 160}} className='filterText_field'>
                                        {/* <Controller
                                          name="filter_input"
                                          className="mb-16"
                                          defaultValue={''}
                                          control={control}
                                          render={({ field }) => (
                                              <> */}
                                                <TextField
                                                    // {...field}
                                                    variant='outlined'
                                                    label='Filter Value'
                                                    required
                                                    value={item.inputVal}
                                                    // value={filterInputValue}
                                                    // onChange={(e)=>{handleFilterInput2(e, index)}}
                                                    onChange={(e)=>{handleFilterInput(e,index)}}
                                                    style={{ marginBottom: '10px',background: 'white' }}
                                                />
                                              {/* </> */}
                            {/* )}
                        /> */}
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ marginBottom: '10px' }}>
                                    {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='add_OrTabs'> */}
                                        <Button style={{backgroundColor: 'green'}} className="add_tabs_text" onClick={(e)=>{handleAndOrBtn(e,"and", index)}}>And</Button>
                                        <Button className="dd_tabs_text" label="Or" onClick={(e)=>{handleAndOrBtn(e,"or", index)}}>Or</Button>
                                    {/* </Tabs> */}
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ minWidth: 120, marginRight: '5px' }}>
                                    <FormControl fullWidth className='serialNumberText_field'>
                                            <InputLabel id="demo-simple-select-label">Contains</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className='serialNumber_field'
                                                value={item.actionTwo}
                                                label="Start With"
                                                onChange={(e)=>{handleFilterActionSelectTwo(e,index)}}
                                            >
                                                {Object.keys(filterAction).map(item=>(<MenuItem value={item}>{item}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                    <FormControl style={{width: 160}} className='filterText_field'>
                                        <TextField
                                                    // {...field}
                                                    variant='outlined'
                                                    label='Filter Value'
                                                    required
                                                    value={item.inputValTwo}
                                                    onChange={(e)=>{handleFilterInputTwo(e,index)}}
                                                    style={{ marginBottom: '10px',background: 'white' }}
                                                />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>)})}
                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                    className='add_anotherButton'
                                    onClick={addAnotherFilter}
                                    startIcon={
                                        <Icon fontSize="small" title="Add Another">
                                            add
                                        </Icon>}>
                                    Add Another</Button>
                            </Box>
                        </Box>
                        <Box>
                            <Button onClick={handleApplyFilter} className='apply_filterButton'>Apply Filter</Button>
                            <Button onClick={clearFilter} className='apply_filterButton'>Clear Filter</Button>
                        </Box>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel className='custom_tab' value={value} index={1}>
                    Tab 2
                </CustomTabPanel>
            </Box>
            <Box sx={{ marginTop: '5px' }}>
                    <div className="ag-theme-alpine"
                        style={{
                            width: "100%",
                            height: windowHeight + 165,
                            fontSize: '12px'
                        }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={500}
                            paginationPageSizeSelector={[200, 500, 1000]}
                        />
                    </div>
            </Box>
            <Box>
                {/* <TableListing /> */}
            </Box>

            {/* <AddDashboardAlert open={open} close={close} /> */}
        </div>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: windowHeight + 105,
          fontSize: "12px",
        }}
      >
        {/* <button onClick={createGroup}>createGroup</button> */}
        {filterApplied && <p style={{margin: '4px'}}>Filters have been applied</p>}
        <AgGridReact
          ref={gridRef}
          // columnDefs={columnDefs}
          // rowSelection={rowSelection}
          // Setup SSRM
          rowModelType='serverSide'
          serverSideStoreType="partial"
          defaultColDef = {{
            // flex: 1,
            // minWidth: 100,
            sortable: true,
            resizable: true
          }}
          // Set the desired sheet name
          defaultExcelExportParams={{
            sheetName: 'Dalos-Source'
          }}
          suppressRowClickSelection
          onGridReady={onGridReady}
          animateRows
          // rowData={props.endUsersAccounts}
          rowHeight={38}
          // no rows overlay
          overlayNoRowsTemplate="<span>No Result Found</span>"
          // Row Grouping conditions
          showOpenedGroup
          suppressDragLeaveHidesColumns
          suppressMakeColumnVisibleAfterUnGroup
          rowGroupPanelShow={rowGroupPanelShow}
          onColumnMoved={onColumnMoved}
          onColumnPinned={onColumnPinned}
          onColumnVisible={onColumnVisible}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          groupHeaderHeight={1}
          headerHeight={44}
          floatingFiltersHeight={40}
          autoGroupColumnDef={{ minWidth: 100 }}
          // on column group changed
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          cacheQuickFilter
          // Pagination Condition
          pagination
          getChildCount={getChildCount}
          frameworkComponents={frameworkComponents}

          /** 
           * Configuring the Side Bar
           * Donn't remove this section
          */
          // sideBar={'columns'}
          // gridOptions={{
          //   sideBar: {
          //     toolPanels: [
          //       {
          //         id: 'columns',
          //         labelDefault: 'Columns',
          //         labelKey: 'columns',
          //         iconKey: 'columns',
          //         toolPanel: 'agColumnsToolPanel',
          //       },
          //     ],
          //     defaultToolPanel: null,
          //     // visible: false,
          //   }
          // }}
          sortable
        >
          <AgGridColumn
            lockPosition
            rowSelection="single"
            minWidth={200}
            suppressSizeToFit
            headerName="Actions"
            pinned="right"
            lockPinned
            className="action_field"
            suppressColumnsToolPanel
            cellRendererFramework={(event) => (
              <ActionsColumn
                data={event.data}
                handleClickOpen={handleClickOpen}
                handelEdit={handelEdit}
                handelClone={handelClone}
                handleArchive={handleArchive}
                gotoServiceRequestPage={gotoServiceRequestPage}
                createAssetPermission={props.createAssetPermission}
                updateAssetPermission={props.updateAssetPermission}
                deleteAssetPermission={props.deleteAssetPermission}
              />
            )}
           />
          {tableHeaders.map((header) => {
            // if (skipHeader.includes(header)) return
            if (header === "serial_no") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  width= {222}
                  suppressSizeToFit
                  suppressRowClickSelection
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  
                  checkboxSelection
                  headerCheckboxSelection
                  headerCheckboxSelectionFilteredOnly
                  headerComponentFramework= {CustomFilter}
                  // floatingFilter
                 />
              );
            }
            if (header == "name") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  suppressSizeToFit
                  onCellValueChanged={onCellValueChanged}
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework= {CustomFilter}
                  sortable
                  // floatingFilter
                  filter="text"
                  searchbar
                  
                                 
                 />
              );
            }
            if (header == "user_name") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  cellEditorPopup
                  suppressSizeToFit
                  // cellEditor="agRichSelectCellEditor"
                  onCellValueChanged={onCellValueChanged}
                  cellEditor ='customDropdownEditor'
                  sortable
                  
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  cellEditorParams={{
                    values: statusMenu.map(
                      (s) => `${s.label},${statusColors[s.color]}`
                    ),
                  }}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "user_name") {
                      return (
                        <span 
                          // onClick={dropdownClickOne} 
                        style={{backgroundColor:'transparent'}}>
                          {/* <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              // backgroundColor: event.data?.asset_status_color?.code
                              backgroundColor:
                                event.value?.split(",")[1] ??
                                event.data?.asset_status_color?.code,
                            }}
                           /> */}
                           {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
                          {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
                          <span  style={{ marginLeft: "5px",marginRight:'14px', fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                          {/* <span style={{position:'absolute', right:'3%', top:'4%'}}>
                            <Icon>
                              expand_more
                            </Icon>
                          </span> */}
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "user_name"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    } 
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    
                  }}
                  filter="text"  
                  editable               
                 />
              );
            }
            if (header == "status") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  cellEditorPopup
                  suppressSizeToFit
                  // cellEditor="agRichSelectCellEditor"
                  onCellValueChanged={onCellValueChanged}
                  cellEditor = 'customDropdownEditor'
                  headerComponentFramework= {CustomFilter}
                  sortable
                  
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  cellEditorParams={{
                    values: statusMenu.map(
                      (s) => `${s.label},${statusColors[s.color]}`
                    ),
                  }}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "status") {
                      return (
                        <span 
                          // onClick={dropdownClickOne} 
                        style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 8px', backgroundColor:'transparent', lineHeight:'24px', position:'relative'}}>
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              // backgroundColor: event.data?.asset_status_color?.code
                              backgroundColor:
                                event.value?.split(",")[1] ??
                                event.data?.asset_status_color?.code,
                            }}
                           />
                           {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
                          {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
                          <span  style={{ marginLeft: "5px",marginRight:'14px', fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                          <span style={{position:'absolute', right:'3%', top:'4%'}}>
                            <Icon>
                              expand_more
                            </Icon>
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "status"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    } 
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    
                  }}
                  filter="text"  
                  editable               
                 />
              );
            }
            if (header == "status") {
              return (
                <AgGridColumn
                  field={header}
                  // width= {250}
                  key={header}
                  // cellEditorPopup
                  suppressSizeToFit
                  // cellEditor="agRichSelectCellEditor"
                  onCellValueChanged={onCellValueChanged}
                  cellEditor = 'customDropdownEditor'
                  headerComponentFramework= {CustomFilter}
                  sortable
                  
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  cellEditorParams={{
                    values: statusMenu.map(
                      (s) => `${s.label},${statusColors[s.color]}`
                    ),
                  }}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "status") {
                      return (
                        <span 
                          // onClick={dropdownClickOne} 
                        style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 8px', backgroundColor:'transparent', lineHeight:'24px', position:'relative'}}>
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              // backgroundColor: event.data?.asset_status_color?.code
                              backgroundColor:
                                event.value?.split(",")[1] ??
                                event.data?.asset_status_color?.code,
                            }}
                           />
                           {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
                          {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
                          <span  style={{ marginLeft: "5px",marginRight:'14px', fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                          <span style={{position:'absolute', right:'3%', top:'4%'}}>
                            <Icon>
                              expand_more
                            </Icon>
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "status"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    } 
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    
                  }}
                  filter="text"  
                  editable               
                 />
              );
            } 
            // if (header == "address") {
            //   return (
            //     <AgGridColumn
            //       field={header}
            //       key={header}
            //       minWidth={100}
            //       onCellValueChanged={onCellValueChanged}
            //       cellEditorPopup
            //       cellEditor="agRichSelectCellEditor"
            //       headerName={generateHeaderLabel(header)}
            //       filter="text"
            //       sortable
            //       
            //       suppressSizeToFit
            //       // floatingFilter
            //       cellEditorParams={{
            //         values: locationMenu.map(
            //           (option) =>
            //             `${option.address1}, ${option.city}, ${option.state}, ${option.country}`
            //         ),
            //       }}                 
            //      />
            //   );
            // }                                  
            // if (header == "contract_status") {
            //   return (
            //     <AgGridColumn
            //       field={header}
            //       key={header}
            //       suppressSizeToFit
            //       filter="text"
            //       sortable
            //       
            //       // floatingFilter
            //       headerName={generateHeaderLabel(header)}
            //       cellRendererFramework={(event) => {
            //         if (event.colDef.field === "contract_status") {
            //           return (
            //             <span style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 10px', backgroundColor:'transparent', lineHeight:'24px'}}>
            //               <span
            //                 style={{
            //                   height: "10px",
            //                   width: "10px",
            //                   marginTop: "2px",
            //                   borderRadius: "50%",
            //                   display: "inline-block",
            //                   backgroundColor:
            //                     event.data?.contract_status_color?.code
            //                 }}
            //                />
            //               <span style={{ marginLeft: "5px", fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
            //                 {event.value}
            //               </span>
            //             </span>
            //           );
            //         } if (
            //           event.colDef.headerName == "Group" &&
            //           event.node.field == "contract_status"
            //         ) {
            //           return (
            //             <>
            //               <span>{event.value}</span>
            //             </>
            //           );
            //         } 
            //           return (
            //             <>
            //               <span>{event.value}</span>
            //             </>
            //           );
                    
            //       }}                  
            //      />
            //   );
            // }        
            // if (header === "notes") {
            //   return (
            //     <AgGridColumn
            //       field={header}
            //       key={header}
            //       suppressSizeToFit
            //       headerName={generateHeaderLabel(header)}
            //       filter="text"
            //       sortable
            //       
            //       // floatingFilter
            //       cellRendererFramework={(params)=>{
            //         return <abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>
            //       }}
            //      />
            //   );
            // }
            // if (header === 'created_date' || header === 'updated_date') {
            //   return (
            //     <AgGridColumn
            //       field={header}
            //       key={header}
            //       headerName={generateHeaderLabel(header)}
            //       // valueFormatter={valueFormatter}
            //       filter="text"
            //       sortable
            //       
            //       // floatingFilter
            //      />
            //   );
            // } 
            // if(header === 'source') {
            //   return (
            //     <AgGridColumn
            //       field={header}
            //       key={header}
            //       headerName={generateHeaderLabel(header)}
            //       filter="text"
            //       sortable
            //       
            //       // floatingFilter
            //       cellRendererFramework={(event) => {
            //         if(event.value === "manual") {
            //           return <span>Manual</span>
            //         } if(event.value === "csv") {
            //           return <span>CSV</span>
            //         } 
            //           return <span>{event.value}</span>
                    
            //       }}
            //      />
            //   );
            // } 
              if(header === 'contract_number') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  
                  headerComponentFramework= {CustomFilter}
                  // floatingFilter
                  // cellRendererFramework={(event) => {
                  //   if(event.value === "manual") {
                  //     return <span>Manual</span>
                  //   } if(event.value === "csv") {
                  //     return <span>CSV</span>
                  //   } 
                  //     return <span>{event.value}</span>
                    
                  // }}
                 />
              );
            } 
              // return (
              //   <AgGridColumn
              //     field={header}
              //     key={header}
              //     headerName={generateHeaderLabel(header)}
              //     filter="text"
              //     sortable
              //     
              //     // floatingFilter
              //    />
              // );
                  
          })}
        </AgGridReact>
      </div>
    </>
  );
}

export default ListView;

function ActionsColumn ({
  data,
  handleClickOpen,
  handelEdit,
  handelClone,
  handleArchive,
  gotoServiceRequestPage,
  createAssetPermission,
  updateAssetPermission,
  deleteAssetPermission
}){
  if (true) {
    return (
      <>
        {/* <Tooltip id="view" title="View" placement="center">
          <Icon
            onClick={() => handleClickOpen(data.id)}
            fontSize="small"
            style={{
              color: "#1D2939",
              paddingBottom: "3px",
              paddingRight: "30px",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            visibility
          </Icon>
        </Tooltip> */}

        {
          (
          <Tooltip id="edit" title="Edit" placement="top">
            <Icon
              onClick={() => handelEdit(data)}
              style={{
                color: "#1D2939",
                paddingBottom: "3px",
                paddingRight: "30px",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
              edit
            </Icon>
          </Tooltip>
        )}

        {(
          <Tooltip id="clone" title="Clone" placement="top">
            <Icon
              onClick={() => handelClone(data)}
              style={{
                color: "#1D2939",
                paddingBottom: "3px",
                paddingRight: "30px",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
              content_copy
            </Icon>
          </Tooltip>
        )}

        {(
          <Tooltip id="view" title="View" placement="top">
            <Icon
              onClick={() => handleArchive(data)}
              style={{
                color: "#1D2939",
                paddingBottom: "3px",
                paddingRight: "30px",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
             more_vert

            </Icon>
          </Tooltip>
        )}

        {/* <Tooltip
          id="service_request"
          title="Service Ticket"
          placement="top"
        >
          <Icon
            onClick={() => gotoServiceRequestPage(data.serial_no)}
            style={{
              color: "#1D2939",
              paddingBottom: "3px",
              paddingRight: "30px",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            room_service
          </Icon>
        </Tooltip> */}
      </>
    );
  } 
    // return <></>;
  
}
function dropdownlist () {
  return(
  (<><div>test</div></>)
  )
}

const CustomDropdownEditor = ({ value, column, onCellValueChanged, values,api, params }) => {
  console.log(column.colDef.field)
  const [filterElOne, setFilterElOne] = useState(true);
  const dropdownClickOne =(event) => {
    setFilterElOne(filterElOne ? null : event.currentTarget)
  }
  const handleFilterOneClose = () => {
    setFilterElOne(false)
  }
  const mainTheme = useSelector(selectMainTheme);
  const classes = useStyles();
  const openFilterOne = true;
  const idOne = 'simple-popperOne'
  const onCellValueChange = () => {
    const payload = {
      asset_id: 279,
  entity_name
  : 
  "status_id",
  entity_value
  : 
  40
    };
    
  
    axios
      .post(`/assets/inline-edit`, payload)
      .then((results) => {
        // toast.success("Asset updated successfully.");
      })
      .catch((err) => {
        console.log(err);
      });

      const gridApi = api
      const transaction = {
        update: [{ rowIndex: 4, data: { 'status': "hgfhgf" } }],
      };
      
      // Apply the transaction to the grid
      // gridApi.updateRowData(transaction);// Reference to the ag-Grid API, obtained through the onGridReady event or elsewhere;

// Specify the rows to refresh
  const params = {
  force: true, // Set to true to refresh all rows
  };
   gridApi.redrawRows();
   }

  if (column.colDef.field==='user_name'){
  return (
  <>
   <div className='userlisting_dropdown'>
    <div>
     <ThemeProvider theme={mainTheme}>
					<Paper
						// component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center search_listingFiled ${classes.buttonSquare}`}
						style={{minWidth:"100%"}}
					>
						<TextField
							placeholder="Search.."
							className="flex flex-1 p-6 search_text"
							fullWidth
							InputProps={{
								disableUnderline: true,
								
							}}
							// value={query_data ?? '' }
							// onChange={(e)=>{
							// 	dispatch(setQuickFilter(e.target.value))
							// }}
						/>
						</Paper>
					</ThemeProvider>
       </div>
       <div>
       <List>
          <ListItem className="userlist_items" disablePadding>
            <ListItemButton>
              <ListItemText className="userlist_itemsText" primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem className="userlist_items" disablePadding>
            <ListItemButton>
              <ListItemText className="userlist_itemsText" primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem className="userlist_items" disablePadding>
            <ListItemButton>
              <ListItemText className="userlist_itemsText" primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem className="userlist_items" disablePadding>
            <ListItemButton>
              <ListItemText className="userlist_itemsText" primary="Trash" />
            </ListItemButton>
          </ListItem>
        </List>
       </div>
       </div>
        </>
     )
    }   
      return(
        <div className='listing_dropdown'>
        <div>
          <div>
            <div className="assigned_list">
            <span className="dot_circle"/>
           <p className="assigned_text">
            Assigned
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            Active
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            In Use
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            Broken
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            Broken
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            Broken
           </p>
           </div>
           <div className="assigned_list" style={{marginTop:'8px'}}>
           <span className="dot_circle"/>
           <p>
            Broken
           </p>
           </div>
          </div>
        </div>
  </div>
      )
    
};

const CustomFilter = (props) => {
  const [columnHeaderAndValueMap, setColumnHeaderAndValueMap] = useState({
    "serial_no":"Serial Number","asset_tag":"Asset Tag","name":"Asset Name","user_name":"User","status":"Status","contract_number":"Contract Number"
  })
  console.log("props.column.colDef.field", props.column.colDef.field)
  const [visibility, setVisibility] = useState(false)
  const dispatch = useDispatch();
  const handleChange = (e) => {
    console.log(props.api.setFilterModel)
    props.api.setFilterModel({[props.column.colDef.field] : {
      filterType: "text",
      type: "contains",
        filter: e.target.value
        }});
  }    
  return (
    <div style={{display:'flex', justifyContent:'space-between'}}>
      {!visibility && <p style={{fontSize:'12px', fontWeight:'600', fontFamily:'"Inter", sans-serif', color:'#1D2939'}}>
      <span style={{color:'#98A2B3',position:'absolute', top:'30%',fontWeight:'200', fontSize:'1.8rem', left:'1%'}}>
      <Icon onClick={()=>{props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'desc' }])}} style={{fontSize:'1.8rem'}}>
        arrow_downward
      </Icon>
      <Icon onClick={()=>{props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'asc' }])}} style={{fontSize:'1.8rem'}}>
       arrow_upward
      </Icon>
      </span>
       <span style={{position:'absolute', top:'31%', left:'21%'}}> {columnHeaderAndValueMap[props.column.colDef.field]} </span>
      </p>}
      {visibility && <input
        type="text"
        style={{padding:'6px', backgroundColor:'transparent'}}
        // value={filterValue}
        onChange={handleChange}
        placeholder="Search..."
      />}
      {!visibility && <p style={{position:'absolute', right:'7%', top:'30%', color:'#98A2B3', fontWeight:'200'}} onClick={()=>{setVisibility(true)}}>
        {/* open */}
        <Icon style={{fontSize:'1.8rem'}}>
         search
        </Icon>
      </p>}
      {visibility && <p style={{position:'absolute', right:'3%', top:'30%', color:'#98A2B3', fontWeight:'200'}} onClick={()=>{setVisibility(false)}}>
        <Icon onClick={()=>{props.api.setFilterModel([{}])}} style={{fontSize:'1.8rem'}}>
          close
        </Icon>
      </p>}
    </div>
  );
};

