import _ from '@lodash';
import 'ag-grid-enterprise';
import { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useToast from '@fuse/hooks/useToast'
import { useDispatch } from 'react-redux'
import { setfilterlist, setrowGroupModel } from '../../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import { hexToRgba, stripHtml, titleCase } from 'helper/common';
import { Opacity } from '@material-ui/icons';
import { generateProperLocationString } from '@fuse/utils/general';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "SERVICE_REQUEST";
  	const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const [filterApplied, setFilterApplied] = useState(false);
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [serviceRequest, setServiceRequest] = useState([]);
	const [activeUserCount, setActiveUserCount ] = useState()
	const [inactiveUserCount, setInActiveUserCount ] = useState()
	const [ticketCount, setTicketCount] = useState()
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};

	const tableHeaders = ["ticket_number", "serial_number", "subject_line","status","priority", "agent", "user","asset_name", "location", "model_number", "created_date", "updated_date"];

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) => {
		setGridApi(params);
		serviceRequest.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(fieldName === "ticket_number") return list[fieldName] = value
				if((value === '-' || value === '')&& fieldName == 'user_name') 
					list[fieldName] = 'Unassigned'
				else if (value === '' || !value || value === '')
					list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if (router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function cellRendererFramework(params){
		if(params.column.colId === "status"){
			return (
				<span className={`listing_status_badge_holder ${params.value && params.value.toLowerCase()}`}>
				{params.value}
				</span> 
			)
		}
		return(<span>{params.value}</span>)
	}

	function getReport(event) {
		const report_name = router.query.report_name;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if(header === "first_name") return "Person's First Name"
		if(header === "last_name") return "Person's Last Name"
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function formatData(data){
		const serviceReqWithUnreadMessage = [], serviceRequests = []
		for( let item of data){
			const formattedObject = {
				id: item?.id,
				ticket_number: item?.ticket_id,
				asset_id: item.asset_id,
				serial_number: item?.asset?.serial_number,
				subject_line: item?.title,
				priority: item?.service_request_priority?.name,
				model_number: item?.asset?.asset_model?.model_no,
				// model_name: item?.asset?.asset_model?.model_name,
				contract_Number: item?.asset?.contract_header?.entity_external_platform_id,
				status: item?.service_request_status?.status_name,
				status_details: {
					id: item?.service_request_status?.id,
					status_name: item?.service_request_status?.status_name,
					status_color: item?.service_request_status?.status_color
				},
				priority_details: {
					id: item?.service_request_status?.id,
					priority_name: item?.service_request_priority?.name,
					priority_color: item?.service_request_priority?.priority_color
				},
				// category_name: item?.service_request_category?.category_name,
				// source_name: item?.service_request_source?.source_name,
				agent: item.agent ? `${item.agent.first_name} ${item.agent.last_name}(${item.agent.email})` : 'Unassigned',
				agent_details: {
					id: item.agent?.id,
					first_name: item.agent?.first_name || '',
					last_name: item.agent?.last_name || '',
				},
				user: item.requestor ? `${item.requestor.first_name} ${item.requestor.last_name}(${item.requestor.email})` : 'Unassigned',
				user_details: {
					id: item.asset?.assigned_user?.id,
					first_name: item.asset?.assigned_user?.first_name || '',
					last_name: item.asset?.assigned_user?.last_name || '',
				},
				client_name: item.user_company.company_name,
				asset_name: item.asset?.asset_name,
				location: generateProperLocationString({
					location_name: item.asset?.shipping?.location_name ?? '',
					address1: item.asset?.shipping?.address1 ?? '',
					address2: item.asset?.shipping?.address2 ?? '',
					city: item.asset?.shipping?.city ?? '',
					state: item.asset?.shipping?.state ?? '',
					country: item.asset?.shipping?.country ?? '',
					zip:  item.asset?.shipping?.zip ?? ''
				}),
				detailed_description_of_the_issue: stripHtml(item?.description),
				created_date: item.created_at ? getLocalTimeFromUTC(item.created_at) : '-',
				updated_date: item.updated_at ? getLocalTimeFromUTC(item.updated_at) : '-',
				has_unread_message: item.new_message_to_agent
			}
			if(formattedObject.has_unread_message){
				serviceReqWithUnreadMessage.push(formattedObject)
			}else{
				serviceRequests.push(formattedObject)
			}
		}
		return [...serviceReqWithUnreadMessage, ...serviceRequests]
	}

	function getAllClients(){
		axios.get(`/service/show-all-service-request?company_id=${props?.companyId}`).then(res =>{
			setServiceRequest(formatData(res.data.data.response))
			setActiveUserCount(res.data.data?.active_user)
			setInActiveUserCount(res.data.data?.inactive_user)
		}).catch(err => {
			console.log(err)
		})
	}

	function getServiceTicketCount(){
		axios.get(`/service/service-ticket-count?company_id=${props?.companyId}`).then((res)=>{
			const {
				total_ticktes_count,
				total_open_ticktes,
				total_pending_tickets,
				total_in_progress_tickets,
				total_closed_count,
			} = res.data.data;
			setTicketCount({
				...ticketCount,
				total_ticktes_count,
				total_open_ticktes,
				total_pending_tickets,
				total_in_progress_tickets,
				total_closed_count,
			})
		}).catch((err)=>{
			console.log('error',err)
		}).finally(()=>{

		})
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	useEffect(() => {
		onGridReady();
		getAllClients()
		getServiceTicketCount()
		return () => {}
	}, [])

	function handleHideColumnDropDownDesign(event){
		if(!groupByArr.length && event.value){
		  return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
		}else{
		  return {}
		}
	}

	function handleHideColumnDropDownPriorityDesign(event){
		if(!groupByArr.length && event.value){
		  return { 
			border: `1px solid ${event.data?.priority_details?.priority_color?.code ?? '#000000'}`, borderRadius: '16px', 
		 	padding: '4px 8px', backgroundColor: hexToRgba(event.data?.priority_details?.priority_color?.code ?? '#000000',0.1),
		   lineHeight: '24px', position: 'relative'
		 }
		}else{
		  return {}
		}
	}

	return (
		<>
		{serviceRequest &&
		<>
		<div className='flexbox_holder justify-start'>
			<div className='each_grey_border_holder'>
				<div className='light_heading'>Total Tickets</div>
				<div className='bottom_holder'>
					<span className='deep_heading'>{ticketCount?.total_ticktes_count}</span>
				</div>
			</div>
			<div className='each_grey_border_holder'>
				<div className='light_heading'>Open Tickets</div>
				<div className='bottom_holder'>
					<span className='deep_heading'>{ticketCount?.total_open_ticktes}</span>
				</div>
			</div>
			<div className='each_grey_border_holder'>
				<div className='light_heading'>Closed Tickets</div>
				<div className='bottom_holder'>
					<span className='deep_heading'>{ticketCount?.total_closed_count}</span>
				</div>
			</div>
			<div className='each_grey_border_holder'>
				<div className='light_heading'>In-Progress Tickets</div>
				<div className='bottom_holder'>
					<span className='deep_heading'>{ticketCount?.total_in_progress_tickets}</span>
				</div>
			</div>
			<div className='each_grey_border_holder'>
				<div className='light_heading'>Pending Tickets</div>
				<div className='bottom_holder'>
					<span className='deep_heading'>{ticketCount?.total_pending_tickets}</span>
				</div>
			</div>
        </div>

		<div
			className="ag-theme-alpine"
			style={{
				width: "100%",
				height: windowHeight + 105,
				fontSize: '11px'
			}}
		>
			{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
			<AgGridReact
				ref={gridRef}
				suppressRowClickSelection={true}
				onGridReady={onGridReady}
				animateRows={true}
				rowData={serviceRequest}
				rowHeight={38}
				onRowClicked={() => {}}
				// Set the desired sheet name
				defaultExcelExportParams={{sheetName: 'Dalos-Source'}}
				defaultColDef={{resizable: true}}
				// no rows overlay 
				overlayNoRowsTemplate={'<span>No Result Found</span>'}

				// Row Grouping conditions
				showOpenedGroup={true}
				suppressDragLeaveHidesColumns={true}
				suppressMakeColumnVisibleAfterUnGroup={true}
				rowGroupPanelShow={rowGroupPanelShow}

				onColumnMoved={onColumnMoved}
				onColumnPinned={onColumnPinned}
				onColumnVisible={onColumnVisible}
				onSortChanged={onSortChanged}
				groupHeaderHeight={1}
				// headerHeight = {15}
				floatingFiltersHeight={40}

				// status bar enabled on range selection
				enableRangeSelection={true}
				statusBar={{
					statusPanels: [
						{
							statusPanel: 'agAggregationComponent'
						},
					],
				}}

				// to check the the no. of rows selected during range selection
				onRangeSelectionChanged={onRangeSelectionChanged}

				onFilterChanged={onFilterChanged}

				// on column group changed
				onColumnRowGroupChanged={onColumnRowGroupChanged}
				frameworkComponents={frameworkComponents}
				pagination={true}
			>
				{tableHeaders.map(header => {
					if (header == 'status') {
						return (
							<AgGridColumn
							  field={header}
							  key={header}
							  minWidth={230}
							  cellEditorPopup
							  suppressSizeToFit
							  // cellEditor="agRichSelectCellEditor"
							  cellEditor='customDropdownEditor'
							  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
							  sortable
							  
							  // floatingFilter
							  headerName={generateHeaderLabel(header)}
							  filter="text"
							  editable={false}
							  cellRendererFramework={(event) => {
								if (event.colDef.field === "status") {
								  return (
									<span
										style={handleHideColumnDropDownDesign(event)}
									>
									  <span
										style={{
										  height: "10px",
										  width: "10px",
										  marginTop: "2px",
										  borderRadius: "50%",
										  display: "inline-block",
										  // backgroundColor: event.data?.asset_status_color?.code
										  backgroundColor:
											event.value?.split(",")[1] ??
											event.data?.status_details?.status_color?.code,
										}}
									  />
									  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
									  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
									  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
										{event.value?.split(",")[0]}
									  </span>
									</span>
								  );
								} if (
								  event.colDef.headerName == "Group" &&
								  event.node.field == "status"
								) {
								  return (
									<>
									  <span>{event.value}</span>
									</>
								  );
								}
								return (
								  <>
									<span>{event.value}</span>
								  </>
								);
			
							  }}
							/>
						  );
					}
					if (header == 'priority') {
						return (
							<AgGridColumn
							  field={header}
							  key={header}
							  minWidth={230}
							  cellEditorPopup
							  suppressSizeToFit
							  cellEditor='customDropdownEditor'
							  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
							  sortable
							  headerName={generateHeaderLabel(header)}
							  filter="text"
							  editable={false}
							  cellRendererFramework={(event) => {
								if (event.colDef.field === "priority") {
								  return (
									<span
										style={handleHideColumnDropDownPriorityDesign(event)}
									>
									  <span style={{ marginLeft: "5px", marginRight: '5px', fontSize: '12px', fontWeight: '500', color: event.data?.priority_details?.priority_color?.code ?? 'black', fontFamily: '"Inter", sans-serif', }}>
										{event.value?.split(",")[0]}
									  </span>
									</span>
								  );
								} if (
								  event.colDef.headerName == "Group" &&
								  event.node.field == "status"
								) {
								  return (
									<>
									  <span>{event.value}</span>
									</>
								  );
								}
								return (
								  <>
									<span>{event.value}</span>
								  </>
								);
			
							  }}
							/>
						  );
					}
					return (
						<AgGridColumn
							field={header}
							key={header}
							suppressSizeToFit={true}
							headerName={generateHeaderLabel(header)}
							sortable={true}
							minWidth={230}
							// floatingFilter = { true } 
							flex={1}
							headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
							filter="text"
							cellRendererFramework={cellRendererFramework}
						/>
					)
				})}
			</AgGridReact>
		</div>
		</>
		}
		</>
	);
}

export default ListView