import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "CATEGORY";
	const router = useRouter();
	const dispatch = useDispatch();
	const gridRef = useRef();
	const parentRef = useRef();
	const toast = useToast(dispatch);
	const [ windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [ categoryDetails, setCategoryDetails ] = useState({})
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [logs,setLogs] = useState([]);
	const [filterApplied, setFilterApplied] = useState(false);

	const skipHeader = [
		"id", "platform_customer_id", "entity_external_platform_id",
		"category_type", "image", "created_at", "updated_at",
		"category_id", "models", "original_info",
		"no_of_assets_belongs_to_this_category",
	]
	// const query_data = useSelector((state) => state.categoryApp.filterParamsReducer);
	// const filterModel = useSelector((state) => state.categoryApp.filterParamsReducer.filter);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );
	
	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}


	function handelDelete(id){
		axios.delete(`/category/delete/${id}?category_type=hardware`).then((res)=>{
			if(res.status == 201){
				toast.error(res.data.message)
				handleClose()
			}else{
				toast.success(res.data.message)
				props.refresh()
				handleClose()
			}
		}).catch((err)=>{
			toast.error(err.data);
		})
	}

	function handelOpenDialouge(data){
		handleClickOpen()
		setCategoryDetails(data)
	}

	// function handleDeleteDialogOpen(data){
	// 	handleClickOpen()
	// 	setCategoryDetails(data)
	// }

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			// for(let [fieldName, value] of Object.entries(list)){
			// 	if(fieldName === "description"){
			// 		list[fieldName] = <abbr style={{textDecoration: 'none'}} title={value}>{value}</abbr>
			// }
			// 	if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			// }
		})
		if(router.query.report_name == undefined) {
			loadInitialState (params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "asset category";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				gridRef.current = {...gridRef.current,filterModelArr:filter}
				params?.columnApi.applyColumnState({ state: order });
				params?.api.setFilterModel(filter);
				if (Object.keys(filter)?.length) {
					setFilterApplied(true)
				}
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView (rows, filter, filterModelArr=null){
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filterModelArr ?? filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 
		// const order = JSON.stringify(rows);
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
			dispatch(setrowGroupModel(rows));
    }
  }

	function onColumnRowGroupChanged(event){
		// const report_name = router.query.report_name;
		// if(report_name == undefined){
		// 	const rows = event.columnApi.getColumnState();
		// 	const first_row = rows[0];
	
		// 	if(first_row.colId !== 'ag-Grid-AutoColumn'){
		// 		saveView(rows);
		// 		dispatch(setrowGroupModel([]));
		// 	}else{
		// 		dispatch(setrowGroupModel(rows));
		// 	}
		// }
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			// parentRef.current.log(event.columns)
			setGroupByArr(event.columns)
		}
		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[1];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
		// auto-size columns on row-grouping		
		// onRowGroupOpenedAutoSize();	
	}

	const onColumnPinned = (params) =>{
		const rows = params.columnApi.getColumnState();
		if(router.query.report_name == undefined) {
			dispatch(setrowGroupModel(rows));
			saveView (rows);
		}
		
	}

	function onCellValueChanged(e){
		const payload = {
			category_id: e.data.id,
			category_name: e.data.category_name,
		}
		if(!payload.category_name){
			toast.error('Asset Category Name is required')
			props.refresh()
			return
		}
		axios.post(`/category/edit?category_type=hardware`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('Asset Category updated Successfully.')
				props.refresh()
			}		  
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})

	}

	const generateHeaderLabel = (header) => {
		if(header == 'category_name'){
			return 'Asset Category'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

	function redirectToAssetProfile(event){
		if(event.value == 0){
      return
    }
		// localStorage.setItem('category_id',event.data.id)
		openInNewTab(`/assets-list?category_id=${event.data.id}`)
	}

	// useEffect(()=>{
	// 	gridRef.current.api.setQuickFilter(
	// 		query_data.query
	// 	);
	// 	if(Object.keys(filterModel).length !== 0){
	// 		setTimeout(()=>{
	// 			gridRef.current.api.setFilterModel(filterModel);
	// 		},0)
	// 	}
	// },[query_data.query])
	useEffect(() => {
		// console.log('@@@@@@@@@@@@@@@@@@@@@', props.endUsersAccounts)
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				category_name: item?.category?.category_name || item.category_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item.user_company.company_name
			}
		})
	}
	
	function getLogs(){
		axios.get('/category/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}
	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	return (	
		<>
		<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'asset_category'} logs={logs} displayTableName = {'Asset Categories'}
				 onClick={handleChildClick}
				 />
			{(tabValue == 0) && <div
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref = { gridRef}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData = {props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					// get filter model
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
					pagination={true}
					defaultColDef={{
						// flex: 1,
						// minWidth: 100,
						sortable: true,
						resizable: true
					}}
				>
				
					{Object.keys(props.endUsersAccounts[0]).map(header => {
						if(!skipHeader.includes(header)){
							if(header == 'no_of_assets'){
								return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											editable = {false}										
											onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											
											cellRendererFramework={(event) =>{
												return (<a 
													style={{
														height:'10px',
														width:'10px',
														color: 'black',
														cursor: 'pointer',
														marginTop: '2px',
														borderRadius: '50%',
														display: 'inline-block',
													}} 
													onClick={()=> redirectToAssetProfile(event)} 
													variant="subtitle1"
												>
													{<><span>{event.value}</span></>}
												</a>)
											}}
										>
										</AgGridColumn>
								)
							}
							else if(header == 'description'){
								return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											editable = {false}										
											onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											
											cellRendererFramework={(event) =>{
												return (
													<abbr style={{textDecoration: 'none'}} title={event.value}>{event.value}</abbr>
												)
											}}
										>
										</AgGridColumn>
								)
							}
							else if(header == 'source'){
								return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											// editable = {false}										
											// onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											
										>
										</AgGridColumn>
								)
							}
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									suppressSizeToFit = {true}
									// editable = {props.updateCategoryPermission}										
									onCellValueChanged = {onCellValueChanged}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									flex = {1}
									minWidth={200}
									headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
									
								></AgGridColumn>
							)
						}							
					})}
				</AgGridReact>
				<DeleteModelDialog
					openDeleteDialog={openDeleteDialog}
					categoryDetails={categoryDetails}
					handelDelete={handelDelete}
					handleClose={handleClose}
				/>
			</div>}	
		</>		
	);
}

export default ListView

function DeleteModelDialog(props) {
	return (
		<div>
			<Dialog
				open={props.openDeleteDialog}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete Asset Category"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<h4>Are you sure to delete this Asset Category named as <b>{props.categoryDetails.category_name}</b>?</h4>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClose}>Cancel</Button>
					<Button onClick={() => props.handelDelete(props.categoryDetails.id)} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}