import axios from '@fuse/utils/axios'

const getAllAsset = (params) => axios.get(`/assets/fetch-asset-suggestions?page=${params.page}${params?.client_id ? `&company_id=${params?.client_id}` : ''}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetStatus = (params) => axios.get(`/asset-status/fetch-status-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetModel = (params) => axios.get(`/models/fetch-model-suggestion?page=${params.page}&column=${params.column}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetCategory = (params) => axios.get(`/category/fetch-category-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetManufacturer = (params) => axios.get(`/manufacturers/fetch-manufacturer-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetServiceProvider = (params) => axios.get(`/service-provider/fetch-service-provider-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllAssetAccessoryCategory = (params) => axios.get(`/accessory-category/fetch-accessory-category-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);

export {
    getAllAsset,
    getAllAssetStatus,
    getAllAssetModel,
    getAllAssetCategory,
    getAllAssetManufacturer,
    getAllAssetServiceProvider,
    getAllAssetAccessoryCategory
}
