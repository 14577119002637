
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Box, Button, Dialog, DialogContent } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Map from '@fuse/components/Map';
import axios from '@fuse/utils/axios';

const MapView = forwardRef(({}, ref) => {
  const [ isOpen, setIsOpen ] = useState(true)
  const [ mapCenter, setMapCenter ] = useState([-100.332988,43.319574]);
  const [ mapData, setMapData ] = useState([]);

  const handleOpenDialog =() => {
    setIsOpen(!isOpen)
  }

  const [openMap, setOpenMap] = useState(false);

  const [mapMarkerClicked, setMapMarkerClicked] = useState(false);

  const handleClickOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const handleMapMarkerClicked =(value) => {
    setMapMarkerClicked(value);
  }

  function getMapData(filterModel){
    const query = window.location.search;
    axios.post(`/assets/map-view${query}`,{
      filterModel
    }).then((res)=>{
      const { response } = res.data.data;
      setMapData(
        (()=>
          (Object.entries(response)).map(([key, val])=>{
            return{
              locations: val.locations,
              total_asset_sum_by_category_for_all_locations: val.total_asset_sum_by_category_for_all_locations,
              total_location_count: val.total_location_count,
              total_asset_count: val.total_asset_count,
              marker_color: val.marker_color,
              latitude: Number(key.split(',')[0]),
              longitude: Number(key.split(',')[1]),
            }
          })
        )()
      )
    }).catch((err)=>{
      console.log(err)
    })
  }

  useImperativeHandle(ref,()=>({
		getMapData
	}));

  return (
    <div className='map_acc_holder'>
      {!isOpen && <h4 className='main_heading'>Map</h4>}
    
      <Accordion className='accordione_iconPosition' style={{
        borderRadius: '16px',
        backgroundColor: isOpen ? 'transparent' : 'white'
        }}
        expanded={isOpen}>
        <AccordionSummary
          className='accordine_icon'
          onClick={handleOpenDialog}
          expandIcon={
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" fill="white" />
              <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" stroke="#562658" />
              <g clipPath="url(#clip0_2544_16819)">
                <path d="M21 15.4546V21.4546L16 17.4546L11 21.4546V15.4546L16 11.4546L21 15.4546Z" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2544_16819">
                  <rect width="24" height="24" fill="white" transform="translate(4 4.45459)" />
                </clipPath>
              </defs>
            </svg>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        />
        <AccordionDetails className='accrodione_details'>
          <Box height={400}>
            <Map mapData={mapData} mapCenter={mapCenter} mapZoom={2.5} />
          </Box>
        </AccordionDetails>
      </Accordion>
      {isOpen && 
      <>
        <Button className='maxmin_btn' onClick={handleClickOpenMap}>
          <i className='ti ti-arrows-maximize' />
        </Button>
        <Dialog onClose={handleCloseMap} open={openMap} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth className='zoomed_dialog_holder'>
          <DialogContent className={mapMarkerClicked ? 'overflow_hidden' : 'overflow_auto'}>
            <Box height={720}>
              <Map mapData={mapData} mapCenter={mapCenter} mapZoom={4} handleMapMarkerClicked={handleMapMarkerClicked} fullView={true}/>
            </Box>
          </DialogContent>

          <Button className='maxmin_btn' onClick={handleCloseMap}>
            <i className='ti ti-arrows-minimize' />
          </Button>
        </Dialog>
      </>}
    </div>
  )
})

export default MapView
