import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
// import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
// import {TextField,MenuItem, FormControlLabel} from '@material-ui/core';
import { useState, useEffect, Fragment, useRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import { generateProperLocationString } from '@fuse/utils/general';
import axios from '@fuse/utils/axios'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import useRouter from '@fuse/hooks/useRouter';
import { TextField } from '@mui/material';
// import { Autocomplete, TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '8px',
		// boxSizing: 'content-box',
		border:'1px solid #EAECF0',
		boxShadow: 'none'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});


function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	let suggRef = useRef(null);
	const [ selectedLocation, setSelectedLocation ] = useState("");
	const [ selectedLocationName, setSelectedLocationName] = useState("");
	const [ selectedCategory, setSelectedCategory ] = useState("");
	const [ selectedCategoryName, setSelectedCategoryName ] = useState("");
	const [ locationMenu, setLocationMenu ] = useState([])
	const [ category, setCategory ] = useState([])
	const [ suggestionArray, setSuggestionArray ] = useState([])
	const [ selectedSuggestion, setSelectedSuggestion ] = useState("");
	const [ selectedSuggestionName, setSelectedSuggestionName] = useState({
		serial_number : "",
		id: 0
	});
	const router = useRouter();
	const serial_id = router.query?.serial_id ?? ''
	const category_id = router.query?.category_id ?? ''
  	const location_id = router.query?.location_id ?? ''


	const unmount_asset_list = useSelector(
		(state) => state.assetsApp.reportViewDialogReducer.unMountAssetList
	);


	// console.log(`${unmount_asset_list} unmount_asset_list unmount_asset_list unmount_asset_list`)


	const handleAutoCompleteInputChange = (e)=>{
		// console.log('===============> ', e.target.value)
		try{
			if(e != null) {
				let currSearch = e.target.value;
				if(currSearch === '' || currSearch === undefined) {
					if(window.location.pathname === "/assets-list") {
						let currSearch = e.target.value;
						let url = `/assets-list?`;
						if(router.query?.category_id != undefined) {
							url += `category_id=${router.query?.category_id}&`
						}
						if(router.query?.location_id != undefined) {
							url += `location_id=${router.query?.location_id}&`
						}
						setSelectedLocation(null)
						setSelectedLocationName(null)
						setSelectedCategory(null)
						setSelectedCategoryName(null)
						suggRef.current.value = currSearch;
						props.onSelectSuggestionRaw(currSearch);
						router.push(url)
					}
				} else {
					if(typeof(currSearch) != 'string'){return}
					props.currentSuggestions.forEach(suggestion => {
						if(suggestion.toLowerCase() == currSearch.toLowerCase()){
							currSearch = suggestion;
						}
					})
					suggRef.current.value = currSearch;
					props.onSelectSuggestionRaw(currSearch);
				}
				
			} else {
				console.log('ssss');
			}
		}catch(err){
			console.log(err)
		};
	}


	const fetchLocationSuggestions = (selectedId, value = '') => {
        axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
            let locations = AlphabeticalSorting(response.data.data.locations,'location_name')
			if(selectedId !== undefined) {
				const data = locations.filter( (location) => {
					if(location.id === parseInt(selectedId)) {
						return {
							id: location?.id ?? 0,
							location_name: location?.location_name ?? '',
							address1: location?.address1 ?? '',
							address2: location?.address2 ?? '',
							city: location?.city ?? '',
							state: location?.state ?? '',
							country: location?.country ?? ''
						}
					}
				})
				if(data.length > 0) {
					setSelectedLocation(data[0]?.id)
					// setSelectedLocationName(data[0])
					setSelectedLocationName(generateProperLocationString({
						location_name: data[0]?.location_name ?? '',
						address1: data[0]?.address1 ?? '',
						address2: data[0]?.address2 ?? '',
						city: data[0]?.city ?? '',
						state: data[0]?.state ?? '',
						country: data[0]?.country ?? ''
					}))
				}
			}
            setLocationMenu(locations)
        }).catch(err => {
            console.log(err);
        })
    }

	const fetchCategorySuggestion = (selectedId, value = '') => {
		axios.get(`assets/fetch-category-manufacturer-suggestion`).then(response => {
			const categories = response.data.data.categories
			if(selectedId !== undefined) {
				const data = categories.filter( (category) => {
					if(category.id === parseInt(selectedId)) {
						return {
							id: category?.id ?? 0,
							category_name: category?.category_name ?? ''
						}
					}
				})
				if(data.length > 0) {
					// console.log('sujay ghosh', data[0])
					setSelectedCategoryName(data[0].category_name)
					setSelectedCategory(data[0].id)
				}
			}
			
            setCategory(categories)
        }).catch(err => {
            console.log(err);
        })
	}

	const fetchSuggestionData = (selectedId, value = '') => {
		const filter = 'Serial Number';
		axios.get(`/assets/fetch-suggestions?filter=${filter}`).then(res => {
			const suggestions = res.data.data.suggestionWithFullData
			if(selectedId !== undefined) {
				const data = suggestions.filter( (suggestion) => {
					if(suggestion.id === parseInt(selectedId)) {
						return {
							id: suggestion?.id ?? 0,
							serial_number: suggestion?.serial_number ?? ''
						}
					}
					
				})
				if(data.length > 0) {
					setSelectedSuggestionName(data[0].serial_number)
					setSelectedSuggestion(data[0].id);
				}
			}
			setSuggestionArray(suggestions);

		}).catch(err => {
			return err;
		})
	}


    useEffect(()=>{
		const urlParams = new URLSearchParams(window.location.search);
		// console.log(urlParams)
		// const myParam = urlParams.get('category_id');
		fetchSuggestionData(router.query?.serial_id);
        fetchLocationSuggestions(router.query?.location_id)
		fetchCategorySuggestion(router.query?.category_id)
    },[]);

	// console.log('dfghjklcghjkl', selectedSuggestion)
	

	// useEffect( () => {
	// 	fetchCategorySuggestion(undefined)
	// }, [selectedCategoryName])

	useEffect( () => {
		setSelectedSuggestion("")
		setSelectedLocationName("")
		setSelectedLocation("")
		setSelectedCategory("")
		setSelectedCategoryName("")
		setSelectedSuggestionName("")
	}, [unmount_asset_list])


	const selectedSuggestionChangeEvent = (value) => {
		if(value === null || value === "") {
			if(window.location.pathname === "/assets-list") {
				let url = `/assets-list?`;
				if(router.query?.category_id != undefined) {
					url += `category_id=${router.query?.category_id}&`
				}
				if(router.query?.location_id != undefined) {
					url += `location_id=${router.query?.location_id}&`
				}
				setSelectedSuggestionName("")
				setSelectedSuggestion("");
				router.push(url)
			}
			setSelectedSuggestionName("")
			setSelectedSuggestion("");
		} else {
			// console.log(value)
			setSelectedSuggestionName(value.serial_number)
			setSelectedSuggestion(value.id);
		}
	}


	const selectLocationChangeEvent = (value) => {
		if(value === null || value === "") {
			if(window.location.pathname === "/assets-list") {
				let url = `/assets-list?`;
				if(router.query?.serial_id != undefined) {
					url += `serial_id=${router.query?.serial_id}&`
				}
				if(router.query?.category_id != undefined) {
					url += `category_id=${router.query?.category_id}&`
				}
				setSelectedLocation("")
				setSelectedLocationName("")
				router.push(url)
			}
			setSelectedLocation("")
			setSelectedLocationName("")
		} else {
			setSelectedLocationName(generateProperLocationString({
				location_name: value?.location_name ?? '',
				address1: value?.address1 ?? '',
				address2: value?.address2 ?? '',
				city: value?.city ?? '',
				state: value?.state ?? '',
				country: value?.country ?? ''
			}))
			// setSelectedLocationName(value)
			setSelectedLocation(value.id);
		}
			
		// if(value) {
			
		// }
		// if(typeof value !== undefined && value.id !== null  ) {
		
		// } else {
		// 
		// 	// if(props.currentSuggestionsIdData[suggRef.current.value] !== undefined) {
		// 	// 	url += `serial_id=${props.currentSuggestionsIdData[suggRef.current.value].id}&`
		// 	// }
		// 	// // if(selectedLocation !== null) {
		// 	// // 	url += `location_id=${selectedLocation}&`
		// 	// // }
		// 	// if(selectedCategory !== null) {
		// 	// 	url += `category_id=${selectedCategory}&`
		// 	// }
		// 	router.push(url)
		// }
		
	}


	const selectCategoryChangeEvent = (value) => {
		if(value === null || value === "") {
			if(window.location.pathname === "/assets-list") {
				let url = `/assets-list?`;
				if(router.query?.serial_id != undefined) {
					url += `serial_id=${router.query?.serial_id}&`
				}
				if(router.query?.location_id != undefined) {
					url += `location_id=${router.query?.location_id}&`
				}
				setSelectedCategory("")
				setSelectedCategoryName("")
				router.push(url)
			}
			setSelectedCategory("")
			setSelectedCategoryName("")
		} else {
			setSelectedCategoryName(value.category_name)
			setSelectedCategory(value.id)
		}
		
	}



	function headerSearchButtonClickEvent () {
		if(selectedSuggestion === null && selectedLocation === null && selectedCategory === null ) {
			return;
		}
		if(selectedSuggestion === "" && selectedLocation === "" && selectedCategory === "" ) {
			return;
		}
		if(selectedSuggestion === undefined && selectedLocation === null && selectedCategory === null ) {
			return;
		}
		let url = `/assets-list?`;
		if(selectedLocation === null || selectedLocation === '') {
			setSelectedLocation('')
			setSelectedLocationName("")
		}
		if(selectedSuggestion === null || selectedSuggestion === '') {
			setSelectedSuggestionName("")
		}
		if(selectedCategory === null || selectedCategory === '') {
			setSelectedCategoryName("")
		}

		if(selectedSuggestion !== null && selectedSuggestion !== "") {
			url += `serial_id=${selectedSuggestion}&`
		}
		if(selectedLocation !== null && selectedLocation !== "") {
			url += `location_id=${selectedLocation}&`
		}
		if(selectedCategory !== null && selectedCategory !== "") {
			url += `category_id=${selectedCategory}&`
		}
		router.push(url)
	
		
	}

	function captureSearchAssetsOnChangeEvent(event) {
		console.log(event.target.value)
	}

	return (
		<div className="flex w-full items-left justify-between">
			<div className={`flex flex-wrap items-center justify-start ${classes.noPaddingRight}`}>
				<div className='break_list_holder p-4'>
					<Autocomplete
						freeSolo
						sx={{ width: 160 }}
						options={suggestionArray}
						disablePortal
						onChange={(event, value) => {
							selectedSuggestionChangeEvent(value)
						}}
						onInputChange={(event, value) => {
							setSelectedSuggestion("")
							setSelectedSuggestionName(value)
						}}
						getOptionLabel={
							option => {
								return option.serial_number
							}
						}
						// inputValue={selectedSuggestionName.id === 0 ? '' :  selectedSuggestionName}
						inputValue={selectedSuggestionName}
						renderInput={(params) => {
							return (
								<div ref={params.InputProps.ref}>
									<TextField
										{...params}
										style={{ background: 'white' }}
										label="Search Assets"
										// value={selectedSuggestionName.serial_number}
										className='location_field'
										FormHelperTextProps={{
											style: {
												margin: 0,
												backgroundColor: '#f4f4f4',
												width: '100%',
												fontSize: '12px !important',
												fontStyle: 'normal',
												fontWeight: '500 !important',
												lineHeight: '16px',
												color: '#98A2B3 !important',
												fontFamily: '"Inter", sans-serif !important',
												// paddingTop: '2px'
											}
										}}
										inputProps={{
											...params.inputProps,
											onKeyDown: (e) => {
														if (e.key === 'Enter') {
															e.stopPropagation();
														}
											},
										}}
										variant="outlined"
										fullWidth
									/>
								</div>
							)
						}}
					/>
				</div>
				<div className='break_list_holder p-4'>
					<Autocomplete
					    freeSolo
					//   className='category_field'
					    sx={{ width: 160 }}
						options={locationMenu}
						disablePortal
						onChange={(event, value) => {
							selectLocationChangeEvent(value)
						}}
						onInputChange={(event, value) => {
							// console.log(value)
							setSelectedLocation("")
							setSelectedLocationName(value)
						}}
						getOptionLabel={
							option => {
								return generateProperLocationString({
									location_name: option?.location_name ?? '',
									address1: option?.address1 ?? '',
									address2: option?.address2 ?? '',
									city: option?.city ?? '',
									state: option?.state ?? '',
									country: option?.country ?? ''
								})
							}
						}
						// value={selectedLocationName}
						inputValue={ selectedLocationName}
						renderInput={(params) => {
							return (
								<div ref={params.InputProps.ref}>
									<TextField
										{...params}
										style={{ background: 'white' }}
										label="Location"
										className='location_field'
										FormHelperTextProps={{
											style: {
												margin: 0,
												backgroundColor: '#f4f4f4',
												width: '100%',
												fontSize: '12px !important',
												fontStyle: 'normal',
												fontWeight: '500 !important',
												lineHeight: '16px',
												color: '#98A2B3 !important',
												fontFamily: '"Inter", sans-serif !important',
												// paddingTop: '2px'
											}
										}}
										inputProps={{
											...params.inputProps,
											onKeyDown: (e) => {
														if (e.key === 'Enter') {
															e.stopPropagation();
														}
											},
										}}
										variant="outlined"
										fullWidth
									/>
								</div>
							)
						}}
					/>
                </div>
				<div className='break_list_holder p-4'>
					<Autocomplete
					   freeSolo
					   sx={{ width: 160 }}
						// className='category_field'
						options={category}
						disablePortal
						onChange={(event, value) => {
							selectCategoryChangeEvent(value)
						}}
						getOptionLabel={
							option => option?.category_name
						}
						onInputChange={(event, value) => {
							setSelectedCategory("")
							setSelectedCategoryName(value)
						}}
						// value={selectedCategoryName?.category_name}
						inputValue={selectedCategoryName}
						renderInput={(params) => {
							return (
								<div ref={params.InputProps.ref}>
									<TextField
										{...params}
										style={{ background: 'white' }}
										className='location_field'
										label="Category"
										FormHelperTextProps={{
											style: {
												margin: 0,
												backgroundColor: '#f4f4f4',
												width: '100%',
												fontSize: '12px !important',
												fontStyle: 'normal',
												fontWeight: '500 !important',
												lineHeight: '16px',
												color: '#98A2B3 !important',
												fontFamily: '"Inter", sans-serif !important',
												// paddingTop: '2px'
											}
										}}
										inputProps={{
											...params.inputProps,
											onKeyDown: (e) => {
														if (e.key === 'Enter') {
															e.stopPropagation();
														}
											},
										}}
										variant="outlined"
										fullWidth
									/>
								</div>
							)
						}}
					/>
				</div>
				<div style={{ cursor: 'pointer' }} className='search_iconButton' onClick={headerSearchButtonClickEvent}>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_2228_146)">
							<path d="M9.16683 15C12.3885 15 15.0002 12.3884 15.0002 9.16671C15.0002 5.94505 12.3885 3.33337 9.16683 3.33337C5.94517 3.33337 3.3335 5.94505 3.3335 9.16671C3.3335 12.3884 5.94517 15 9.16683 15Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M16.6668 16.6667L13.3335 13.3334" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
						</g>
						<defs>
							<clipPath id="clip0_2228_146">
								<rect width="20" height="20" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</div>
			</div>
		</div>
	);
}

export default DynamicFieldsHeader;
