import axios from '@fuse/utils/axios'

const getAllContract = (params) => axios.get(`/contracts/fetch-contract-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllContractStatus = (params) => axios.get(`/contract-status/fetch-status-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllContracType = (params) => axios.get(`/contracts/fetch-contract-type-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);

export {
    getAllContract,
    getAllContractStatus,
    getAllContracType
}
