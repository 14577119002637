import { createSlice } from "@reduxjs/toolkit";
const pageSessions = [
	{
		pathName: '/dashboard',
		moduleName: 'Dashboard',
		totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
	},
	{
		pathName: '/assets-dashboard',
		moduleName: 'Asset Dashboard',
		totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
	},
	{
		pathName: '/assets-catalog',
		moduleName: 'Assets Catalog',
		totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
	},
	{
		pathName: '/assets-list',
		moduleName: 'Assets List',
		totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
	},
    {
        moduleName: 'Archived Assets',
        pathName: '/archived-assets',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Manufacturers',
        pathName: '/manufacturers',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Asset Categories',
        pathName: '/categories',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/models',
        moduleName: 'Models',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Accessories',
        pathName: '/accessories',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Model Attributes',
        pathName: '/model-dynamic-attributes',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Asset Status',
        pathName: '/asset-statuses',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/accessory-categories',
        moduleName: 'Accessory Categories',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
		moduleName: 'Contracts',
		pathName: '/contracts',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Archived Contracts',
        pathName: '/archived-contracts',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Payment Terms',
        pathName: '/payment-terms',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Contract Tags',
        pathName: '/contract-tags',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Contract Types',
        pathName: '/contract-types',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Service Providers',
        pathName: '/service-providers',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Service Level Agreements',
        pathName: '/service-level-agreements',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
		moduleName: 'Subscriptions',
		pathName: '/subscriptions',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        moduleName: 'Subscription Categories',
        pathName: '/subscription-categories',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/subscription-statuses',
        moduleName: 'Subscription Statuses',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/subscription-payment-terms',
        moduleName: 'Payment Terms',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/subscription-providers',
        moduleName: 'Subscription Providers',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
		pathName: '/service-tickets',
		moduleName: 'Service Tickets',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/archived-service-tickets',
        moduleName: 'Archived Service Tickets',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/service-ticket-categories',
        moduleName: 'Service Ticket Categories',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/service-ticket-statuses',
        moduleName: 'Service Ticket Statuses',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        id: 'service-request-source-component',
        moduleName: 'Service Ticket Sources',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/roles',
        moduleName: 'Role',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/locations',
        moduleName: 'Shipping Locations',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/location-types',
        moduleName: 'Location Types',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/departments',
        moduleName: 'Department',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/users',
        moduleName: 'User',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/insights-log',
        moduleName: 'Insights Log',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/import-csv',
        moduleName: 'Import CSV',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/standard-reports',
        moduleName: 'Standard Reports',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
    {
        pathName: '/custom-reports',
        moduleName: 'Custom Reports',
        totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
    },
  //   {
	// 	pathName: '/connectors',
	// 	moduleName: 'Marketplaces',
	// 	totalSessionTimeInSec: 0,
	// 	startSessionTimeInSec: null
	// },
    {
		pathName: '/user-audit-logs',
		moduleName: 'Audit Logs',
		totalSessionTimeInSec: 0,
		startSessionTimeInSec: null
		
	}
]
const initialState = {
	PAGES_ACCESS_BY_USER: [...pageSessions]
};

const pagesAccessSlice = createSlice({
	name: 'pagesAccessByUser',
	initialState,
	reducers: {
        setAllPages: (state, action) => {
			state.PAGES_ACCESS_BY_USER = [...action.payload]
        },
        resetAllPages: (state) => {
            state.PAGES_ACCESS_BY_USER = [...pageSessions]
        }
	},
	extraReducers: {}
});

export const { setAllPages, resetAllPages } = pagesAccessSlice.actions;

export default pagesAccessSlice.reducer;