import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Button, Breadcrumbs, Link, Card, CardContent, Box } from '@mui/material';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomFilterData, setfilterlist, setrowGroupModel, setServiceRequestId as setServiceRequestID } from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import ServiceRequestDailog from './ServiceRequestDailog';
import { setAssetIdForRediection } from '../../assets/store/filterParamsReducer';
import { statusColors } from "@fuse/utils/colorName";
import AlphabeticalSorting from "@fuse/utils/alphabeticalSort";
import GeneralArchieveModal from '@fuse/components/Modal/GeneralArchieveModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

import { IconTicket, IconTicketOff, IconBellRinging } from '@tabler/icons-react';
import AddDashboardAlert from 'app/main/assets-dashboard/components/addAlert';
import { usePusher } from '@fuse/hooks';
import { customAlphanumericSort, customDateSort } from '@fuse/utils/aggridCustomSort';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "SERVICE_REQUEST";
	const BROADCAST_EVENT_NAME = 'new_asset_notification'
	const NEW_TICKET_CREATED_FROM_USER = 'new_service_request_created_from_user'
	const SERVICE_REQ_ARCHIVE_AND_RESTORE_BY_USER = 'service_req_archive_and_restore_by_user'
  const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch();
	const { broadcastChannel } = usePusher();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const service_request_id = useSelector((state) => state.ServiceRequestsApp.filterParamsReducer.service_request_id);
	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');
	const [tabState, setTabState] = useState("asset");
	const [serviceRequestId, setServiceRequestId] = useState(null)
	const [statusMenu, setStatusMenu] = useState([]);
	const [priorityMenu, setPriorityMenu] = useState([]);
	const [filterApplied, setFilterApplied] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [isArchieve, setIsArchieve] = useState(false);
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [ticketCount, setTicketCount] = useState()
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const callRefreshList = useRef(true)
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};
	
	const tableHeaders = [
		"ticket_number", "serial_number", "problem_summary", "priority", "status", "agent_name", "user_name", "client_name",
		"asset_name", "location", "detailed_description_of_the_issue", "created_date", "updated_date"
	];
	const skipHeader = [
		"asset_id", "user_companies_id", "platform_customer_id", "model_number","req_file", "contract_Number",
		"created_at", "updated_at", "submission_date", "status_id", "source_id",
		"category_id", "agent_id", "req_file_location", "location_id",
		"agent_details", "shipping_location_details", "id", "user_details",
		"status_details", "asset_id","has_unread_message","priority_details"
	]

	const [notifications, setNotifications] = useState([])
	const limit = 100, page = useRef(0);
	const [loading, setLoading] = useState(false);
	const [moduleName, setModuleName] = useState('asset');
	const [assetNotificationCount, setAssetNotificationCount ] = useState('0');
	const [ openNotificationDrawer, setOpenNotificationDrawer ] = useState(false);

	const handleOpenDialog = () => {
		setOpenNotificationDrawer(true)
		getNotifications(moduleName)
	}

	const handleCloseNotificationDrawer = () => {
		setOpenNotificationDrawer(false)
		page.current = 0
		setNotifications([])
	}

	useEffect(() => {
		setTabValue(tab_value)
	}, [tab_value])

  const redirectToPage = (pageUrl) => {
	router.push(pageUrl)
}

	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
			<Button
				{...restProps}
				onClick={() => tabStateChange(tab)}
				className={restProps.className && isActive ? activeClass : ""}
			>
				{props.children}
			</Button>
		)
	}

	const handleClickOpen = (data) => {
		setServiceRequestId(data)
		router.push(`/service-tickets/${data}`)
		setOpen(true)
	}

	function Close() {
		setOpen(false)
		setTabState('asset')
		dispatch(setServiceRequestID(null))
	}

	const handelEdit = (data) => {
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	const handleArchive = (data) => {
		setIsArchieve(true)
		setModalData(data);
	};

	function handleClose() {
		setIsArchieve(false)
		setModalData(null);
	}

	function handleConfirm() {
		axios.delete(`/service/archived-service-request/${modalData.id}`).then(() => {
			handleClose();
			props.fetchServiceReq();
			toast.success("Service Ticket archived Successfully.");
		}).catch((err) => {
			console.log("err", err.response);
		});
	}

	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}

	const fetchStatus = () => {
		axios
			.get(`/service-request-status/fetch-status-suggestion`)
			.then((response) => {
				let status = AlphabeticalSorting(response.data.data, "status_name");
				setStatusMenu(
					status.map((item) => {
						return {
							id: item.id,
							label: item.status_name,
							color: item.status_color?.code,
						};
					})
				);
			})
			.catch((err) => {
				console.log("136=>", err);
				// toast.error('Something went wrong!')
			});
	};

	const fetchPriority = () => {
		axios
			.get(`/service-request-priority/fetch-priority-suggestion`)
			.then((response) => {
				let priorities = AlphabeticalSorting(response.data.data, "name");
				setPriorityMenu(priorities.map((item) => {
					return {
						id: item.id,
						label: item.name,
						color: item.priority_color.code,
					}
				}))
			})
			.catch((err) => {
				console.log("136=>", err);
				// toast.error('Something went wrong!')
			});
	};

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function formatLogs(data) {
		let length = data.length
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				ticket_id: item.service_request ? item.service_request.ticket_id : item.ticket_id,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data ?? '-',
				current_value: item.current_data ?? '-',
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				asset_serial_number: item.asset_serial_number || 'N/A'
			}
			length--;
			return obj
		})
	}

	function getLogs() {
		setLoadingLog(true)
		axios.get("/service/get-all-activities").then((res) => {
			setLogs(formatLogs(res.data.data.activities));
			setLoadingLog(false)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
		});
	}

	const onGridReady = (params) => {
		setGridApi(params);
		props.serviceRequests.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(fieldName === "ticket_number") return list[fieldName] = value
				if((value === '-' || value === '')&& fieldName == 'user_name') 
					list[fieldName] = 'Unassigned'
				else if (value === '' || !value || value === '')
					list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if (router.query.report_name == undefined) {
			loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function getReport(event) {
		const report_name = router.query.report_name;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function loadInitialState(params) {
		axios
			.get(`/user-view/show-view-order?table_name=${table_name}`)
			.then((results) => {
				const { data } = results.data;
				if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data.filter);
					gridRef.current = {...gridRef.current,filterModelArr:filter}
					params?.columnApi.applyColumnState({ state: order });
					params?.api.setFilterModel(filter);
					if (Object.keys(filter ?? {})?.length) {
						setFilterApplied(true)
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter, filterModelArr=null) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filterModelArr ?? filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}

		if (router.query.report_name == undefined) {
			const rows = event?.columnApi?.getColumnState();
			const first_row = rows[0];
			if (first_row.colId != "ag-Grid-AutoColumn") {
				dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			  // saveView(rows);
			}
		  }
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if (header == 'accessory_category_id') {
			return 'Accessory Category Name'
		} else if (header == 'manufacturer_id') {
			return 'Manufacturer Name'
		} else if(header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function getServiceTicketMetrics(){
		if(props.pageType === 'dashboard'){
			axios.get('/service/service-ticket-count').then((res)=>{
				const {
					total_ticktes_count,
					total_open_ticktes,
					total_pending_tickets,
					total_in_progress_tickets,
					total_closed_count,
				} = res.data.data;
				setTicketCount({
					...ticketCount,
					total_ticktes_count,
					total_open_ticktes,
					total_pending_tickets,
					total_in_progress_tickets,
					total_closed_count,
				})
			}).catch((err)=>{
				console.log('error',err)
			}).finally(()=>{
	
			})
		}
	}
	useEffect(() => {
		gridRef.current.api.setQuickFilter(query_data);
	}, [query_data])

	useEffect(() => {
		if (service_request_id) {
			setOpen(true)
			setServiceRequestId(service_request_id)
		}
	}, [service_request_id]);

	useEffect(() => {
		getServiceTicketMetrics()
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	}, [props.serviceRequests])

  useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(BROADCAST_EVENT_NAME, () => {
				refreshNotifications()
			})

			broadcastChannel.bind(NEW_TICKET_CREATED_FROM_USER, data => {
				getServiceTicketMetrics();
			});

			broadcastChannel.bind(SERVICE_REQ_ARCHIVE_AND_RESTORE_BY_USER, data => {
				getServiceTicketMetrics();
			});	

			return () => {
				broadcastChannel.unbind(BROADCAST_EVENT_NAME)
			}
		}
	},[broadcastChannel])

	useEffect(() => {
		fetchStatus()
		fetchPriority()
		refreshNotifications()
	}, [])

	const handleChildClick = (value) => {
		setTabValue(value)
	};

	useEffect(() => {
		(tabValue == 1) && getLogs()
	}, [tabValue])

	function redirectToAssetProfile(event) {
		// dispatch(setAssetIdForRediection(event.data.asset_id))
		// router.push('/assets-list');
		router.push(`/assets-list/${event.data.asset_id}`);
	}

	function onCellValueChanged(e) {
		if (callRefreshList.current) props.fetchServiceReq();
		callRefreshList.current = true
		let status = null;
		if (e.colDef.field == "status") {
		status = statusMenu.find(
			(item) => item.label == e.newValue?.split(",")[0]
		);
		}
		let payload = { status_id: status ? status.id : e.data.status.id,
			service_request_id: e.data.id
		 }
		axios.post(`/service/edit-status`, payload)
		.then(results=>{
			toast.success("Service Ticket updated successfully")
			props.fetchServiceReq();
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function flattenNotifications( notifications ){
		if(moduleName !== 'asset') return notifications;
		return notifications.map((notification) => {
				return {
					...notification,
					title: (()=>{
						const splitTitle = notification.title.split("since")
						const date = new Date(notification.notification_at)
						const options = { 
							month: 'short', 
							day: 'numeric', 
							year: 'numeric', 
							hour: 'numeric', 
							minute: 'numeric', 
							hour12: true 
						};
						return `${splitTitle[0]} since ${date.toLocaleString('en-US', options)}`
						// const splitTitle = notification.title.split(" ")
						// const dateTime = new Date(`${splitTitle[splitTitle.length - 2]} ${splitTitle[splitTitle.length - 1].slice(0, -1)}`);
						// const localDateTime = formatDateTo_MM_DD_YYYY_HH_MM_SS(dateTime.toUTCString())
						// return `${splitTitle.slice(0,-2).join(' ')} ${localDateTime}.`
					})()
				}
		})
	}

	function getNotifications(moduleName, refreshBtnClicked=false) {
		if (!moduleName) return
		axios.get(`/notifications?type=${moduleName}&limit=${limit}&page=${page.current}`).then((res) => {
			const { notifications: notification } = res.data.data;
			if(!refreshBtnClicked) setNotifications([...notifications, ...flattenNotifications(notification)])
			else setNotifications([...flattenNotifications(notification)])
			page.current++;
		}).catch((err) => {
			console.log(err)
		}).finally(() => setLoading(false))
	}
	
	
	function onClickRefreshBtn(){
		page.current = 0;
		getNotifications(moduleName, true)
	}
	
	function markAllAsRead() {
		axios.put(`/notifications/update-read-status-of-all-notifications?type=${moduleName}`).then((res) => {
			if (moduleName === 'contract') {
				// dispatch(setContractNotification({
				// 	contractNotificationCount: 0
				// }))
			}
			handleCloseNotificationDrawer()
			refreshNotifications()
		}).catch((err) => {
			console.log(err)
		}).finally(() => setLoading(false))
	}
	
	function updateReadStatusOnClickNotification(notificationId) {
		if (!Boolean(notificationId)) return
		axios.put(`/notifications/update-read-status/${notificationId}`).then((res) => {
			refreshNotifications()
			getNotifications(moduleName)
		}).catch((err) => {
			console.log(err)
		})
	}
	
	const refreshNotifications = () => {
		axios.get("/notifications/count")
			.then((res) => {
				let assetCount = 0;
				let contractCount = 0;
				const { totalNotificationsCount } = res.data.data;
				const unreadNotificationsCount = totalNotificationsCount.reduce((res, curr) => {
					if (curr.type === "contract") {
						contractCount = curr.count;
					}
					if (curr.type === "asset") {
						assetCount = curr.count;
					}
					res[curr.type] = curr.count
					return res;
				}, {});
				setAssetNotificationCount(assetCount)
				// dispatch(setAssetNotification({
				// 	assetNotificationCount: assetCount
				// }))
				// dispatch(setContractNotification({
				// 	contractNotificationCount: contractCount
				// }))
			}).catch((err) => console.log(err));
	};

	const getValues = (name, row_id, column_name) => {
		let status = null;
		let payload = null;
		let priority = null;
		if(column_name === 'status'){
		status = statusMenu.find(
			(item) => item.label == name
		);
		if (!status) return
		 payload = {
			status_id: status ? status.id : e.data.status.id,
			service_request_id: parseInt(row_id)
		}
	}else if(column_name === 'priority'){
		priority = priorityMenu.find(
			(item) => item.label == name
		);

		if (!priority) return
		 payload = {
			priority_id: priority ? priority.id : e.data.priority.id,
			service_request_id: parseInt(row_id)
		}
	}
		axios.post(`/service/inline-edit`, payload)
			.then(results => {
				toast.success("Service Ticket updated successfully")
				callRefreshList.current = false
				props.fetchServiceReq();
			})
			.catch(err => {
				console.log(err);
			})
	}

	function handleAddServiceTicket() {
		dispatch(openFormViewerDialog())
	}

	const clearRedirectionFilter = () => {
		dispatch(setCustomFilterData(null))
	}

	const redirectionFilteredList = (data) => {
		dispatch(setCustomFilterData(data))
		redirectToPage(`/service-tickets`)
	}
	
	function handleHideColumnDropDownDesign(){
		if(!groupByArr.length){
		  return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
		}else{
		  return {}
		}
	}

	function dropdownClickOne(event) {
		if(event.data.status === 'Closed') {
			toast.error("Service ticket has been closed.")
		}
	}

	return (
		<>
			{props.pageType === 'dashboard' &&
				<>
					<div role="presentation" className="cstm_breadcrumbs_holder">
						<Breadcrumbs aria-label="breadcrumb">
							<Link underline="hover" color="inherit">
								Dashboard
							</Link>
						</Breadcrumbs>
					</div>

					<h4 className='main_page_heading'>Service Tickets</h4>

					<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', gap: '12px' }}>
						<div className='dashboard_card' onClick={()=>redirectionFilteredList('Open')}>
							<Card className='dashboard_cardshadow'>
								<CardContent style={{ height: '100%', padding: '24px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex', }}>
											<span className='dashboard_cardIcon icon_holder'>
												{/* <Icon>
											request_quote
										</Icon> */}
												<IconTicket size={18} />
											</span>
										</Box>
										<div className='dashboard_cardDetails'>
											<p>Open Tickets</p>
											<h5>{ticketCount?.total_open_ticktes}</h5>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
						<div className='dashboard_card' onClick={()=>redirectionFilteredList('Pending')}>
							<Card className='dashboard_cardshadow'>
								<CardContent style={{ height: '100%', padding: '24px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex', }}>
											<span className='location_icon icon_holder'>
												<IconTicket size={18} />
											</span>
										</Box>
										<div className='dashboard_cardDetails'>
											<p>Pending Tickets</p>
											<h5>{ticketCount?.total_pending_tickets}</h5>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
						<div className='dashboard_card' onClick={()=>redirectionFilteredList('In Progress')}>
							<Card className='dashboard_cardshadow'>
								<CardContent style={{ height: '100%', padding: '24px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex', }}>
											<span className='ticket_icon icon_holder'>
												<IconTicket size={18} />
											</span>
										</Box>
										<div className='dashboard_cardDetails'>
											<p>In Progress Tickets</p>
											<h5>{ticketCount?.total_in_progress_tickets}</h5>
										</div>			

									</div>
								</CardContent>
							</Card>
						</div>
						<div className='dashboard_card' onClick={()=>redirectionFilteredList('Closed')}>
							<Card className='dashboard_cardshadow'>
								<CardContent style={{ height: '100%', padding: '24px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex', }}>
											<span className='active_assetsIcon icon_holder'>
												<IconTicketOff size={18} />
											</span>
										</Box>
										<div className='dashboard_cardDetails'>
											<p>Closed Tickets</p>
											<h5>{ticketCount?.total_closed_count}</h5>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
						<div className='dashboard_alertcard dashboard_card' onClick={handleOpenDialog}>
							<Card className='alert_card'>
								<CardContent style={{ height: '100%', padding: '27px' }}>
									<div>
										<span className='dashboardPattern_img'>
											<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
												<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
											</svg>
										</span>
										<Box sx={{ alignContent: 'center', display: 'flex' }}>
											<span className='alert_icon icon_holder'>
												<IconBellRinging color='#000' size={18} />
											</span>
										</Box>
										<div className='alert_detailstext'>
											<p>Alerts</p>
											<h5 style={{ display: 'flex' }}>
												{assetNotificationCount}
												<span style={{ marginTop: '5px' }}>
													<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clipPath="url(#clip0_2359_14699)">
															<path d="M6.7998 12.4546L10.7998 8.45459L6.7998 4.45459" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
														</g>
														<defs>
															<clipPath id="clip0_2359_14699">
																<rect width="16" height="16" fill="white" transform="matrix(0 -1 1 0 0.799805 16.4546)" />
															</clipPath>
														</defs>
													</svg>
												</span>
											</h5>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
					</div>
				</>
			}

			<AddDashboardAlert
				open={openNotificationDrawer}
				close={handleCloseNotificationDrawer}
				moduleName={'asset'}
				notifications={notifications}
				loading={loading}
				markAllAsRead={markAllAsRead}
				getNotifications={() => getNotifications(moduleName)}
				updateReadStatusOnClickNotification={updateReadStatusOnClickNotification}
				onClickRefreshBtn={onClickRefreshBtn}
			/>
			<AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName={'service_request'}
				logs={logs}
				displayTableName={'Service Tickets'}
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicket}
				customFilterData={props.customFilterData}
				clearRedirectionFilter={clearRedirectionFilter}
				serviceRequestTotalRecords={props?.serviceRequestTotalRecords}
				loadingLog={loadingLog}
			/>

			{/* {(tabValue == 1) && (<ActivityAgGridListing module={'service_request'} logs={logs} displayTableName={'Service Tickets'}/>)}		 */}

			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '11px'
					}}
				>
					{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection={true}
						onGridReady={onGridReady}
						animateRows={true}
						rowData={props.serviceRequests}
						rowHeight={38}
						onRowClicked={() => {
						}}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}
						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}

						// status bar enabled on range selection
						enableRangeSelection={true}
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agAggregationComponent'
								},
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged={onRangeSelectionChanged}

						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged={onColumnRowGroupChanged}
						frameworkComponents={frameworkComponents}
						pagination={true}
					>
						<AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={120}
							minWidth={120}
							maxWidth={120}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											{props.viewServiceRequestPermission && <Tooltip id="view" title="View" placement="top">
												{/* <Icon onClick={() => handleClickOpen(data)} fontSize='small' style={{
													color: '#1d2939', paddingBottom: '3px', paddingRight: '50px',
													paddingLeft: '20px', fontSize: '15px', cursor: 'pointer'
												}} >visibility</Icon> */}
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-eye" onClick={() => handleClickOpen(data.id)} />

											</Tooltip>}
											{props.deleteServiceRequestPermission &&
												<Tooltip id="delete" title="Archive" placement="top">
													<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-archive" onClick={() => handleArchive(data)} />

													{/* <Icon
													onMouseDown={() => handleArchive(data)}
													style={{
													color: "#1d2939",
													paddingBottom: "5px",

													fontSize: "15px",
													cursor: "pointer",
													}}
												>
													archive
												</Icon> */}
												</Tooltip>
											}
											{data.status != 'Closed' && <Tooltip id="edit" title="Edit" placement="top">
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />

												{/* <Icon
													onClick={() => handelEdit(data)}
													style={{
														color: '#1d2939',
														paddingBottom: '5px',
														paddingRight: '30px',
														fontSize: '15px',
														cursor: 'pointer'
													}}
												>
													edit
												</Icon> */}
											</Tooltip>}

										</>
									)
								} else {
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
						{/* Object.keys(props.serviceRequests[0]) */}
						{props.serviceRequests.length >  0  &&  tableHeaders.map(header => {
							if (!skipHeader.includes(header)) {
								if (header == 'status') {
									return (
										<AgGridColumn
										  field={header}
										  key={header}
										  minWidth={230}
										  cellEditorPopup
										  suppressSizeToFit
										  // cellEditor="agRichSelectCellEditor"
										  cellEditor='customDropdownEditor'
										  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										  sortable
										  
										  // floatingFilter
										  headerName={generateHeaderLabel(header)}
										  filter="text"
										  editable={(event) => event.data?.status_details?.status_name !== 'Closed'}
										  cellEditorParams={{
											values: statusMenu.map(
											  (s) => `${s.label},${statusColors[s.color]}, ${s.id}`
											),
											getValue: getValues
										  }}
										  cellRendererFramework={(event) => {
											if (event.colDef.field === "status") {
											  return (
												<span
												  onClick={() => dropdownClickOne(event)} 
												  style={handleHideColumnDropDownDesign()}>
												  <span
													style={{
													  height: "10px",
													  width: "10px",
													  marginTop: "2px",
													  borderRadius: "50%",
													  display: "inline-block",
													  // backgroundColor: event.data?.asset_status_color?.code
													  backgroundColor:
														event.value?.split(",")[1] ??
														event.data?.status_details?.status_color?.code,
													}}
												  />
												  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
												  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
												  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
													{event.value?.split(",")[0]}
												  </span>
												  {!groupByArr.length && <span style={{ position: 'absolute', right: '3%', top: '4%' }}>
													<Icon>
													  expand_more
													</Icon>
												  </span>}
												</span>
											  );
											} if (
											  event.colDef.headerName == "Group" &&
											  event.node.field == "status"
											) {
											  return (
												<>
												  <span>{event.value}</span>
												</>
											  );
											}
											return (
											  <>
												<span>{event.value}</span>
											  </>
											);
						
										  }}
										/>
									  );
									// return (
									// 	<AgGridColumn
									// 		field={header}
									// 		key={header}
									// 		cellEditorPopup={true}
									// 		suppressSizeToFit={true}
									// 		cellEditor='customDropdownEditor'
									// 		onCellValueChanged={onCellValueChanged}
									// 		flex={1}
									// 		minWidth={200}
									// 		cellEditorParams={{
									// 			values: statusMenu.map(
									// 				(s) => `${s.label},${statusColors[s.color]}`
									// 			),
									// 			getValue: getValues
									// 		}}
									// 		cellRendererFramework={(event) => {
									// 			if (event.colDef.field === "status") {
									// 				return (
									// 					<span>
									// 						<span
									// 							style={{
									// 								height: "10px",
									// 								width: "10px",
									// 								marginTop: "2px",
									// 								borderRadius: "50%",
									// 								display: "inline-block",
									// 								// backgroundColor: event.data?.asset_status_color?.code
									// 								backgroundColor:
									// 									event.value?.split(",")[1] ??
									// 									event.data?.status_details?.status_color?.code,
									// 							}}
									// 						></span>
									// 						{/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
									// 						<span style={{ marginLeft: "5px" }}>
									// 							{event.value?.split(",")[0]}
									// 						</span>
									// 					</span>
									// 				);
									// 			} else if (
									// 				event.colDef.headerName == "Group" &&
									// 				event.node.field == "status"
									// 			) {
									// 				return (
									// 					<>
									// 						<span>{event.value}</span>
									// 					</>
									// 				);
									// 			} else {
									// 				return (
									// 					<>
									// 						<span>{event.value}</span>
									// 					</>
									// 				);
									// 			}
									// 		}}
									// 		filter="text"

									// 		sortable={true}

									// 		// floatingFilter={true}
									// 		headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
									// 		headerName={generateHeaderLabel(header)}
									// 	>
									// 	</AgGridColumn>
									// )
								}
								if (header == 'priority') {
									return (
										<AgGridColumn
										  field={header}
										  key={header}
										  minWidth={230}
										  cellEditorPopup
										  suppressSizeToFit
										  // cellEditor="agRichSelectCellEditor"
										  cellEditor='customDropdownEditor'
										  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										  sortable
										  
										  // floatingFilter
										  headerName={generateHeaderLabel(header)}
										  filter="text"
										  editable={(event) => event.data?.status_details?.status_name !== 'Closed'}
										  cellEditorParams={{
											values: priorityMenu.map(
											  (s) => `${s.label},${statusColors[s.color]}, ${s.id}`
											),
											getValue: getValues
										  }}
										  cellRendererFramework={(event) => {
											if (event.colDef.field === "priority") {
											  return (
												<span
												  onClick={ () => dropdownClickOne(event) } 
												  style={handleHideColumnDropDownDesign()}>
												  <span
													style={{
													  height: "10px",
													  width: "10px",
													  marginTop: "2px",
													  borderRadius: "50%",
													  display: "inline-block",
													  // backgroundColor: event.data?.asset_status_color?.code
													  backgroundColor:
														event.value?.split(",")[1] ??
														event.data?.priority_details?.priority_color?.code,
													}}
												  />
												  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
												  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
												  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
													{event.value?.split(",")[0]}
												  </span>
												  {!groupByArr.length && <span style={{ position: 'absolute', right: '3%', top: '4%' }}>
													<Icon>
													  expand_more
													</Icon>
												  </span>}
												</span>
											  );
											} if (
											  event.colDef.headerName == "Group" &&
											  event.node.field == "status"
											) {
											  return (
												<>
												  <span>{event.value}</span>
												</>
											  );
											}
											return (
											  <>
												<span>{event.value}</span>
											  </>
											);
						
										  }}
										/>
									  );
									// return (
									// 	<AgGridColumn
									// 		field={header}
									// 		key={header}
									// 		cellEditorPopup={true}
									// 		suppressSizeToFit={true}
									// 		cellEditor='customDropdownEditor'
									// 		onCellValueChanged={onCellValueChanged}
									// 		flex={1}
									// 		minWidth={200}
									// 		cellEditorParams={{
									// 			values: statusMenu.map(
									// 				(s) => `${s.label},${statusColors[s.color]}`
									// 			),
									// 			getValue: getValues
									// 		}}
									// 		cellRendererFramework={(event) => {
									// 			if (event.colDef.field === "priority") {
									// 				return (
									// 					<span>
									// 						<span
									// 							style={{
									// 								height: "10px",
									// 								width: "10px",
									// 								marginTop: "2px",
									// 								borderRadius: "50%",
									// 								display: "inline-block",
									// 								// backgroundColor: event.data?.asset_status_color?.code
									// 								backgroundColor:
									// 									event.value?.split(",")[1] ??
									// 									event.data?.priority_details?.priority_color?.code,
									// 							}}
									// 						></span>
									// 						{/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
									// 						<span style={{ marginLeft: "5px" }}>
									// 							{event.value?.split(",")[0]}
									// 						</span>
									// 					</span>
									// 				);
									// 			} else if (
									// 				event.colDef.headerName == "Group" &&
									// 				event.node.field == "status"
									// 			) {
									// 				return (
									// 					<>
									// 						<span>{event.value}</span>
									// 					</>
									// 				);
									// 			} else {
									// 				return (
									// 					<>
									// 						<span>{event.value}</span>
									// 					</>
									// 				);
									// 			}
									// 		}}
									// 		filter="text"

									// 		sortable={true}

									// 		// floatingFilter={true}
									// 		headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
									// 		headerName={generateHeaderLabel(header)}
									// 	>
									// 	</AgGridColumn>
									// )
								}
								if (header == 'detailed_description_of_the_issue') {
									return (
										<AgGridColumn
											field={header}
											key={header}
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}
											sortable={true}
											minWidth={400}
											// floatingFilter = { true } 
											flex={1}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											filter="text"

										>
										</AgGridColumn>
									)
								}

								if (header == 'serial_number') {
									return (
										<AgGridColumn
											field={header}
											key={header}
											suppressSizeToFit={true}
											headerName={generateHeaderLabel(header)}
											sortable={true}
											// floatingFilter = { true } 
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											filter="text"
											flex={1}
											minWidth={200}

											cellRendererFramework={(event) => {
												if(event.value === "No Serial Number") return(<span>{event.value}</span>)
												return (<a
													style={{
														height: '10px',
														width: '10px',
														color: 'black',
														cursor: 'pointer',
														marginTop: '2px',
														borderRadius: '50%',
														display: 'inline-block',
													}}
													onClick={() => redirectToAssetProfile(event)}
													variant="subtitle1"
												>
													{<><span>{event.value}</span></>}
												</a>)
											}}
										>
										</AgGridColumn>
									)
								}
								if(header == 'ticket_number'){
									return(<AgGridColumn 
										field={header}
										key = { header } 
										suppressSizeToFit={true}
										headerName={generateHeaderLabel(header)}  
										sortable={ true }
										flex = {1}
										minWidth={200}
										comparator={customAlphanumericSort}
										// floatingFilter = { true } 
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										filter="text" 
										cellRendererFramework={(params)=>{
											return (
												<div style={{
													display: 'flex',
													alignItems: 'center',
													gap: '10px'
												}}>
													<div
														style={{
															height: '10px',
															backgroundColor: params?.data?.has_unread_message ? 'red' : 'transparent',
															width: '10px',
															borderRadius: '50%'
														}}
													/>
													<span>{params.value}</span>
												</div>
											)
										}}
									/>
									)
								}
								if(header == 'created_date' || header == 'updated_date'){
									return(<AgGridColumn 
										field={header}
										key={header}
										suppressSizeToFit={true}
										headerName={generateHeaderLabel(header)}
										sortable={true}
										flex={1}
										minWidth={250}
										comparator={customDateSort}
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										filter="text"
									/>
									)
								}
								return (
									<AgGridColumn
										field={header}
										key={header}
										suppressSizeToFit={true}
										headerName={generateHeaderLabel(header)}
										sortable={true}
										flex={1}
										minWidth={250}
										// floatingFilter = { true } 
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										filter="text"

									>
									</AgGridColumn>
								)
							}
						})}
					</AgGridReact>
				</div>
			}

			{open && <ServiceRequestDailog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				setOpen={setOpen}
				Close={Close}
				classes={classes}
				TabButton={TabButton}
				tabState={tabState}
				setTabState={setTabState}
				TabContainer={TabContainer}
				serviceRequestId={serviceRequestId}
			/>}

			{isArchieve && (
				<GeneralArchieveModal
					open={isArchieve}
					title={"Are you sure to archive service ticket?"}
					text={"Your ticket will be moved to archive service tickets if you proceed"}
					handleClose={handleClose}
					handleConfirm={handleConfirm}
					actionButtonName="Yes, Proceed!"
				/>
			)}
		</>
	);
}

export default ListView