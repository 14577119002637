import _ from "@lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { getContractStatus } from "helper/status";

function Assets({ companyId }) {
  const gridRef = useRef();
  const rowGroupPanelShow = "never";
  const tableHeaders = [
    "serial_no", "asset_tag", "name", "user_name", "status", "contract_number",
    "contract_tag","contract_status", "model_name", "model_number", "category", "manufacturer",
    "location_name", "address", "notes", "created_date", "updated_date", "source", "network_status"
  ];
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
  const [ assetsInsights, setAssetInsights ] = useState({
    totalNoOfAsset: 'N/A',
    totalNoOfActiveAsset: 'N/A',
    totalNoOfAssetLocations: 'N/A',
    totalNoOfAssetWithTickets: 'N/A'
  });

  const generateHeaderLabel = (header) => {
    if (header == "serial_no") {
      return "Serial Number";
    }
    if (header == "name") {
      return "Asset Name";
    }
    if (header == "model") {
      return "Model Name";
    }
    if (header == "contract_no") {
      return "Contract Number";
    }
    if (header === "location_name") {
      return "Location";
    }

    if (header === "user_name") {
      return "User";
    }
    return header
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  function flattenAssets(assets) {
    return assets.map((asset) => {
      const {
        asset_table_id, serial_number, asset_tag, asset_name, user_name,
        asset_status_name, asset_status_color, contract_number, contract_tag_name,contract_end_date,
        contract_tag_color, model_name, model_no, category_name, manufacturer_name,
        location_name, address, notes, created_at, updated_at, source, connection_status,
        company_name, childcount = undefined
      } = asset;
      return {
        id: asset_table_id,
        serial_no: serial_number,
        asset_tag,
        name: asset_name,
        user_name,
        status: asset_status_name,
        asset_status_color,
        contract_number,
        contract_tag: contract_tag_name,
        contract_status: contract_end_date ? getContractStatus(contract_end_date)?.status : 'No Contract Status',
        contract_tag_color,
        model_name,
        model_number: model_no,
        category: category_name,
        manufacturer: manufacturer_name,
        location_name,
        address,
        notes,
        created_date: dateReformatTo_mmm_dd_yyyy(created_at),
        updated_date: dateReformatTo_mmm_dd_yyyy(updated_at),
        source,
        network_status: connection_status,
        childCount: childcount,
        company_name
      }
    })
  }

  /** This useCallback function, when invoked, refreshes the asset list in the grid.
  *It checks if the grid API is available before attempting to refresh.
  */
  const refreshAssetList = useCallback(() => {
    if (!gridRef.current) return
    gridRef.current.api.refreshServerSideStore({ purge: false })
  }, [gridRef]);

  /** This function handles the fetching of asset data from the server.
  It sends a POST request to the '/assets' endpoint with parameters for server-side data retrieval.
  available property in params object
    {
    "startRow": 0,
    "endRow": 100,
    "rowGroupCols": [],
    "valueCols": [],
    "pivotCols": [],
    "pivotMode": false,
    "groupKeys": [],
    "filterModel": {},
    "sortModel": []
    }
  */
  function onFetchAsset(params){
    const url = `/assets?company_id=${companyId}`

    const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
    axios.post(url,{
      dataSourceParams: {
        startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
      }
    }).then((res)=>{
      if(res.data.data.rows.length === 0) gridRef.current.api.showNoRowsOverlay();
      params.successCallback(flattenAssets(res.data.data.rows), res.data.data.lastRow);
    }).catch((err) => {
      console.log('error', err)
      params.failCallback();
    })
  }

  function getAssetInsights(){
    axios.get(`/super-admin/company-assets-insights/${companyId}`).then((res)=>{
      setAssetInsights((prev)=>{
        return{
          ...prev,
          totalNoOfActiveAsset: res.data.data.totalNoOfActiveAsset !== 0 ? res.data.data.totalNoOfActiveAsset.toLocaleString() : 'N/A',
          totalNoOfAsset: res.data.data.totalNoOfAsset !== 0 ? res.data.data.totalNoOfAsset.toLocaleString() : 'N/A',
          totalNoOfAssetLocations: res.data.data.totalNoOfAssetLocations !== 0 ? res.data.data.totalNoOfAssetLocations.toLocaleString() : 'N/A',
          totalNoOfAssetWithTickets: res.data.data.totalNoOfAssetWithTickets !== 0 ? res.data.data.totalNoOfAssetWithTickets.toLocaleString() : 'N/A'
        }
      })
    }).catch(err=>{
      console.log(err)
    })
  }

  /** This object represents the data source for the grid.
  *It defines a `getRows` function that is invoked by the grid to fetch rows from the server.
  */
  const datasource = {
    getRows(params) {
      // Call the function to fetch asset data from the server.
      onFetchAsset(params)
    }
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit()
    params?.api.setServerSideDatasource(datasource);
  };


  function onFilterChanged(event) {
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  const getChildCount = data => {
    // here child count is stored in the 'childCount' property
    return data.childCount;
  };

  const frameworkComponents = {
    customFilter: CustomFilter,
  };

  /** Add refreshAssetList function as an Event Listener
   * to call it from Layout file or from any other places.
   */
  useEffect(() => {
    getAssetInsights()
    setTimeout(() => {
      window.addEventListener("refreshAssetList", refreshAssetList);
    }, 0)

    return () => {
      window.removeEventListener("refreshAssetList", refreshAssetList);
    };
  }, [refreshAssetList]);

  return (
    <>
      <div className='flexbox_holder justify-start'>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Total Assets</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{assetsInsights.totalNoOfAsset}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Active Assets</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{assetsInsights.totalNoOfActiveAsset}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Assets Locations</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{assetsInsights.totalNoOfAssetLocations}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Assets with Ticket</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{assetsInsights.totalNoOfAssetWithTickets}</span>
          </div>
        </div>
      </div>
      <div
        className="ag-theme-alpine lllllll"
        style={{
          width: "100%",
          height: windowHeight + 125,
          fontSize: "12px",
        }}
      >
        <AgGridReact
          ref={gridRef}
          // Setup SSRM
          rowModelType='serverSide'
          serverSideStoreType="partial"
          defaultColDef={{
            // flex: 1,
            // minWidth: 100,
            sortable: true,
            resizable: true
          }}
          suppressRowClickSelection
          onGridReady={onGridReady}
          onFirstDataRendered
          animateRows
          // rowData={props.endUsersAccounts}
          rowHeight={38}
          // no rows overlay
          overlayNoRowsTemplate="<span>No Result Found</span>"
          // Row Grouping conditions
          showOpenedGroup
          suppressDragLeaveHidesColumns
          suppressMakeColumnVisibleAfterUnGroup
          rowGroupPanelShow={rowGroupPanelShow}
          onColumnMoved
          onColumnPinned
          onColumnVisible
          onSortChanged
          onFilterChanged={onFilterChanged}
          groupHeaderHeight={1}
          headerHeight={44}
          floatingFiltersHeight={40}
          autoGroupColumnDef={{ minWidth: 100 }}
          // on column group changed
          onColumnRowGroupChanged
          // getContextMenuItems = { getContextMenuItems }
          cacheQuickFilter
          // groupIncludeFooter= {false}
          // Pagination Condition
          pagination
          suppressHorizontalScroll= {false}
          getChildCount={getChildCount}
          frameworkComponents={frameworkComponents}
          sortable
        >
          {tableHeaders.map((header) => {
            if (header === "serial_no") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={222}
                  suppressSizeToFit
                  suppressRowClickSelection
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                />
              );
            }
            if (header == "name") {
              return (
                <AgGridColumn
                  field={header}
                  minWidth={200}
                  key={header}
                  suppressSizeToFit
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  filter="text"                  
                />
              );
            }
            if (header == "user_name") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={250}
                  cellEditorPopup
                  suppressSizeToFit
                  cellEditor='customDropdownEditor'
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  headerName={generateHeaderLabel(header)}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "user_name") {
                      return (
                        <span
                          style={{ backgroundColor: 'transparent' }}>                          
                          <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>                          
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "user_name"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );

                  }}
                  filter="text"
                />
              );
            }
            if (header == "status") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  cellEditorPopup
                  suppressSizeToFit
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "status") {
                      return (
                        <span style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 8px', backgroundColor:'transparent', lineHeight:'24px', position:'relative'}}>
                          <span
                            style={{
                              height: "10px", 
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor:
                                event.value?.split(",")[1] ??
                                event.data?.asset_status_color?.code,
                            }}
                          />                          
                          <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "status"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );
                  }}
                  filter="text"
                />
              );
            }
            if (header == "contract_status") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  cellEditorPopup
                  suppressSizeToFit
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "contract_status") {
                      return (
                        <span
                          className={`inline-flex cstm_badge_holder m-0 ${event.value?.split(",")[0].toLowerCase()}`}
                        >                                                
                          <span style={{ fontSize: '12px', fontWeight: '500', fontFamily: '"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "status"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );
                  }}
                  filter="text"
                />
              );
            }
            if (header == "address") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  cellEditorPopup
                  cellEditor="agRichSelectCellEditor"
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  filter="text"
                  sortable
                  suppressSizeToFit            
                />
              );
            }                                  
            if (header == "contract_tag") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  suppressSizeToFit
                  filter="text"
                  sortable
                  
                  minWidth={200}
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "contract_tag") {
                      return (                        
                        <span>
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor: event.data?.contract_tag_color?.code
                            }}
                           />
                          <span style={{ marginLeft: "5px", fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                            {event.value}
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "contract_tag"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    } 
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );
                  }}                  
                />
              );
            }        
            if (header === "notes") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  suppressSizeToFit
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  filter="text"
                  sortable
                  // floatingFilter
                  cellRendererFramework={(params)=>{
                    return <abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>
                  }}
                />
              );
            }
            if (header === 'created_date' || header === 'updated_date') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  filter="text"
                  minWidth={200}
                  sortable
                 />
              );
            } 
            if(header === 'source') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  minWidth={180}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  // floatingFilter
                  cellRendererFramework={(event) => {
                    if(event.value === "manual") {
                      return <span>Manual</span>
                    } if(event.value === "csv") {
                      return <span>CSV</span>
                    } 
                      return <span>{event.value}</span>

                  }}
                 />
              );
            } 
            if (header === 'contract_number') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable                  
                  minWidth={200}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}               
                />
              );
            }
            if (header === 'asset_tag') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable                
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}          
                />
              );
            }
            return (
              <AgGridColumn
                field={header}
                key={header}
                headerName={generateHeaderLabel(header)}
                filter="text"
                sortable
                minWidth={190}                
                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
              />
            );

          })}
        </AgGridReact>
      </div>
    </>
  );
}

export default Assets;