
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Map from '@fuse/components/Map';
import axios from '@fuse/utils/axios';

function MapView() {
 const [ isOpen, setIsOpen ] = useState(true)
 const [ mapData, setMapData ] = useState([]);
 const [ mapCenter, setMapCenter ] = useState([-100.332988,43.319574]); //united state
 
  const handleOpenDialog =() => {
    setIsOpen(!isOpen)
  }

  function getMapData(){
    axios.post('/assets/map-view').then((res)=>{
      const { response } = res.data.data;
      setMapData(
        (()=>
          (Object.entries(response)).map(([key, val])=>{
            return{
              locations: val.locations,
              total_asset_sum_by_category_for_all_locations: val.total_asset_sum_by_category_for_all_locations,
              total_location_count: val.total_location_count,
              total_asset_count: val.total_asset_count,
              marker_color: val.marker_color,
              latitude: Number(key.split(',')[0]),
              longitude: Number(key.split(',')[1]),
            }
          })
        )()
      )
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getMapData()
  },[]);

  return (
    <div className='map_acc_holder'>
      {!isOpen && <h4 className='main_heading'>Map</h4>}
    
      <Accordion className='accordione_iconPosition' style={{
        borderRadius: '16px',
        backgroundColor: isOpen ? 'transparent' : 'white'
        }}
        expanded={isOpen}>
        <AccordionSummary
          className='accordine_icon'
          onClick={handleOpenDialog}
          expandIcon={
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" fill="white" />
              <path d="M0.5 16.4546C0.5 7.89418 7.43959 0.95459 16 0.95459C24.5604 0.95459 31.5 7.89418 31.5 16.4546C31.5 25.015 24.5604 31.9546 16 31.9546C7.43959 31.9546 0.5 25.015 0.5 16.4546Z" stroke="#562658" />
              <g clipPath="url(#clip0_2544_16819)">
                <path d="M21 15.4546V21.4546L16 17.4546L11 21.4546V15.4546L16 11.4546L21 15.4546Z" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2544_16819">
                  <rect width="24" height="24" fill="white" transform="translate(4 4.45459)" />
                </clipPath>
              </defs>
            </svg>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        />
        <AccordionDetails className='accrodione_details'>
          <Box height={400}>
            <Map mapData={mapData} mapCenter={mapCenter}/>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default MapView
