import Page from './index';
export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	// auth: authRoles.user,
	routes: [
		// {
		// 	path: '/connectors',
		// 	exact: true,
		// 	component: Page.Layout
		// },
	]
};