
import { React, useState, Fragment, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Avatar, Button, Tooltip, Checkbox, ListItemText, Menu, MenuItem, TextField  } from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';
import axios from "@fuse/utils/axios";
import { useCurrentUser, usePusher, useRouter } from "@fuse/hooks";
import FuseLoading from "@fuse/core/FuseLoading";
import { downloadFile } from "@fuse/utils/fileUtils";
import AlphabeticalSorting from "@fuse/utils/alphabeticalSort";
import { useDispatch } from "react-redux";
import useToast from "@fuse/hooks/useToast";

const Conversation = forwardRef(({requestId, serviceReqDetails, hasConversationStart }, ref ) => {
  const FORWARD_TO_FIELD = "FORWARD_TO_FIELD"; const CC_FIELD = "CC_FIELD";
  const BROADCAST_EVENT_NAME = 'service_req_chat_new_message_from_user'
  const router = useRouter();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const { data: currentLoggedInUser } = useCurrentUser();
  const serviceRequestId = router.params.id ?? requestId;
  const editorRef = useRef(null);
  const { broadcastChannel } = usePusher();
	const [ replyMode, setReplyMode ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ chatMessages, setChatMessages ] = useState([]);
  const [scrollToBottom, setScrollToBottom ] = useState(true);
  const [ isFloatingButtonVisible ,setIsFloatingButtonVisible ] = useState(false)
  const [ attachmentList, setAttachmentList ] = useState([]);
  const [disableSendBtn, setDisableSendBtn] = useState(false)
  const [ chatAccessParams, setChatAccessParams ] = useState({
    isAgentAssignedToChat: true,
    isStatusClosed: true,
    isUserAssignedToChat: true,
  });
  const [ usersListDropdown, setUsersListDropdown ] = useState(false);
  const userSuggestion = useRef(null)
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ filteredUserSuggestions, setFilterUserSuggestion ] = useState([])
  const [ restAdditionalUsers, setRestAdditionalUser ] = useState([])
  // Forward
  const [ forwardedContent, setForwardedContent ] = useState('');
  const [ isForwardBtnClick, setIsForwardBtnClick ] = useState(false)
  const [forwardToUsers, setForwardToUsers] = useState([]);
  const [forwardDropdownOpen, setForwardDropdownOpen] = useState(false);
  const [searchTermForwardUser, setSearchTermForwardUser] = useState("");
  const [ forwardUserSuggestions, setForwardUserSuggestion ] = useState([])
  const forwardedChatsInfo = useRef([])
  const showAllAdditionalUserClickIndex = useRef(null)
  const [position, setPosition] = useState({ x: 0 });
  const showAllAdditionalUsersRef = useRef(null);
  function filterUserSuggestionList(value, field) {
    const searchValue = value.toLowerCase();
  
    if (field === CC_FIELD) {
      const filteredUsers = userSuggestion.current.filter(
        (user) =>
          (user.name.toLowerCase().includes(searchValue) ||
           user.email.toLowerCase().includes(searchValue)) &&
          !selectedUsers.some((selected) => selected.id === user.id)
      );
      setFilterUserSuggestion([...filteredUsers]);
    } else if (field === FORWARD_TO_FIELD) {
      const filteredUsers = userSuggestion.current.filter(
        (user) =>
          (user.name.toLowerCase().includes(searchValue) ||
           user.email.toLowerCase().includes(searchValue)) &&
          !forwardToUsers.some((selected) => selected.id === user.id)
      );
      setForwardUserSuggestion([...filteredUsers]);
    }
  }

  // Add user as a chip
  const addUser = (user, field) => {
    if(field === CC_FIELD){
      if (!selectedUsers.some((selected) => selected.id === user.id)) {
        setSelectedUsers([...selectedUsers, user]);
      }
      setSearchTerm("");
      setDropdownOpen(false);
    }else if(field === FORWARD_TO_FIELD){
      if (!forwardToUsers.some((selected) => selected.id === user.id)) {
        setForwardToUsers([...forwardToUsers, user]);
      }
      setSearchTermForwardUser("");
      setForwardDropdownOpen(false);
    }
  };

  // Add a new chip for custom input (on pressing Enter)
  const addNewChip = (input, field) => {
    if (input.trim() === "") return;

    // Check if user already exists
    if(field === CC_FIELD){
      if (
        !selectedUsers.some((user) => user.name.toLowerCase() === input.toLowerCase())
      ) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
        if (!isValidEmail) {
          toast.error(`Please enter a valid email address`);
          return
        }
        const newUser = { id: Math.random(), name: input, email: input };
        setSelectedUsers([...selectedUsers, newUser]);
        setSearchTerm("");
        setDropdownOpen(false);
      }else{
        toast.error(`User already added`);
        
      }
    }else if(field === FORWARD_TO_FIELD){
      if (
        !forwardToUsers.some((user) => user.name.toLowerCase() === input.toLowerCase())
      ) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
        if (!isValidEmail) {
          toast.error(`Please enter a valid email address`);
          return
        }
        const newUser = { id: Math.random(), name: input, email: input };
        setForwardToUsers([...forwardToUsers, newUser]);
        setSearchTermForwardUser("");
        setForwardDropdownOpen(false);
      }else{
        toast.error(`User already added`);
        
      }
    }
  };

  // Handle Enter key press
  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if(field == CC_FIELD){
        addNewChip(searchTerm, field);
      }else if(field == FORWARD_TO_FIELD){
        addNewChip(searchTermForwardUser, field);
      }
    }
  };

  // Remove user chip
  const removeUser = (userId, field) => {
    if(field == CC_FIELD) setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
    else if(field == FORWARD_TO_FIELD) setForwardToUsers(forwardToUsers.filter((user) => user.id !== userId));
  };

  function showAllAdditionalUsers(additionalUsers){
    setUsersListDropdown(!usersListDropdown)
    const restEmailAddress = additionalUsers.slice(1);
    const users = []
    for(const email of restEmailAddress){
      const portalUser = userSuggestion.current.find(item => item.email === email)
      if(portalUser){
        users.push({
          ...portalUser
        })
      }else{
        users.push({
          email,
          profilePicUrl: `https://ui-avatars.com/api/?name=${email}`
        })
      }
    }
    setRestAdditionalUser([...users])
  }

  const handleOpenReply = () => {
		setReplyMode(true)
	}

	const handleCloseReply = () => {
    setAttachmentList([])
		setReplyMode(false)
    setForwardedContent("")
    setSelectedUsers([])
    setSearchTerm("")
    setIsForwardBtnClick(false)
    setSearchTermForwardUser("")
    setForwardToUsers([])
	}

  function handleWindowResize(){
    toggleFloatingButtonVisibility()
  }

  // Handle Top-to-bottom/Bottom-to-top scroll on floating btn click
  const handleScroll = () => {
    window.scrollTo({
      top: scrollToBottom ? window.document.body.scrollHeight : 0,
      left: 0,
      behavior: "smooth",
    })
    setScrollToBottom(!scrollToBottom )
  }

  function handleScrollEnd(){
    setScrollToBottom(!window.scrollY);
  }

  function handleMouseClick(event) {
    const parentRect = document.getElementById('info_num_holder').getBoundingClientRect(); // Get the bounding rectangle of the parent
    const relativeX = event.clientX - parentRect.left; // Calculate x relative to parent
    setPosition({ x: relativeX });
  };

  function toggleFloatingButtonVisibility(){
    // const isScrollable = window.innerWidth > window.document.documentElement.clientWidth;
    const isScrollable =  window.document.getElementById('main_conversation')?.clientHeight > 240;
    if(!isScrollable){
      setScrollToBottom(true);
    }
    setIsFloatingButtonVisible(isScrollable);
  };

  function onEditorChange( content ){
    editorRef.current.currentContent = content
  };

  function formatDate( UTCdate ){
    // Convert UTC date into Local date
    const localDate = new Date(UTCdate);
    const now = new Date();

    // Check if the localDate is today
    const isToday = localDate.toDateString() === now.toDateString();
  
    // Define arrays for days and months
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // Get components of the localDate
    const dayOfWeek = daysOfWeek[localDate.getDay()];
    const day = localDate.getDate();
    const month = monthsOfYear[localDate.getMonth()];
    const year = localDate.getFullYear();
  
    // Format the time to the nearest minute and handle AM/PM
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes();
  
    // Round minutes to the nearest multiple of 5
    minutes = Math.round(minutes / 5) * 5;
  
    // Handle rounding up to the next hour if minutes are 60
    if (minutes === 60) {
      minutes = 0;
      hours++;
    }
  
    // Handle AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;  // Convert to 12-hour format
  
    // Pad minutes with leading zero if needed
    const minutesStr = String(minutes).padStart(2, '0');
    return {
      date: isToday ? "Today" : `${dayOfWeek}, ${month} ${day}, ${year}`,
      time: `${hours}:${minutesStr} ${ampm}`
    };
  }


	const fetchUserSuggestionsForAdditionalCC = () => {
		axios.get(`/assets/fetch-users-suggestion?client_id=${serviceReqDetails.company_id}`).then(response => {
      const { users } = response.data.data;
      const formattedUsers = users.map((user)=>{
        return {
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          profilePicUrl: user.photoURL ?? `https://ui-avatars.com/api/?name=${user.email}`
        }
      })
      userSuggestion.current = formattedUsers;
			setFilterUserSuggestion([...formattedUsers])
      setForwardUserSuggestion([...users])
		}).catch(err => {
			console.log('164=>',err);
		})
	}

  function formatMessages( messages ){
    return messages.map((message)=>{
      const { date, time } = formatDate(message.message_at)
      return {
        id: message.id,
        user_name: `${message.sender.first_name} ${message.sender.last_name}`,
        user_type:(message.sender.portal !== "SAM") ?
          (currentLoggedInUser.id === message.sender.id ? 'You' : 'Agent') : 'User',
        message_date: date,
        message_time: time,
        message_content: message.message,
        user_photo_url: message.sender.photoURL,
        chat_attachments: message.chat_attachments,
        cc_users: message.cc_users ?? [],
        forward_to: message.forward_to ?? []
      }
    })
  }

  function handleMouseClick(event) {
    const parentRect = document.getElementById('info_num_holder').getBoundingClientRect(); // Get the bounding rectangle of the parent
    const relativeX = event.clientX - parentRect.left; // Calculate x relative to parent
    setPosition({ x: relativeX });
  };

  function uploadFileHandler(callback, value, meta){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'application/pdf, image/*, text/*');
    input.click();
 
    const MAX_FILE_SIZE_MB = 2;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    // List of restricted file extensions
    const restrictedExtensions = ['exe', 'bat', 'cmd', 'sh', 'js', 'msi', 'jar', 'vbs', 'scr', 'pif'];

    input.onchange = function () {
      const file = this.files[0];

      // Extract the file extension and validate
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (restrictedExtensions.includes(fileExtension)) {
        toast.error(`File type .${fileExtension} is not allowed.`);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE_BYTES) {
        toast.error(`File size exceeds the 2MB limit. Please upload a smaller file.`);
        return;
      }

      const reader = new FileReader();
      reader.onload = function () {
        const id = `blobid${  (new Date()).getTime()}`;
        const {blobCache} = tinymce.activeEditor.editorUpload;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        tinymce.activeEditor.windowManager.close();
        displayUploadedFile(file, blobInfo.blobUri());
      };
      reader.readAsDataURL(file);
    };
  }
 
  function displayUploadedFile(file, fileUrl) {
    const displayDiv = document.getElementById('uploaded-files');
    
    const fileLink = document.createElement('a');
    fileLink.href = fileUrl;
    fileLink.target = '_blank';
    fileLink.textContent = `${file.name}`;
    
    const listItem = document.createElement('div');
    listItem.appendChild(fileLink);
    displayDiv.appendChild(listItem);
    setAttachmentList((prev)=>[...prev, file]);
  }

  function onClickDownloadFile(file){
    axios.post(`/service/get-download-link`,{
      file_location: file.file_location
    }).then((res)=>{
      const { download_link } = res.data.data
      downloadFile(download_link, file.file_file_name)
    }).catch((err)=>{

    })
  }
  function onClickForwardBtn(index){
    setReplyMode(true)
    setIsForwardBtnClick(true)
    const chats = chatMessages.slice(index)
    let messageToBeForward = (chats)
    .reduce((res, curr)=>{
      res += `
        <p>On ${curr.message_date} ${curr.message_time}, ${curr.user_name} wrote:<br></p>
        ${curr.message_content}
        <br/>
      `
      return res;
    }, '');

    messageToBeForward = `
      <div></div>
      <div  style="border-left: 0.25rem solid #ccc;padding-left: 12px;">
        ${messageToBeForward}
      </div>`
    setForwardedContent(messageToBeForward);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })

    forwardedChatsInfo.current = chats.map((chat)=>{
      return {
        chat_id: chat.id,
        cc_users: chat.cc_users,
        forward_to: chat.forward_to
      }
    })
  }

  function forwardMessages(){
    if(!forwardToUsers.length){
      toast.error("Forward to is required!")
      return
    }
    const payload = {
      message_body: editorRef.current.currentContent,
      chat_cc_forward_info: forwardedChatsInfo.current.map((item)=>{
        return{
          chat_id: item.chat_id,
          cc_user: [...new Set([...item.cc_users, ...selectedUsers.map(user => user.email)])],
          forward_to: [...new Set([...item.forward_to, ...forwardToUsers.map(user => user.email)])]
        }
      }),
      cc_emails: [...selectedUsers.map(user => user.email)],
      forward_to_emails: [...forwardToUsers.map(user => user.email)]
    }

    axios.post(`/service/${serviceRequestId}/forward-message`,payload).then((res)=>{
      handleCloseReply()
      fetchMessages()
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{

    })

  }

  function fetchMessages(){
    setLoading(true)
    axios.get(`/service/${serviceRequestId}/chats`).then((res)=>{
      setChatMessages(formatMessages(res.data.data.chats))
      hasConversationStart(res.data.data.chats.length)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
      toggleFloatingButtonVisibility();
    })
  }

  function sendMessage(){
    if(!editorRef.current.currentContent.length) return
    setDisableSendBtn(true)
    const formData = new FormData();

    attachmentList.forEach((attachment) => {
      formData.append("attachments", attachment)
    });

    formData.append("message", editorRef.current.currentContent);
    formData.append("additional_cc_users", JSON.stringify(selectedUsers.map(user => user.email)));

    axios.post(`/service/${serviceRequestId}/chat/send`,formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(()=>{
      handleCloseReply()
      fetchMessages()
    }).catch((err)=>{

    }).finally(()=>{
      setDisableSendBtn(false)
    })
  }

  function markServiceRequestWithNoUnReadMessages(){
    if(!serviceRequestId) return
    axios.put(`/service/${serviceRequestId}/mark-no-unread-messages`).then(()=>{
    }).catch((err)=>{})
  }

  function checkServiceRequestHasAccessToChat(){
    if(!serviceRequestId || router?.query?.archived ) return
    axios.get(`/service/${serviceRequestId}/chat/check-access`).then((res)=>{
      const { isAgentAssignedToChat, isStatusClosed,isUserAssignedToChat } = res.data.data;
      setChatAccessParams((prev)=>({
        ...prev,
        isAgentAssignedToChat,
        isStatusClosed,
        isUserAssignedToChat
      }));
    }).catch((err)=>{})
  }

  useImperativeHandle(ref, ()=>({checkServiceRequestHasAccessToChat}));

  const handleClickOutside = (event) => {
    if (showAllAdditionalUsersRef.current && !event.target.classList.contains('num_badge') && !showAllAdditionalUsersRef.current.contains(event.target)) {
      setUsersListDropdown(false);
    }
  };

  useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(BROADCAST_EVENT_NAME, data => {
				if(data.service_request_id === Number(serviceRequestId)){
					fetchMessages();
          markServiceRequestWithNoUnReadMessages()
				}
			})
			return () => broadcastChannel.unbind(BROADCAST_EVENT_NAME)
		}
	},[broadcastChannel])

  useEffect(()=>{
    document.addEventListener("scrollend", handleScrollEnd);
    window.addEventListener("resize", handleWindowResize);
    document.addEventListener("mousedown", handleClickOutside);
    fetchMessages();
    checkServiceRequestHasAccessToChat();
    fetchUserSuggestionsForAdditionalCC()
    markServiceRequestWithNoUnReadMessages()
    return () => {
      document.removeEventListener("scrollend", handleScrollEnd);
      window.addEventListener("resize", handleWindowResize);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  },[]);

  return (
    <div id="conversation_holder">
      <div className='flex flex-wrap justify-between items-center mb-20'>
        {/* <h4 className='medium_heading'></h4> */}
        {(!replyMode && Boolean(chatMessages.length) && !chatAccessParams.isStatusClosed) && (
          <>
            <h4 className='medium_heading'>Conversation</h4>
            <div>
              <Button className='primary_maroon_btn' style={{marginRight: '20px'}} onClick={() => handleOpenReply()}>
                <i className='ti ti-arrow-back-up icon' />
                <span>Reply</span>
              </Button>
              <Button className='primary_maroon_outlined_btn' onClick={() => onClickForwardBtn(0)}>
                <i className='ti ti-arrow-forward-up icon' />
                <span>Forward</span>
              </Button>
            </div>
          </>
        )}
      </div>

      {replyMode && <>
        {/* <div className='subject_holder mb-12'>
          <span className='cstm_label_holder pr-8'>Subject:</span>
          <span className='content_holder'>Tell us The Exact Problem</span>
        </div> */}
        <div>
        <div className="cstm_multi_select_dropdown">
          <h3 style={{ marginBottom: '7px'}}>To: {serviceReqDetails.requestor_email}</h3>
            {isForwardBtnClick && (
              <>
              <div style={{ marginBottom: '10px', position: 'relative'}} className="cstm_input_outer_holder">
                <span className="flex items-center">Forward to: </span>
                {forwardToUsers.length > 0 && <div className="chip_container">
                  {forwardToUsers.map((user) => (
                    <div key={user.id} className="chip_holder">
                      <span>{user.name}</span>
                      <span className="close-btn" onClick={() => removeUser(user.id, FORWARD_TO_FIELD)}>
                        <span>&times;</span>
                      </span>
                    </div>
                  ))}
                </div>}

                <input
                  className="input_box"
                  type="text"
                  value={searchTermForwardUser}
                  onChange={(e) => {
                    setDropdownOpen(false)
                    setSearchTerm("")
                    setSearchTermForwardUser(e.target.value);
                    setForwardDropdownOpen(Boolean(e.target.value));
                    filterUserSuggestionList(e.target.value, FORWARD_TO_FIELD)
                  }}
                  placeholder="Search by name or email..."
                  onKeyDown={(e)=>handleKeyDown(e, FORWARD_TO_FIELD)}
                  onFocus={() => setForwardDropdownOpen(true)}
                />

                {forwardDropdownOpen && searchTermForwardUser && (
                  <div className="cstm_dropdown_holder">
                    <ul className="list_holder">
                      {forwardUserSuggestions.length > 0 ? (
                        forwardUserSuggestions.map((user) => (
                          <li key={user.id} onClick={() => addUser(user, FORWARD_TO_FIELD)}>
                            <div className="rounded_img_holder">
                              <img src={user.profilePicUrl} alt="image11" />
                            </div>
                            <div className="info_holder">
                              <p className="name_holder">{user.name}</p>
                              <p className="mail_holder">{user.email}</p>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>No users found</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              </>
            )}

            <div style={{ position: 'relative'}}  className="cstm_input_outer_holder">
              <span className="flex items-center">CC:</span>
              {selectedUsers.length > 0 && <div className="chip_container">
                {selectedUsers.map((user) => (
                  <div key={user.id} className="chip_holder">
                    <span>{user.name}</span>
                    <span className="close-btn" onClick={() => removeUser(user.id, CC_FIELD)}>
                      <span>&times;</span>
                    </span>
                  </div>
                ))}
              </div>}

              <input
                className="input_box"
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTermForwardUser("");
                  setForwardDropdownOpen(false);
                  setSearchTerm(e.target.value);
                  setDropdownOpen(Boolean(e.target.value));
                  filterUserSuggestionList(e.target.value, CC_FIELD)
                }}
                placeholder="Search by name or email..."
                onKeyDown={(e)=>handleKeyDown(e, CC_FIELD)}
                onFocus={() => setDropdownOpen(true)}
              />

              {dropdownOpen && searchTerm && (
                <div className="cstm_dropdown_holder">
                  <ul className="list_holder">
                    {filteredUserSuggestions.length > 0 ? (
                      filteredUserSuggestions.map((user) => (
                        <li key={user.id} onClick={() => addUser(user, CC_FIELD)}>
                          <div className="rounded_img_holder">
                            <img src={user.profilePicUrl} alt="image11" />
                          </div>
                          <div className="info_holder">
                            <p className="name_holder">{user.name}</p>
                            <p className="mail_holder">{user.email}</p>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li>No users found</li>
                    )}
                  </ul>

                  {/* {filteredUsers.length > 0 &&
                    <div className='btn_holder'>
                      <Button
                        color="inherit"
                        className='button primary_maroon_outlined_btn'
                      >
                        <i className="ti ti-x icon" />
                        <span>Cancel</span>
                      </Button>
                      <Button className='primary_maroon_btn'>
                        <i className="ti ti-check icon" />
                        <span>Proceed</span>
                      </Button>
                    </div>} */}
                </div>
              )}
            </div>

          </div>
          <span className='inline-block cstm_label_holder pb-12'>Description</span>
          <Editor
            apiKey="rizevt0is9zmdrbesb6gkhvjtzmi8wpejsr6f8qw0hoh6uj4"
            onEditorChange={onEditorChange}
            initialValue={forwardedContent}
            ref={editorRef}
            init={{
              placeholder: 'Type a response',
              height: 250,
              menubar: false,
              branding: false,
              file_picker_types: 'file',
              plugins: [
                'link', 'autolink', /*'quickbars',*/
                'advlist', 'autolink', 'lists', 'link', 'image','charmap', 'print', 'preview', 'anchor',
                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'paste', 'help', 'wordcount'
              ],
              toolbar: 
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | fileUploadButton',
              content_style: `  
                body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }
                .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #98A2B3; font-family: "Inter", sans-serif; font-size: 12px; font-style: normal; font-weight: 400; }
              `,
              setup: (editor) => {
                editor.ui.registry.addButton('fileUploadButton', {
                  text: '', // You can use text or an icon
                  icon: 'upload', // Use TinyMCE's built-in upload icon
                  tooltip: 'Upload File',
                  onAction: () => {
                    // Trigger the file picker callback manually
                    editor.settings.file_picker_callback(
                      (fileUrl) => {
                        editor.insertContent(`<a href="${fileUrl}" target="_blank">${fileUrl}</a>`);
                      },
                      '', 
                      { filetype: 'file' }
                    );
                  }
                });
              },
              file_picker_callback(callback, value, meta) {
                if (meta.filetype === 'file') { 
                  uploadFileHandler(callback, value, meta);
                }
              }
            }}
          />
          {Boolean(attachmentList.length) && <h2 style={{ margin: '10px 0px'}} className="form_label_input">Attachments: <br/></h2>}
          <div id="uploaded-files" className="uploaded_content_holder"/>
          <div className='text-right my-20'>
            {!isForwardBtnClick && <Button disabled={disableSendBtn} className='primary_maroon_btn mr-12' onClick={sendMessage}>
              <i className='ti ti-send icon' />
              <span>Send</span>
            </Button>}
            {isForwardBtnClick && <Button className='primary_maroon_btn mr-12' onClick={forwardMessages}>
              <i className='ti ti-send icon' />
              <span>Forward</span>
            </Button>}
            <Button className='primary_maroon_outlined_btn' onClick={() => handleCloseReply()}>
              <i className='ti ti-x icon' />
              <span>Cancel</span>
            </Button>
          </div>
        </div>
      </>}
      
      <div id="main_conversation">
        {loading ? <FuseLoading/> : 
          (!replyMode && !chatMessages.length) ? 
          <NoConversation 
            handleOpenReply={handleOpenReply}
            chatAccessParams={chatAccessParams}
          /> :
          chatMessages.map(( message, index ) => {
            return(
              <Fragment key={index}>
                <div className={`conversion_holder ${message.user_type === "User" ? 'agent_holder' : 'user_holder'}`}>
                  <div className='top_holder'>
                    <div className='left_holder'>
                      <div className='rounded_img_holder'>
                      {
                        message.user_photo_url === "no_image" ? (
                          <Avatar sx={{ height: '30px', width: '30px'}}/>
                        ) : (
                          <img src={message.user_photo_url} alt='' />
                        )
                      }
                      </div>
                      <div id="info_num_holder" className="information_holder">
                        <div className="upper_holder">
                          <div className='name_holder'>
                            {message.user_name}
                          </div>
                          <div className='badge_holder'>
                            {message.user_type}
                          </div>                    
                        </div>
                        <div className="lower_holder">
                          {/* <div className="each_info">
                            <span className="label_holder">to:</span> 
                            <span>Mary Jones</span>
                          </div> */}
                          {Boolean(message.cc_users.length) && <div className="each_info">
                            <span className="label_holder">CC:</span>
                            {message.cc_users.length === 1 && <span>{message.cc_users[0]}</span>}
                            {message.cc_users.length > 1 && (
                              <>
                                <span>{message.cc_users[0]}</span>
                                <span className="num_badge"
                                  onClick={(event)=>{
                                    showAllAdditionalUserClickIndex.current = index
                                    showAllAdditionalUsers(message.cc_users)
                                    handleMouseClick(event)
                                  }}
                                >+{message.cc_users.length - 1}
                                </span>
                              </>
                            )}
                          </div>}
                          {Boolean(message.forward_to.length) && <div className="each_info">
                            <span className="label_holder">Forwarded to:</span>
                            {message.forward_to.length === 1 && <span>{message.forward_to[0]}</span>}
                            {message.forward_to.length > 1 && (
                              <>
                                <span>{message.forward_to[0]}</span>
                                <span className="num_badge"
                                  onClick={(event)=>{
                                    showAllAdditionalUserClickIndex.current = index
                                    showAllAdditionalUsers(message.forward_to)
                                    handleMouseClick(event)
                                  }}
                                >+{message.forward_to.length - 1}
                                </span>
                              </>
                            )}
                          </div>}
                        </div>
                        {index == showAllAdditionalUserClickIndex.current && <div
                          ref={showAllAdditionalUsersRef}
                          style={{left: `${position.x}px`}}
                          className={`user_dropdown_holder ${usersListDropdown ? 'show' : ''}`}
                        >
                          {
                            restAdditionalUsers.map((user)=>{
                              return(
                                <div className="each_user">
                                  <div className="rounded_img_holder">
                                    <img src={user.profilePicUrl} alt="image12" />
                                  </div>
                                  <strong>{user.email}</strong>
                                </div>
                              )
                            })
                          }
                        </div>}
                      </div>
                    </div>
                    <div style={{ alignItems: 'center', gap: '20px'}} className='right_holder flexbox_holder'>
                      <div className="forward_arrow_holder" onClick={()=>onClickForwardBtn(index)}>
                        <i className="ti ti-arrow-forward-up"/>
                      </div>
                      <div>
                        <span className='date_holder'>{message.message_date}</span>
                        <span className='time_holder'>{message.message_time}</span>
                      </div>
                    </div>
                  </div>
                  <div className='bottom_holder'>
                    <p className='content_holder' dangerouslySetInnerHTML={{__html: message.message_content}} />
                  </div>
                  {
                    Boolean(message.chat_attachments.length) && 
                    <div className="uploaded_content_holder">
                      <h2 style={{ margin: '10px 0px'}} className="form_label_input">Attachments:</h2>
                      {
                        message.chat_attachments.map((attachment)=>{
                          return(
                            <p className="attachment_name_holder" onClick={()=>onClickDownloadFile(attachment)}>{attachment.file_name}</p>
                          )
                        })
                      }
                    </div>
                  }
                </div>
              </Fragment>
            )
          })
        }
      </div>

      {isFloatingButtonVisible && <div className="top_bottom_btn" onClick={handleScroll}>
        <i className={`ti ti-chevron-${scrollToBottom ? 'down' : 'up'}`}/>
      </div>}
    </div>
  )
});

export default Conversation;

function NoConversation({ handleOpenReply, chatAccessParams }){
  const router = useRouter()
  return(
    <div className='no_data_holder'>
      <img src='assets/images/nodata.svg' alt='icon' />
      <p className='bold_txt'>It's all empty here!</p>
      <p className='normal_txt'>There is no conversation to display</p>
      {
        /** If Agent is not assigned and status is not closed then button will appear as disable */
        (!chatAccessParams.isStatusClosed && (!chatAccessParams.isAgentAssignedToChat || !chatAccessParams.isUserAssignedToChat)) && (
          <Tooltip
            title="Conversation cannot be started as no agent is assigned to the ticket."
            componentsProps={{
              tooltip: {
                sx: {
                  color: '#ffffff',
                  borderRadius: '3px',
                  padding: '10px',
                  fontSize: '15px',
                  fontWeight: '400'
                }
              }
            }}
          >
            <span>
              <Button
                style={{ marginTop: 5, opacity: 0.5}}
                className={`primary_maroon_btn ${router?.query?.archived ? 'secondary_btn' : ''}`}
                onClick={() => handleOpenReply()}
                disabled
              >Start Conversation</Button>
            </span>
          </Tooltip>
        )
      }
      {
        /** If Agent is assigned and status is not closed then button will be functional */
        (!chatAccessParams.isStatusClosed && chatAccessParams.isAgentAssignedToChat && chatAccessParams.isUserAssignedToChat) && (
          <Button
            style={{ marginTop: 5}}
            className='primary_maroon_btn'
            onClick={() => handleOpenReply()}
          >Start Conversation</Button>
        )
      }
    </div>
  )
}