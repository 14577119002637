import React, { useEffect, useState, forwardRef } from 'react'
import {
  Tabs, Tab, Box, Typography, Checkbox, InputLabel,
  MenuItem, FormControl, Select
} from '@mui/material'
import { Popover, Button, Icon, TextField } from '@material-ui/core';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';
import FuseLoading from '@fuse/core/FuseLoading';
import { exportToCSV } from "@fuse/utils/generateCsv";
import exportExcel from "@fuse/utils/excel";
import multiFormatFileManager from "@fuse/utils/multiFormatFileManager";
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setGroupViewTabValue } from '../../../../src/app/main/reports/store/reportViewDialogReducer';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import useToast from "@fuse/hooks/useToast";
import useRouter from '@fuse/hooks/useRouter';
import { useCurrentUser } from '@fuse/hooks';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { SearchDropDownListPaginationComponent } from 'helper/SearchDropDownPaginationList';
import { getAllCompany } from 'app/api/company';
import { getAllUser } from 'app/api/user';
import { getAllAsset, getAllAssetAccessoryCategory, getAllAssetCategory, getAllAssetManufacturer, getAllAssetModel, getAllAssetServiceProvider, getAllAssetStatus } from 'app/api/asset';
import { getAllContract, getAllContractStatus, getAllContracType } from 'app/api/contract';
import { getAllDepartment, getAllLocation, getAllLocationType } from 'app/api/location';
import { getAllPriority, getAllStatus } from 'app/api/service-request';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AgGridFilterGroupView = ({ tableRef, setRowGroupPanelShow, groupByArrFromList,
  tableName, onClick = () => { }, logs = [], displayTableName = '', allArchiveId = [], bulkAssetArchive = () => { },
  handleServiceTicketCreation = () => { }, onClickAddButton = () => { } ,customFilterData = null, clearRedirectionFilter = () => { }, forActivity = false,
  assetActivity = false, serviceRequestTotalRecords, loadingLog=false, totalCustomer = 0, hideTableName = false, activityModuleName
}) => {
  const user = useCurrentUser();
  const [gridRef, setGridRef] = useState(null);
  const [columns, setColumns] = useState([])
  const [tabValue, setTabValue] = useState(0);
  const [columnHeaderAndValueMap, setColumnHeaderAndValueMap] = useState([]);
  const [dateInputValOne, setDateInputValOne] = useState({
    date: null,
    error: false
  })
  const [dateInputValTwo, setDateInputValTwo] = useState({
    date: null,
    error: false
  })
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [ fileDownloadLoader, setFileDownloadLoader ] = useState(null);
  const showActivity = ['asset','Model','Manufacture','asset_category','service_ticket_priority','asset_status','custom_attributes','user','service_provider',
  'accessory_category','Accessory', 'Contract','service_request_status', 'service_request_category','payment_term', 'service_request_source', 'service_request', 
  'agents', 'customer_management','contract_tag','Contract Types','service_level_agreement','shipping','location_type','department']
  const dropDownFilterModule = ['asset','Model','Archived Asset', 'Manufacture', 'asset_category', 'asset_status', 'custom_attributes', 'department','user',
  'Insight log','service_provider','service_request','Archived Service Tickets',
  'accessory_category', 'Accessory', 'Contract', 'Archived Contract','payment_term','contract_tag','Contract Types','service_level_agreement','shipping', 'location_type']
  const hideExportAs = ['service_ticket_priority', 'service_request_status', 'service_request_category', 
    'service_request_source', 'accessory_category', 'Accessory', 'custom_attributes', 'asset_status', 'asset_category',
    'payment_term', 'contract_tag', 'Contract Types', 'service_level_agreement', 'location_type', 'department',
    'customer_management', 'agents'
  ]
	const router = useRouter();
  const dropdownFilterColumns = ['Company Name', 'User', 'User Name', 'Agent Name', 'Status', 'Contract Number','Contract Tag', 
    'Model Name', 'Model Number', 'Category', 'Manufacturer', 'Location', 'Type', 'Service Provider', 'Client Name', 'Serial Number',
    'Priority', 'Status', 'Location Type Name', 'Department Name', 'Accessory Category']

  const asset_count = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.asset_count
  );

  const hideActivity = {
    'Archived Contract': true,
    'Archived Asset': true,
    'Insight log': true,
    'Archived Service Tickets': true,
    'Custom Report': true,
    'Data Report': true,
    'user_audit_logs': true,
    'Contract': true,
    'contract_tag': true,
    'Contract Types': true,
    'service_provider': true,
    'service_level_agreement': true,
    'payment_term': true
  };
  function handleChangeTab(event, newValue) {
    if(Object.keys(rowSelectionCheckbox).length === 0) return
    onClick(newValue)
    setTabValue(newValue)
    dispatch(setGroupViewTabValue(newValue))
  }

  function hideAddButton(){
    if(['Archived Service Tickets', 'user_audit_logs', 'activity_logs','Contract','Archived Contract','payment_term','asset_category','shipping','location_type','department','user','Insight log','service_provider','custom_attributes',
     'asset','Model','Accessory','accessory_category','asset_status','Archived Asset', 'Manufacture','contract_tag','Contract Types','service_level_agreement']?.includes(tableName)){
      return false
    }
    return true
  }

  useEffect(() => {
    requestIdleCallback(()=>{
      setGridRef(tableRef)
      setColumns((tableRef?.current?.columnApi?.getAllColumns())?.filter(item => item.colDef.headerName !== "Actions"))

    })
    
    // forActivity && setTabValue(1)
  }, [])

  /*=========================================== */

  /**************Row Selection***************** */
  const [rowSelected, setRowSelected] = useState(false)
  /*=========================================== */

  /**************Filter*********************** */
  const [filterAction, setFilterAction] = useState({
    "Contains": "contains",
    "Not Contains": "notContains",
    "Equal": "equals",
    "Not Equal": "notEqual",
    "Starts With": "startsWith",
    "Ends With": "endsWith"
  })
  const filterTemp = {
    column: '',
    action: '',
    inputVal: '',
    operator: '',
    actionTwo: '',
    inputValTwo: '',
    error: {
      actionError: false,
      actionTwoError: false
    }
  }
  // const filterAction = {
  //   "Contains": "contains",
  //   "Not Contains": "notContains",
  //   "Equal": "equals",
  //   "Not Equal": "notEqual",
  //   "Starts With": "startsWith",
  //   "Ends With": "endsWith"
  // }
  

  useEffect(()=>{
    setColumnHeaderAndValueMap((() => {
      return (tableRef?.current?.columnApi?.getAllColumns())?.filter(item => item.colDef.headerName !== "Actions")?.reduce((res, curr) => {
        res[curr.colDef.headerName] = curr.colDef.field;
        return res
      }, {})
    })())
    // requestIdleCallback(()=>{
    //   setColumnHeaderAndValueMap((() => {
    //     return (tableRef?.current?.columnApi.getAllColumns())?.filter(item => item.colDef.headerName !== "Actions")?.reduce((res, curr) => {
    //       res[curr.colDef.headerName] = curr.colDef.field;
    //       return res
    //     }, {})
    //   })())
    // })
    
    // setColumnHeaderAndValueMap(columns?.reduce((res, curr) => {
    //   res[curr.colDef.headerName] = curr.colDef.field;
    //   return res
    // }, {}))
  },[])

  

  const [openCustomFilterUi, setOpenCustomFilterUi] = useState(false)
  const [allFilter, setAllFilter] = useState([{ ...filterTemp }])
  const [initialFilterApplied, setInitialFilterApplied] = useState(false)

  function openFilter() {
    setOpenCustomFilterUi(true)
  }

  const handleColumnSelect = (event, index) => {
    // if(event.target.value?.includes('Date')){
    //   setFilterAction({
    //     "Equal": "equals",
    //     "Not Equal": "notEqual",
    //   })
    // }else{
    //   setFilterAction({
    //     "Contains": "contains",
    //     "Not Contains": "notContains",
    //     "Equal": "equals",
    //     "Not Equal": "notEqual",
    //     "Starts With": "startsWith",
    //     "Ends With": "endsWith"
    //   })
    // }
    setSelectedColumnName(event.target.value)
    setAllFilter(prevFilters => {
      prevFilters[index].column = event.target.value;
      prevFilters[index].inputVal = '';
      prevFilters[index].inputValTwo = '';
      return [...prevFilters];
    });
  }

  const getKeyName = (obj, searchValue) => {

    if(!searchValue) return ''

    for (const [key, value] of Object.entries(obj)) {
      if (value === searchValue) {
          return key 
      }
    }
  }

  const flattenFilter = (filterObj) => {
    const filterArr = []
    for (const [key, value] of Object.entries(filterObj)){
      if(filterObj[key]?.condition1 || filterObj?.condition2){
        if(key?.includes('date')){
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.condition1?.type),
            inputVal:{date: new Date(filterObj[key]?.condition1?.filter)?.toISOString(), error: false},
            operator: filterObj[key]?.operator,
            actionTwo: getKeyName(filterAction, filterObj[key]?.condition2?.type),
            inputValTwo: {date: new Date(filterObj[key]?.condition2?.filter)?.toISOString(), error: false}
          })
        }else{
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.condition1?.type),
            inputVal: filterObj[key]?.condition1?.filter,
            operator: filterObj[key]?.operator,
            actionTwo: getKeyName(filterAction, filterObj[key]?.condition2?.type),
            inputValTwo: filterObj[key]?.condition2?.filter,
          })
        }
      }else{
        if(key?.includes('date')){
          // setDateInputValOne({date: new Date().toISOString(), error: false})
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.type),
            inputVal: '',
            operator: filterObj[key]?.operator,
            actionTwo:'',
            inputValTwo: ''
          })
        }else{
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.type),
            inputVal: filterObj[key]?.filter,
            operator: filterObj[key]?.operator,
            actionTwo:'',
            inputValTwo: '',
            id:filterObj[key]?.id
          })

        }

      }

      setAllFilter(filterArr)
      
      // console.log(value, key)
    }
  }

  useEffect(()=>{
    const appliedFilter = tableRef?.current?.api?.getFilterModel()

    if(Object.keys(appliedFilter || {})?.length != 0 && !initialFilterApplied){
      const filter = tableRef?.current.filterModelArr
      setOpenCustomFilterUi(true)
      flattenFilter(filter)
      setInitialFilterApplied(true)
    }
  }, [tableRef?.current?.api?.getFilterModel()])

  const handleFilterActionSelect = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].action = event.target.value;
      prevFilters[index].error.actionError = false;
      return [...prevFilters];
    });
  }

  const handleFilterInput = (event, index) => {
    if((allFilter[index].column?.includes('Date') || allFilter[index].column?.includes('Time'))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = event
        return [...prevFilters];
      });
    } else if((dropdownFilterColumns.includes(allFilter[index].column.trimEnd()) && dropDownFilterModule?.includes(tableName))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = event
        return [...prevFilters];
      });
    }else {
      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = event.target.value;
        return [...prevFilters];
      });
    }
  }

  const handleAndOrBtn = (event, operator, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].operator = operator.toUpperCase();
      return [...prevFilters];
    });
  }

  const handleFilterActionSelectTwo = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].actionTwo = event.target.value;
      prevFilters[index].error.actionTwoError = false;
      return [...prevFilters];
    });
  }

  const handleFilterInputTwo = (event, index) => {
    if((allFilter[index].column?.includes('Date') || allFilter[index].column?.includes('Time'))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event;
        return [...prevFilters];
      });
    } else if((dropdownFilterColumns.includes(allFilter[index].column.trimEnd()) && dropDownFilterModule?.includes(tableName))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event
        return [...prevFilters];
      });
    } else {
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event.target.value;
        return [...prevFilters];
      });
    }
  }

  const addAnotherFilter = () => {
    setFilterAction({
      "Contains": "contains",
      "Not Contains": "notContains",
      "Equal": "equals",
      "Not Equal": "notEqual",
      "Starts With": "startsWith",
      "Ends With": "endsWith"
    })
    setAllFilter(prevArray => [...prevArray, { ...filterTemp }])
  }

  function formatDate(inputDate) {
    let dateObj = new Date(inputDate);

    let monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    let month = monthNames[dateObj.getMonth()];
    let day = ('0' + dateObj.getDate()).slice(-2);
    let year = dateObj.getFullYear();

    let outputDate = month + ' ' + day + ', ' + year;

    return outputDate;
  }


  function handleApplyFilter(customFilterColumn) {
    requestIdleCallback(()=>{if (gridRef?.current) {
      // Get the grid API
      const gridApi = gridRef.current.api;
      // for both side filled
      const filterModelArr = {}
      allFilter.forEach((item, index) => {
        if (item.inputVal && item.inputValTwo) {
          if (item.inputVal && item.inputValTwo && !item.operator) {
            toast.error('Please select and/or')
            return
          }
          // if (item.inputVal && item.inputValTwo && (!filterAction[item.action] || !filterAction[item.actionTwo])) {
          //   toast.error('Please select operator')
          //   return
          // }
          if(item.action === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
          }
          if(item.actionTwo === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionTwoError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
          }

          if((item.column?.includes("Date") || item.column?.includes("Time"))){
            filterModelArr[columnHeaderAndValueMap[item.column]] =  {
              filterType: "date",
              operator: item.operator,
              condition1: {
                filterType: "date",
                type: filterAction[item.action],
                filter: (tableName !='asset' && tableName != 'user_audit_logs') ? formatDate(item.inputVal) : item.inputVal
              },
              condition2: {
                filterType: "date",
                type: filterAction[item.actionTwo],
                filter: (tableName !='asset' && tableName != 'user_audit_logs') ? formatDate(item.inputValTwo) : item.inputValTwo
              }
            }
          } else {
            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] = {
              filterType: "text",
              operator: item.operator,
              condition1: {
                filterType: "text",
                type: filterAction[item.action],
                filter: typeof  item.inputVal === 'object' ?   item.inputVal.label : item.inputVal,
                id: item.inputVal.id
              },
              condition2: {
                filterType: "text",
                type: filterAction[item.actionTwo],
                filter: typeof  item.inputValTwo === 'object' ?   item.inputValTwo.label : item.inputValTwo,
                id: item.inputValTwo.id
              }
            }
          }
            
        }else if(item.inputVal){
          if(item.action === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
            return
          }
          if((item.column?.includes("Date") || item.column?.includes("Time"))){
            if(filterAction[item.action] != 'equals' && filterAction[item.action] !='notEqual'){
              toast.error(`Date filter can't work with ${item.action}`)
              return
            }

            filterModelArr[columnHeaderAndValueMap[item.column]] = {
              filterType: "date",
              type: filterAction[item.action],
              filter: (tableName !='asset' && tableName != 'user_audit_logs') ? formatDate(item.inputVal) : item.inputVal,
            }
          } else {
            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] = {
              filterType: "text",
              type: filterAction[item.action],
              filter: typeof  item.inputVal === 'object' ?   item.inputVal.label : item.inputVal,
              id: item.inputVal.id
            }
          }
        }
      })

      tableRef.current = {
        ...tableRef.current,
        filterModelArr: filterModelArr
      }

      gridApi.setFilterModel(filterModelArr);
    }})
    
  }

  const clearFilter = () => {
    requestIdleCallback(()=>{
      gridRef.current = {...gridRef.current,filterModelArr:{}}
      const gridApi = gridRef?.current?.api;
      gridApi.setFilterModel({});
      setOpenCustomFilterUi(false)
      setAllFilter([{ ...filterTemp }])
      clearRedirectionFilter()
    })
    
  }

  useEffect(() => {
    if (customFilterData) {
      handleColumnSelect({
        target: {
          value: Object.keys(customFilterData)[0]
        }
      }, 0)
      handleFilterInput({
        target: {
          value: Object.values(customFilterData)[0]
        }
      }, 0)
      handleFilterActionSelect({
        target: {
          value: "Equal"
        }
      }, 0)

      handleApplyFilter(Object.keys(customFilterData)[0].toLowerCase())
      // handleApplyFilter(Object.keys(customFilterData)[0])
      setOpenCustomFilterUi(true)
    }
  }, [customFilterData,  gridRef])
  /*=========================================== */


  /**************GroupBy*********************** */
  const [groupByEl, setGroupByEl] = useState(null);
  const openGroupByPopUp = Boolean(groupByEl);
  const [rowSelectionCheckboxGroupBy, setRowSelectionCheckboxGroupBy] = useState({})
  const [groupByArr, setGroupByArr] = useState([])
  const [groupByArrAsset, setGroupByArrAsset] = useState([])

  const setDefaultRowSelectionCheckboxGroupBy = () => {
    return (() => {
      const initialState = {};
      Object.keys(columnHeaderAndValueMap || {}).map(item => {
        initialState[item] = false;
      });
      return initialState;
    })
  }
  useEffect(() => {
    setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
  }, [columns])

  useEffect(() => {
    requestIdleCallback(()=>{
      if (!(gridRef?.current?.columnApi?.getRowGroupColumns().length)) {
        setGroupByArr([])
        setGroupByArrAsset([])
        setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
      }
    })
    
  }, [gridRef, groupByEl])

  useEffect(() => {
    if (!groupByArrFromList.length) {
      setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
      setRowGroupPanelShow(false)
      setGroupByArr([])
      setGroupByArrAsset([])
    } else {
      let newGroup = []
      Object.keys(rowSelectionCheckboxGroupBy).map((item) => {
        if (groupByArrFromList.find(ele => ele.colDef?.headerName == item)) {
          newGroup.push(item)
          setRowSelectionCheckboxGroupBy(prevArray => ({
            ...prevArray,
            [item]: true
          }))
        } else {
          setRowSelectionCheckboxGroupBy(prevArray => ({
            ...prevArray,
            [item]: false
          }))
        }
      })
      setRowGroupPanelShow('always')
      setGroupByArr(newGroup)
      setGroupByArrAsset(newGroup)
      // gridRef?.current?.columnApi?.setRowGroupColumns(col_ids);
    }
  }, [groupByArrFromList])

  // useEffect(()=>{
  //   handleGroupByCreate()
  // },[groupByArr])

  const handleGroupByCancel = () => {
    setRowGroupPanelShow('never')
    setGroupByArr([])
    setGroupByArrAsset([])
    const { columnApi } = gridRef?.current;
    columnApi.setRowGroupColumns([]);
    setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
    setGroupByEl(null)
  }

  const handleGroupByCreate = () => {
    setRowGroupPanelShow('always')
    let col_ids = []
    for (const key of groupByArr) {
      if (columnHeaderAndValueMap[key]) {
        col_ids.push(columnHeaderAndValueMap[key])
      }
    }

    gridRef?.current?.columnApi?.setRowGroupColumns(col_ids);
    setGroupByArrAsset(col_ids)
    setGroupByEl(null)
  }
  const dropdownClick = (event) => {
    setGroupByEl(groupByEl ? null : event.currentTarget)
  }

  const handleFilterClose = () => {
    setGroupByEl(null)
  }
  /*=========================================== */


  /**************Hide Column*********************** */
  const [rowSelectionCheckbox, setRowSelectionCheckbox] = useState({})
  const [filterElHideColumn, setFilterElHideColumn] = useState(null);
  const [moreActions, setMoreActions] = useState(null);
  const [totalShowColumn, setTotalShowColumn] = useState(null);

  useEffect(() => {
    setDefaultHideColumnCheckBox()
  }, [columns])

  function isHideColumnApply() {
    let columnState = gridRef?.current?.columnApi.getColumnState()
    const colPresent = columnState?.find(column => column.hide == true)
    if (colPresent) return true
    else return false
  }

  useEffect(() => {
    !isHideColumnApply() && setDefaultHideColumnCheckBox()
  }, [gridRef, filterElHideColumn])


  const setDefaultHideColumnCheckBox = () => {
    setRowSelectionCheckbox(() => {
      return columns?.reduce((res, curr) => {
        res[curr.colDef.headerName] = true
        return res
      }, {})
    })
  }

  const dropdownClickHideColumn = (event) => {
    setFilterElHideColumn(groupByEl ? null : event.currentTarget)
  }
  const handleFilterCloseHideColumn = () => {
    setFilterElHideColumn(null)
  }
  const openFilterHideColumn = Boolean(filterElHideColumn);
  const id_two = 'simple-popper_two'

  const handleHideColumn = () => {
    for (const key in rowSelectionCheckbox) {
      if (!rowSelectionCheckbox[key]) {
        gridRef?.current?.columnApi.setColumnVisible(columnHeaderAndValueMap[key], false);

      } else {
        gridRef?.current?.columnApi.setColumnVisible(columnHeaderAndValueMap[key], true);
      }
    }
    setFilterElHideColumn(null)
  }


  const dropdownClickMoreAction = (event) => {
    setMoreActions(event.currentTarget)
  }
  const handleFilterCloseMoreActions = () => {
    setMoreActions(null)
  }
  const openMoreAction = Boolean(moreActions);
  const id_more_action = 'simple-popper_more'

  useEffect(() => {
    if(rowSelectionCheckbox){
    setTotalShowColumn((Object.values(rowSelectionCheckbox).filter(val => val == true)).length)
    }
  }, [rowSelectionCheckbox])

  const handleHideColumnCloseButton = () => {
    for (const key in rowSelectionCheckbox) {
      gridRef?.current?.columnApi.setColumnVisible(columnHeaderAndValueMap[key], true);
    }
    setDefaultHideColumnCheckBox()
    setFilterElHideColumn(null)
  }
  /*=========================================== */


  /**************ServiceTicket***************** */
  // function handleServiceTicketCreation() {
  //   console.log('handleServiceTicketCreation');
  // }
  /*=========================================== */

  /**************Exports***************** */
  const [exportType, setExportType] = useState('')
  const handleChangeExport = (event) => {
    setExportType(event.target.value);
  };

  useEffect(async () => {
    if (exportType) {
      setFileDownloadLoader(true)
      await multiFormatFileManager(exportType,  tableName === 'activity_logs' ? `${activityModuleName}_activity_logs`  : tableName , gridRef)
      setFileDownloadLoader(false)
      setExportType('')
    }

  }, [exportType])


  /*=========================================== */
  const handleFilterDelete = (index) => {
    let newFilterArr = [...allFilter]
    newFilterArr.pop()
    setAllFilter(newFilterArr)
  }
  /*=============================================================*/

  /*********************************For Asset Service Ticket************** */

  useEffect(() => {
    if (allArchiveId.length) setRowSelected(true)
    else setRowSelected(false)
  }, [allArchiveId])


  useEffect(() =>{
    requestIdleCallback(()=>{if (gridRef?.current && columns.length !== 0) {
      for (let item of columns) {
        if (!item?.visible) {
          setRowSelectionCheckbox(prevArray => ({
            ...prevArray,
            [item.colDef.headerName]: false
          }))
        } 
        if (item?.rowGroupActive) {
          setRowSelectionCheckboxGroupBy(prevArray => ({
            ...prevArray,
            [item.colDef.headerName]: true
          }))
        }
      }
    }})
  },[gridRef?.current, columns])

  return (
    <div>
      {fileDownloadLoader && <FuseLoading />}
      {tableName === 'customer_management' && <div role="presentation" className="cstm_breadcrumbs_holder">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
          System Settings
          </Link>
          <span>{tableName === 'customer_management' ? 'Customer Management' :''}</span>
        </Breadcrumbs>
      </div>}
      <Box style={{ marginBottom: hideTableName ? '16px' : '0px' }} sx={{ width: '100%', marginTop: '36px' }}>
      
        {!hideTableName && <Typography
          style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}
          className='allAssets_text'
        >
          <div>
            {(displayTableName == 'All Assets') ? 'All Assets Grouping' : tableName === 'service_request' ? 'Listed Service Tickets' : 
            tableName === 'customer_management' ? 'Listed Customers': displayTableName} 
            {displayTableName == 'All Assets' && <span className='devices_text'>({asset_count})</span>}
            {(tableName === 'service_request' || tableName === 'agents') && serviceRequestTotalRecords && <span className='devices_text'> ({serviceRequestTotalRecords})</span>}
            {(tableName === 'customer_management') && totalCustomer && <span className='devices_text'> ({totalCustomer})</span>}
            
          </div>
        {tableName === 'service_request' &&
          <Button style={{marginRight: '2px'}} className='gradient_animated_button focus_mode_btn' onClick={()=>router.push("/service-tickets/focus-details")}>
            <i className='ti ti-bolt' />
            Focus Mode
          </Button>
        }
        
        </Typography>}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ backgroundColor: !hideTableName ? '#e9ebed' : '', padding: '8px', borderRadius: '8px', marginBottom: hideTableName ? '0px' : '10px' }}>
            {!hideTableName && <Tabs value={tableName == 'Archived Asset' ? null : tabValue} onChange={handleChangeTab} aria-label="basic tabs example" className='category_tabs'>
              <Tab className="tabs_exampleText" label={displayTableName} />
              {showActivity?.includes(tableName)  && <Tab className="tabs_exampleText" label="Activities" />}
            </Tabs>}
            {
             hideTableName && <Typography
              className='allAssets_text mb-0'
            >{displayTableName}</Typography>
            }
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {hideAddButton() && tabValue === 0 &&
                <div className='primary_maroon_btn icon_primary_btn filter_icon' onClick={()=>{
                  onClickAddButton()
                }}>
                  <i className='ti ti-plus icon'/>
                </div>
            }

            {rowSelected &&
              <Button onClick={bulkAssetArchive} className='create_button mr-12'><i className='ti ti-archive' />Archive</Button>
            }
            {rowSelected &&
              <Button onClick={handleServiceTicketCreation} className='create_button mr-12'><i className='ti ti-ticket' />Service Ticket</Button>
            }
            {/* Filter and Archive*/}
            {!rowSelected &&
              <div className="filter_icon" onClick={openFilter}>
                <i style={{ fontSize: '18px' }} className='ti ti-filter' />
              </div>
            }
            <div onClick={dropdownClickHideColumn} className='filter_icon'>
              <i className='ti ti-layout-columns' />
            </div>
            <Popover
              id={id_two}
              open={openFilterHideColumn}
              anchorEl={filterElHideColumn}
              onClose={handleFilterCloseHideColumn}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              style={{ marginTop: "1px" }}
            >
              <div className='dropdown_listGroup'>
                <div>
                  <Typography className='group_columnText'>Show Columns({totalShowColumn})</Typography>
                </div>
                <div style={{ height: '190px', overflowY: 'scroll' }} className='thinscrollbar_holder'>
                  {Object.keys(columnHeaderAndValueMap || {})?.map((item, index) => {
                    return (
                      <div className='checkbox_list'>
                        <span style={{ marginRight: '5px' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2657_16034)">
                              <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_2657_16034">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox className='checkbox_padding'
                            sx={{
                              color: '#eaecf0',
                              '&.Mui-checked': {
                                color: '#f66d69',
                              },
                            }}
                            checked={rowSelectionCheckbox[item]} onChange={() => {
                              const data = Object.keys(rowSelectionCheckbox).filter(key => rowSelectionCheckbox[key] === true);
                              if(data.length === 1 && rowSelectionCheckbox[item]){
                                toast.error('You cannot uncheck all columns!')
                              } else {
                                setRowSelectionCheckbox(prevArray => ({
                                  ...prevArray,
                                  [item]: !rowSelectionCheckbox[item]
                                }))
                            }

                            }} />

                          <Typography className='list_text'>{item}</Typography>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                <Box sx={{ display: 'flex', marginTop: '6px', padding: '4px 12px' }}>
                  <Button onClick={handleHideColumnCloseButton} className='cancel_button w-full'>Reset</Button>
                  <Button className='create_button w-full' onClick={handleHideColumn}>Create</Button>
                </Box>
              </div>
            </Popover>

            {rowSelected && <>
              <div className="filter_icon" onClick={dropdownClickMoreAction}>
                <i className='ti ti-dots-vertical' />
              </div>

              <Popover
                id={id_more_action}
                open={openMoreAction}
                anchorEl={moreActions}
                onClose={handleFilterCloseMoreActions}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className='cstm_dropwdown_holder'>
                  <div className='each_holder' onClick={openFilter}>
                    <i className='ti ti-eye' />
                    <span>Filter</span>
                  </div>
                  <div className='each_holder' onClick={dropdownClick}>
                    <i className='ti ti-archive' />
                    <span>Group By</span>
                    <i className='ti ti-chevron-down ml-2' />
                  </div>
                </div>
              </Popover>
            </>}

            {/* GroupBy and Create Service Tickets */}
            <Box sx={{ display: 'flex', alignItems: "center", marginRight: '5px' }}>
              {!rowSelected && <>
                <Button className='group_field' onClick={dropdownClick}>
                  Group By <Icon>
                    expand_more
                  </Icon>
                </Button>
              </>}
              <Popover
                id='simple-popper'
                open={openGroupByPopUp}
                anchorEl={groupByEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                style={{ marginTop: "1px" }}
              >
                <div className='dropdown_listGroup'>
                  <div>
                    <Typography className='group_columnText'>Group Column</Typography>
                  </div>
                  <div style={{ height: '202px', overflowY: 'scroll' }} className='thinscrollbar_holder'>
                    {Object.keys(columnHeaderAndValueMap || {}).map((item, index) => {
                      return (
                        <div className='checkbox_list'>
                          <span style={{ marginRight: '5px' }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_2657_16034)">
                                <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_2657_16034">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox className='checkbox_padding'
                              sx={{
                                color: '#eaecf0',
                                '&.Mui-checked': {
                                  color: '#f66d69',
                                },
                              }}
                              checked={rowSelectionCheckboxGroupBy[item]} onChange={() => {

                                setRowSelectionCheckboxGroupBy(prevArray => ({
                                  ...prevArray,
                                  [item]: !rowSelectionCheckboxGroupBy[item]
                                }))
                                let newArr = [...groupByArr]
                                const indexToRemove = newArr.indexOf(item);
                                if (indexToRemove !== -1) {
                                  newArr.splice(indexToRemove, 1);
                                } else {
                                  newArr.push(item)
                                }
                                setGroupByArr(newArr)
                              }} />
                            <Typography className='list_text'>{item}</Typography>
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                  <Box sx={{ display: 'flex', marginTop: '6px', padding: '4px 12px' }}>
                    <Button onClick={handleGroupByCancel} className='cancel_button w-full my-0'>Reset</Button>
                    <Button className='create_button w-full my-0' onClick={handleGroupByCreate}>Group</Button>
                  </Box>
                </div>
              </Popover>
            </Box>
            {/* Exporting */}
            {!hideExportAs?.includes(tableName) && <Box sx={{ minWidth: 108 }}>
              <FormControl fullWidth className='cstm_select_formcontrol'>
                {/* <InputLabel id="demo-simple-select-label">Export As</InputLabel> */}
                <Select
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className='export_field'
                  value={exportType}
                  onChange={handleChangeExport}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <span className="placeholder_title">Export As</span>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem disabled value="">
                    <span style={{ display: 'none' }} className="placeholder_title">Export As</span>
                  </MenuItem>
                  <MenuItem value={'CSV'}>CSV</MenuItem>
                  <MenuItem value={'Excel'}>Excel</MenuItem>
                  <MenuItem value={'PDF'}>PDF</MenuItem>
                </Select>
              </FormControl>
            </Box>}
          </Box>
        </Box>

        <CustomTabPanel className='custom_tab' value={tabValue} index={tabValue}>
          {openCustomFilterUi && <Box className="filter_box">
            <><Typography sx={{ color: "#1D2939", fontSize: '12px', fontWeight: '500', lineHeight: '16px', fontFamily: '"Inter", sans-serif', marginBottom: '10px' }}>FILTER</Typography>

              <Box sx={{}}>
                {allFilter.map((item, index) => {
                  return (
                    <>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: "#FCFCFD", border: '1px solid #EAECF0', padding: '10px', borderRadius: "4px", marginBottom: '5px' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol h-full p-4'>
                              <InputLabel id="demo-simple-select-label">Column Name</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                style={{maxWidth: '150px'}}
                                className='serialNumber_field h-full'
                                id="demo-simple-select"
                                value={item.column}
                                label="Column Name"
                                onChange={(e) => { handleColumnSelect(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                {Object.keys(columnHeaderAndValueMap || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol h-full' error={item.error.actionError}>
                              <InputLabel id="demo-simple-select-label">Start With</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='serialNumber_field h-full'
                                value={item.action}
                                label="Start With"
                                onChange={(e) => { handleFilterActionSelect(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                {(item.column?.includes("Date") || item.column?.includes("Time")) ? Object.keys({"Equal": "equals",
                            "Not Equal": "notEqual"}).map(item => (<MenuItem value={item}>{item}</MenuItem>)): Object.keys(filterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ margin: '5px' }}>
                            <FormControl style={{ width: 160 }} className='filterText_field deep_input_holder h-full'>
                              {/* <Controller
                                        name="filter_input"
                                        className="mb-16"
                                        defaultValue={''}
                                        control={control}
                                        render={({ field }) => (
                                            <> */}
                            {(!(item.column?.includes("Date") || item.column?.includes("Time")) && !(dropdownFilterColumns.includes(item.column.trimEnd()) && dropDownFilterModule?.includes(tableName))) && <TextField
                              // {...field}
                              variant='outlined'
                              placeholder='Filter Value'
                              required
                              value={item.inputVal}
                              onChange={(e) => { handleFilterInput(e, index) }}
                              style={{ background: 'white', height: '100%' }}
                              className='textfield_holder'
                            />}
                            {(['Client Name','Company Name'].includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
																			label="Filter Value"
																			module="Company"
																			searchEnabled={true}
                                      defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
																			listCall={getAllCompany}
																			emitItem={(value) => {
                                          handleFilterInput(value ? {id: value.id,label:value?.company_name} : null, index)  
																			}}
																			apiParams={{}}
																		/>}
                            {(['User', 'User Name','Agent Name'].includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User"
                                searchEnabled={true}
                                listCall={getAllUser}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                    handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                             {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && tableName !== 'service_request') && <SearchDropDownListPaginationComponent
																			label="Filter Value"
																			module="Asset Status"
																			searchEnabled={true}
                                      defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
																			listCall={getAllAssetStatus}
																			emitItem={(value) => {
                                          handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
																			}}
																			apiParams={{}}
																		/>}
                            {(item.column === 'Contract Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Number"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllContract}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {(item.column === 'Contract Tag' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Tag"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllContractStatus}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {(item.column === 'Model Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Name"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_name'}}
                              />}
                              {(item.column === 'Model Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Number"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_no'}}
                              />}
                              {(item.column.trimEnd() === 'Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Category"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetCategory}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Manufacturer' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Manufacturer"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetManufacturer}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Location' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllLocation}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Type' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Type"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllContracType}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Service Provider' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Provider"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetServiceProvider}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Serial Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Priority' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Priority"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllPriority}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && tableName === 'service_request') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Status"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllStatus}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Location Type Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location Type"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllLocationType}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Department Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Department"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllDepartment}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column.trimEnd() === 'Accessory Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory Category"
                                searchEnabled={true}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetAccessoryCategory}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {/* { groupByModule?.includes(tableName) &&
                              <TextField
                              // {...field}
                                variant='outlined'
                                placeholder='Filter Value'
                                required
                                value={item.inputVal}
                                onChange={(e) => { handleFilterInput(e, index) }}
                              />
                            } */}
                            {(item.column?.includes("Date") || item.column?.includes("Time")) && <CustomDatePickerV2
                              state={item.inputVal}
                              setState={setDateInputValOne}
                              required={false}
                              label=''	
                              onChangeFromParent = {(e)=>{handleFilterInput(e, index)}}									
                            />}	
                            {/* </> */}
                            {/* )}
                      /> */}
                            </FormControl>
                          </Box>
                        </Box>
                        <div style={{ margin: '5px' }} className="two_btns_holder">
                          {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='add_OrTabs'> */}
                          <Button className={`add_tabs_text ${allFilter[index].operator === 'AND' ? 'active' : ''}`} onClick={(e) => { handleAndOrBtn(e, "and", index) }}>and</Button>
                          <Button className={`dd_tabs_text ${allFilter[index].operator === 'OR' ? 'active' : ''}`} label="Or" onClick={(e) => { handleAndOrBtn(e, "or", index) }}>or</Button>
                          {/* </Tabs> */}
                        </div>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol' error={item.error.actionTwoError}>
                              <InputLabel id="demo-simple-select-label">Contains</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='serialNumber_field'
                                value={item.actionTwo}
                                label="Start With"
                                onChange={(e) => { handleFilterActionSelectTwo(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                
                                {(item.column?.includes("Date") || item.column?.includes("Time")) ? Object.keys({"Equal": "equals",
                            "Not Equal": "notEqual"}).map(item => (<MenuItem value={item}>{item}</MenuItem>)): Object.keys(filterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Box>

                        <Box sx={{ margin: '5px' }}>
                          <div style={{ width: 160 }} className='filterText_field deep_input_holder'>
                          {(!(item.column?.includes("Date") || item.column?.includes("Time")) && !(dropdownFilterColumns.includes(item.column.trimEnd()) && dropDownFilterModule?.includes(tableName))) && <TextField
                              // {...field}
                              variant='outlined'
                              placeholder='Filter Value'
                              required
                              value={item.inputValTwo}
                              onChange={(e) => { handleFilterInputTwo(e, index) }}
                            />}
                            {(['Client Name','Company Name'].includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
																			label="Filter Value"
																			module="Company"
                                      defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
																			searchEnabled={true}
																			listCall={getAllCompany}
																			emitItem={(value) => {
                                        handleFilterInputTwo(value ? {id: value.id,label:value?.company_name} : null, index)  
																			}}
																			apiParams={{}}
															/>}
                            {(['User', 'User Name', 'Agent Name'].includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllUser}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)    
                                }}
                                apiParams={{}}
                            />}
                             {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && tableName !== 'service_request') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset Status"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Contract Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Number"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContract}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Contract Tag' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Tag"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContractStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Model Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Name"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_name'}}
                              />}
                              {(item.column === 'Model Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Number"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_no'}}
                              />}
                              {(item.column.trimEnd() === 'Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Category"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetCategory}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Manufacturer' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Manufacturer"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetManufacturer}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Location' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllLocation}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Type' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Type"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContracType}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Service Provider' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Provider"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetServiceProvider}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Serial Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Priority' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Priority"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllPriority}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && tableName === 'service_request') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Status"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Location Type Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location Type"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllLocationType}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Department Name' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Department"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllDepartment}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column.trimEnd() === 'Accessory Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory Category"
                                searchEnabled={true}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetAccessoryCategory}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {/* { !groupByModule?.includes(tableName) &&
                              <TextField
                              // {...field}
                                variant='outlined'
                                placeholder='Filter Value'
                                required
                                value={item.inputValTwo}
                                onChange={(e) => { handleFilterInputTwo(e, index) }}
                              />
                            } */}
                            {(item.column?.includes("Date") || item.column?.includes("Time")) && <CustomDatePickerV2
                              state={item.inputValTwo}
                              setState={setDateInputValTwo}
                              required={false}
                              label=''	
                              onChangeFromParent = {(e)=>{handleFilterInputTwo(e, index)}}									
                            />}
                          </div>
                        </Box>  
                      </Box>                  
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: '6px' }}>
                        <>
                          {(allFilter.length - 1 === index) && <Button
                              className='add_anotherButton mt-0 mr-3 p-1'
                              onClick={addAnotherFilter}
                              startIcon={
                                <Icon fontSize="small" title="Add Another">
                                  add
                                </Icon>}>
                              Add Another</Button>
                            }
                            <div className="delete_filter_holder">
                              {(index !== 0) && <Icon onClick={() => { handleFilterDelete(index) }}>delete_outline_outlined</Icon>}
                            </div>
                          </>
                        </Box>
                      {/* </Box> */}
                    </>
                  )
                })}
                {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  className='add_anotherButton'
                  onClick={addAnotherFilter}
                  startIcon={
                    <Icon fontSize="small" title="Add Another">
                      add
                    </Icon>}>
                  Add Another</Button>
              </Box> */}
              </Box>
              <Box>
                <Button onClick={handleApplyFilter} className='apply_filterButton'>Apply Filter</Button>
                <Button onClick={clearFilter} className='apply_filterButton'>Clear Filter</Button>
              </Box>
            </>
          </Box>}

        </CustomTabPanel>
        {showActivity?.includes(tableName) && <CustomTabPanel className='custom_tab' value={tabValue} index={1}>
          <ActivityAgGridListingSameAsSam module={tableName} logs={logs}  loadingLog={loadingLog}/>
        </CustomTabPanel>}
      </Box>
    </div>
  )
}

export default AgGridFilterGroupView;