import axios from '@fuse/utils/axios'

const getAllLocation = (params) => axios.get(`/shipping/fetch-location-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllLocationType = (params) => axios.get(`/location-type/fetch-location-type-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllDepartment = (params) => axios.get(`/department/fetch-department-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);

export {
    getAllLocation,
    getAllLocationType,
    getAllDepartment
}
