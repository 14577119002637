import _ from "@lodash";
import React, { useState, useEffect, useRef } from "react";
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";
import useRouter from "@fuse/hooks/useRouter";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import useToast from "@fuse/hooks/useToast";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "styles/ag-grid-header-style.css";
import CustomFilter from "@fuse/components/AgGridFilterGroupView/components/CustomFilter";
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';

function Contracts({ companyId }) {
  const tableHeader = ["contract_number", "contract_name", "total_contract_value", "contract_type", "start_date",
    "end_date", "contract_status", "total_no_of_assets", "service_provider", "created_date", "updated_date"
  ]
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
  const [ rowData, setRowData ] = useState([])
  const dispatch = useDispatch();
  const gridRef = useRef();
  const router = useRouter();
  const toast = useToast(dispatch);
  const rowGroupPanelShow = 'never';
  const [ contractInsights, setContractInsights ] = useState({
    totalNoOfContracts: 'N/A',
    totalNoOfActiveContracts: 'N/A',
    totalNoOfExpiringContracts: 'N/A',
    totalNoOfExpiredContract: 'N/A'
  });

	const frameworkComponents = {
		customFilter: CustomFilter,
    customDropdownEditor: CustomDropdownEditor
	};


  function setHeaderName(header) {
    if (header == "contract_id") {
      return "Contract ID";
    } if (header == "shipping_id") {
      return "Shipping ID";
    } if (header == "billing_id") {
      return "Billing ID";
    } if (header == "client_users_id") {
      return "Client User ID";
    } if(header === "contract_name") {
      return "Contract Name"
    } 
      return header
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
    
  }

  const onGridReady = (params) => {};

  function flattenContract( contracts ){
    return contracts.map((contract)=>{
      return {
        "contract_number": contract.entity_external_platform_id,
        "contract_name": contract.contract_name,
        "total_contract_value": contract.total_contract_value ?? '',
        "contract_type": contract?.contract_headers_type?.display_name,
        "start_date": contract.contract_start_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_start_date) : "",
        "end_date": contract.contract_end_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_end_date) : "",
        "contract_status": contract?.contract_header_status?.contract_status_name,
        "status_color": contract?.contract_header_status?.status_color?.code,
        "total_no_of_assets": contract?.assets?.length,
        "service_provider": contract?.contract_service_provider?.service_provider_name,
        "created_date":  dateReformatTo_mmm_dd_yyyy(contract.created_at),
        "updated_date": dateReformatTo_mmm_dd_yyyy(contract.updated_at)
      }
    })
  }

  function getContracts(){
	  axios.get(`/end-user-contracts/list-all?company_id=${companyId}`).then((res)=>{
			const { data } = res.data;
      setRowData(flattenContract(data))
    }).catch((err)=>{
      console.log(err)
    });
	}

  function getContractInsights(){
    axios.get(`/super-admin/company-contract-insights/${companyId}`).then((res)=>{
      setContractInsights((prev)=>{
        return{
          ...prev,
          totalNoOfContracts: res.data.data.totalNoOfContracts !== 0 ? res.data.data.totalNoOfContracts : 'N/A',
          totalNoOfActiveContracts: res.data.data.totalNoOfActiveContracts !== 0 ? res.data.data.totalNoOfActiveContracts : 'N/A',
          totalNoOfExpiringContracts: res.data.data.totalNoOfExpiringContracts !== 0 ? res.data.data.totalNoOfExpiringContracts : 'N/A',
          totalNoOfExpiredContract: res.data.data.totalNoOfExpiredContract !== 0 ? res.data.data.totalNoOfExpiredContract : 'N/A'
        }
      })
    }).catch(err=>{
      console.log(err)
    })
  }

  useEffect(()=>{
		onGridReady()
    getContracts()
    getContractInsights()
	},[]);

  return (
    <>
      <div className='flexbox_holder justify-start'>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Total Contracts</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{contractInsights.totalNoOfContracts}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Active Contracts</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{contractInsights.totalNoOfActiveContracts}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Expiring Contracts</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{contractInsights.totalNoOfExpiringContracts}</span>
          </div>
        </div>
        <div className='each_grey_border_holder'>
          <div className='light_heading'>Expired Contracts</div>
          <div className='bottom_holder'>
            <span className='deep_heading'>{contractInsights.totalNoOfExpiredContract}</span>
          </div>
        </div>
      </div>    
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: windowHeight + 90,
          fontSize: "12px",
        }}
      >
        <AgGridReact
          ref={gridRef}
          suppressRowClickSelection
          onGridReady={onGridReady}
          animateRows
          rowData={rowData}
          rowHeight={30}
          // Set the desired sheet name
          defaultExcelExportParams={{
            sheetName: 'Dalos-Source'
          }}
          defaultColDef={{
            resizable: true
          }}
          // no rows overlay
          overlayNoRowsTemplate="<span>No Result Found</span>"
          // Row Grouping conditions
          showOpenedGroup
          suppressDragLeaveHidesColumns
          suppressMakeColumnVisibleAfterUnGroup
          // autoGroupColumnDef={{ minWidth: 500 }}
          rowGroupPanelShow={rowGroupPanelShow}
          onColumnMoved
          onColumnPinned
          onColumnVisible
          onSortChanged
          groupHeaderHeight={1}
          // headerHeight={15}
          floatingFiltersHeight={40}
          frameworkComponents={frameworkComponents}
          // status bar enabled on range selection
          enableRangeSelection
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agAggregationComponent",
              },
            ],
          }}
          onFilterChanged
          onColumnRowGroupChanged
          pagination
        >
          {tableHeader.map((header) => {   
              if (header == "contract_type") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName="Contract Type"
                    sortable
                    suppressSizeToFit
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                    flex = {1}
                    minWidth={200}
                   />
                );
              }
              if (header == "payment_term") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName="Payment Term"
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                    flex = {1}
                    minWidth={200}
                  />
                );
              }
              if (header == "location") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName="Location"
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                    flex = {1}
                    minWidth={200}
                  />
                );
              }
              if (header == "service_level_agreement") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName="Service Level agreement"
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                    flex = {1}
                    minWidth={200}
                  />
                );
              }
              if (header == "service_provider") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName="Service Provider"
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                    flex = {1}
                    minWidth={200}
                  />
                );
              }
              if (header == "contract_status") {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    cellEditorPopup
                    suppressSizeToFit
                    cellEditor='customDropdownEditor'
                    flex = {1}
                    minWidth={200}
                    headerName="Contract Status"
                    // editable={props.updateContractPermission}
                    cellRendererFramework={(event) => {
                        return (
                          <span style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 8px', backgroundColor:'transparent', lineHeight:'24px', position:'relative'}}>
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                marginTop: "2px",
                                borderRadius: "50%",
                                display: "inline-block",
                                backgroundColor:
                                  event.value?.split(",")[1] ??
                                  event.data?.status_color
                              }}
                             />
                            <span style={{ marginLeft: "5px" }}>
                              {event.value?.split(",")[0]}
                            </span>
                          </span>
                        );
                    }}
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                   />
                );
              } if (
                header == "shipping_id" ||
                header == "billing_id" ||
                header == "client_users_id"
              ) {
                if (header == "shipping_id") {
                  return (
                    <AgGridColumn
                      field={header}
                      key={header}
                      headerName="Location ID"
                      sortable
                      filter
                      headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                      flex = {1}
                      minWidth={200}
                      
                     />
                  );
                }
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName={setHeaderName(header)}
                    sortable
                    filter
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    flex = {1}
                    minWidth={200}
                    
                   />
                );
              } if (
                header == "start_date" ||
                header == "end_date"
              ) {
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName={setHeaderName(header)}
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    flex = {1}
                    minWidth={200}
                    
                    filter="text"
                   />
                );
              }
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    flex = {1}
                    minWidth={200}
                    headerName={setHeaderName(header)}
                    sortable
                    headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                    filter="text"
                   />
                );
                            
          })}
        </AgGridReact>          
      </div>      
    </>
  );
}

export default Contracts;
