import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState,useEffect, useRef } from 'react';
import { useDispatch,useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "PROVIDER";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [provider, setProvider] = useState(null)
	const [gridApi, setGridApi] = useState(null);
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

	function formatString(str){
        if(!str) return ''
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	const onGridReady = (params) =>{
		// props.endUsersAccounts.forEach((list) => {
		// 	for(let [fieldName, value] of Object.entries(list)){
		// 		if(value === '' || value == null) {
		// 			list[fieldName] = `No ${formatString(fieldName)}`
		// 		}
		// 	}
		// })
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	const frameworkComponents = {
		customFilter: CustomFilter,
	};


	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	const onGridSizeChanged = ()=>{
		// setWindowHeight(window.innerHeight - 290);
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "subscription provider";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data?.filter);
          params?.columnApi.applyColumnState({ state: order });
					params.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }


	function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

    const onColumnMoved = (params) => {
        const rows = params.columnApi.getColumnState();
        saveView(rows);
				dispatch(setrowGroupModel(rows))
    }

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];

			saveView(rows);
			dispatch(setrowGroupModel(rows));
	
			// if(first_row.colId !== 'ag-Grid-AutoColumn'){
			// 	saveView(rows);
			// 	dispatch(setrowGroupModel([]));
			// }else{
			// 	dispatch(setrowGroupModel(rows));
			// }
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const handleConfirm = (data) => {
		if(data.is_system_generated){
			toast.error("Default Subscription Provider can't be a deletable.")
			props.refresh()
			return
		}
		setProvider(data)
	}

	const handleClose = () => {
		setProvider(null)
	}

	function handelDelete(){
		axios.delete(`/cloud-provider/delete/${provider.id}?`).then((res)=>{
			if(res.status == 201){
				toast.error(res.data.message);
			} else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function handelEdit(data){
		if(data.is_system_generated){
			toast.error("Default Subscription Provider can't be a editable.")
			props.refresh()
			return
		}
		dispatch(setFromData(data))
		// setEditButtonClick(true)
		// setEditData(data)
		dispatch(openFormViewerDialog())
		
	}

	// function onCellValueChanged(e){
	// 	const payload = {
	// 		edit_id:e.data.id,
	// 		status_name:e.data.status_name_,
	// 		type:e.data.type,
	// 	}
	// 	axios.post('/asset-status/edit', payload).then(response => {
	// 		if(response.status == 201){
	// 			toast.success(response.data.message)
	// 			props.refresh()
	// 		} else {
	// 		//   onSuccess()
	// 		  toast.success('Asset status updated Successfully.')
	// 		  props.refresh()
	// 		//   props.fetchAllHardwareAssets()
	// 		}
			
		  
	// 	}).catch(err => {
	// 	  console.log('212=>',err);
	// 	  toast.error('Something went wrong!')
	// 	})

	// }

	function editCloseForm(data){
		if(data){
			setEditDone(true)
			setEditButtonClick(false)
		}
	}

	function onCellValueChanged(e){
		if(e.data.is_system_generated){
			toast.error("Default Subscription Provider can't be a editable.")
			props.refresh()
			return
		}
		let provider_name = e.data.provider_name
		let phone = null
		let address_1 = null 
		let address_2 = null 
		let city = null 
		let state = null
		let zip = null
		let country = e.data.original_info.country
		let url = e.data.url.includes('www')?e.data.url:"www."+e.data.url
		
		if(e.colDef.field == "service_provider_name"){
			provider_name = e.newValue
		}
		if(e.colDef.field == "phone"){
			phone = e.newValue
		}
		if(e.colDef.field == "address_1"){
			address_1 = e.newValue
		}
		if(e.colDef.field == "address_2"){
			address_2 = e.newValue
		}
		if(e.colDef.field == "city"){
			city = e.newValue
		}
		if(e.colDef.field == "state"){
			state = e.newValue
		}
		if(e.colDef.field == "zip"){
			zip = e.newValue
		}
		if(e.colDef.field == "url"){
			url = e.newValue
		}
		if(e.colDef.field == "country"){
			country = e.newValue
		}
		const payload = {
			provider_name: provider_name,
			phone: phone ? e.data.phone : e.data.original_info.phone,
      address_1: address_1 ? e.data.address_1 : e.data.original_info.address_1,
      city: city ? e.data.city : e.data.original_info.city,
      state: state ? e.data.state : e.data.original_info.state,
      zip: zip ? e.data.zip : e.data.original_info.zip,
			country: country || '',
			url: e.data.url.includes('www') ? e.data.url : "www."+e.data.url,
			edit_id: Number(e.data.id)
		}
		if(phone && !phone.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/)){
			toast.error('Phone number should be in 000-000-0000 format')
			props.refresh()
			return
		}
		if(!payload.provider_name){
			toast.error('Subscription Provider Name is required')
			props.refresh()
			return
		  }
		if(url.indexOf('.') == -1){
			toast.error('Please enter a valid URL')
			props.refresh()
			return
		  }
		  axios.post(`/cloud-provider/edit`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('Service Provider updated successfully')
				props.refresh()
			}		  
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])

	function getHeaderName(header){
		// return header
		if(header == 'address_1'){
			return 'Address Line 1'
		}
		if(header == 'url'){
			return 'URL'
		}
		if(header == 'address_2'){
			return 'Address Line 2'
		}
		if( header === "state" ) {
			return 'State/Province';
		}
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//Logs
	function getLogs(){
		axios.get('/cloud-provider/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log(err)
			console.log('cannot fetch logs');
		})
	}

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				provider_name: item?.cloud_provider?.provider_name || item.provider_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

	return (	
		<>
			{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'subscription_provider'} logs={logs} displayTableName = {'Subscription Providers'}
				 onClick={handleChildClick}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListing module={'subscription_provider'} logs={logs} displayTableName = {'Subscription Providers'}/>)}

			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+165,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={props.endUsersAccounts}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowHeight = {30}
					onGridSizeChanged = { onGridSizeChanged }
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}
					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					//row grouping
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					// autoGroupColumnDef={{ minWidth: 5000 }}
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					<AgGridColumn 
						lockPosition={true}
						rowSelection={'single'}
						width={120}
						minWidth={120}
						maxWidth={120}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
									{/* props.updateAssetStatusPermission && */}
									{/* props.deleteAssetStatusPermission && */}
										{props.updateCProviderPermission && <Tooltip id="edit" title="Edit" placement="top">
											<Icon 
												onClick={() => handelEdit(data)} 
												style={{
														color: '#1d2939',
														paddingBottom: '3px',
														paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}
												>
													edit
												</Icon>
										</Tooltip>}
										{props.deleteCProviderPermission && <Tooltip id="delete" title="Delete" placement="top">
											<Icon 
												onClick={() => handleConfirm(data)} 
												style={{
													color: '#1d2939',
													paddingBottom: '3px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>
												delete
											</Icon>
										</Tooltip>}
									</>
									)
							}else{
								return(<></>)
							}

						}}
					>
					</AgGridColumn>
					{Object.keys(props.endUsersAccounts[0]).map(item=>{
						if(item != 'id'&& item!='original_info' && item!='preview_image_link' && item!='image_link' && item!='image_name'){
							if(item == 'country'){
								return (
									<AgGridColumn 
										key = {item}
										field = {item}
										suppressSizeToFit = {true}
										suppressRowClickSelection = {true}
										editable = {props.updateCProviderPermission}										
										onCellValueChanged = {onCellValueChanged}
										headerName = {getHeaderName(item)}  
										sortable = {true}
										filter = "text"
										flex = {1}
										minWidth={200}
										headerComponentFramework={(props)=>(CustomFilter(props, item, gridRef))}
										
										cellEditorParams= {{values:countries.map(item=>`${item.name}`)}}
										cellRenderer= 'GenderRenderer'
										cellEditor= 'agRichSelectCellEditor'
									>
									</AgGridColumn>
								)
							}
						return(
							<AgGridColumn 
								field={item}
								headerName={getHeaderName(item)} 
								editable = {props.updateCProviderPermission}										
								onCellValueChanged = {onCellValueChanged}
								sortable={ true }
								headerComponentFramework={(props)=>(CustomFilter(props, item, gridRef))}
								
								filter="text"
								flex = {1}
								minWidth={200}
							>
							</AgGridColumn>
						)}

					})}
				</AgGridReact>
				<GeneralModal
					open={provider}
					title={'Delete Subscription Provider'}
					text={<h4>Are you sure to delete this Subscription Provider named as <b>{provider?.provider_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/>
			</div>}
		</>
	);
}

export default ListView
