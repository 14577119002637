import { React, useState, useEffect, useRef } from "react";
import useRouter from '@fuse/hooks/useRouter';
import { Button, Box, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem, Tooltip, Popover } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { Editor } from '@tinymce/tinymce-react';
import NotesIcon from '../icons/emojione-v1_note-pad.png';
import axios from "@fuse/utils/axios";
import { stripHtml } from "helper/common";
import ListViewDetails  from './details';
import TablePaginationLayout from "@fuse/components/general/TablePaginationLayout";
import GeneralArchieveModal from "@fuse/components/Modal/GeneralArchieveModal";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});


function ListView1(props) {
	const router = useRouter();
	const gridRef = useRef();
	const [serviceTabValue, setServiceTabValue] = useState(0);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);
	const [openAddNote, setOpenAddNote] = useState(false);
	const [replyMode, setReplyMode] = useState(false);
	const [toggleDesc, setToggleDesc] = useState(false);
	const [status, setStatus] = useState('open');
	const [agentName, setAgentName] = useState(1);
	const [serviceRequest, setServiceRequest] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
	const [totalRecords,setTotalRecords] = useState(0);
	const [isLoading,setIsLoading] = useState(0);
	const [serviceRequestId,setServiceRequestId] = useState(0);
    const [trigger, setTrigger] = useState(new Date());
    const [isArchieve, setIsArchieve] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleListClick = (event,data) => {
        event.stopPropagation()
        setModalData(data);
        setAnchorEl(event.currentTarget);
    };

    const handleClickOpen = (event,data) => {
		setServiceRequestId(data?.id)
        setTrigger(+new Date())
	}

    const handleListClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;	

    function getServiceRequest(){
        let url =''
        if(search !== ''){
            url = `/service/show-paginated-service-request-list?search=${search}&page=${page}`
        }else{
            url= `/service/show-paginated-service-request-list?page=${page +1}`
        }
		axios.get(url).then((res) => {
			const { data } = res.data.data;
			setServiceRequest(data)
            setServiceRequestId(data[0]?.id)
			setTotalRecords( res.data.data.meta?.total)
            if(search !== ''){
                setTrigger(+new Date())
            }
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setIsLoading(false)
		});
	}
    
    useEffect(()=>{
        setIsLoading(true)
		getServiceRequest()
    },[])

    const handleArchive = (data) => {
		setIsArchieve(true)
	};

    function handleClose() {
		setIsArchieve(false)
        handleListClose()
		setModalData(null);
	}

    function handleConfirm() {
		axios.delete(`/service/archived-service-request/${modalData.id}`).then(() => {
			handleClose();
			getServiceRequest();
			toast.success("Service Ticket archived Successfully.");
		}).catch((err) => {
			console.log("err", err.response);
		});
	}

    useEffect(()=>{
        getServiceRequest()
    }, [search])

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		setPage(0)
		setRowsPerPage(rowsPerPage);
	}

    useEffect(()=>{
        getServiceRequest()
    }, [page])

    function refresh() {
        getServiceRequest()

	}
	
	return (	
        <>
        {!isLoading &&
            <div className='flex'>
                <div className='list_search_holder py-24'>
                    <div className='search_holder'>
                        <input type='text' placeholder='Search tickets by subject, number...' value={search} onChange={(e) => setSearch(e.target.value)}/>
                        <i className='ti ti-search' />
                    </div>
                    {serviceRequest?.length > 0 &&
                    <>
                        <ul className='listing_holder'>
                            {serviceRequest.map((item) => 
                                <li className={`each_list_holder ${item.id === serviceRequestId ? 'active' : ''}`} onClick={(e) => item.id === serviceRequestId ? e.preventDefault() : handleClickOpen(e,item)}>
                                    <p className='id_holder' >(Ticket no : {item?.ticket_id})</p>
                                    <div className='flex justify-between items-center mb-8'>
                                        <h4 className='heading_holder'  >{item?.title}</h4>
                                        <i style={{ fontSize: '24px', color: '#667085', cursor:'pointer' }} className='ti ti-dots-vertical' aria-describedby={id} variant="contained" onClick={(e) =>handleListClick(e, item)} />
                                    </div>
                                    {item.id === modalData?.id &&
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleListClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className="cstm_popover_holder">
                                            <div className='action_list' onClick={(e) => handleArchive(e,item)}>
                                                <i className='ti ti-archive'/>
                                                <span className='action_listText'>Archive</span>
                                            </div>
                                        </div>
                                    </Popover>
                                    }
                                    <p className='content_holder'>{stripHtml(item?.description)}</p>
                                </li>
                            )}
                        </ul>
                    
                        <div className="p-20">
                            <TablePaginationLayout
                                className="flex-shrink-0 border-t-1 mt-10"
                                component="div"
                                count={totalRecords}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page'
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page'
                                }}
                                onChangePage={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showRowsPerPage={false}
                            />
                        </div>
                    </>
                    }
                    {serviceRequest?.length === 0 &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}>No Data Found</div>
                    }
                </div>
                {serviceRequestId && 
                    <ListViewDetails key={trigger} serviceRequestId={serviceRequestId}/>
                }

                {isArchieve && (
                    <GeneralArchieveModal
                        open={isArchieve}
                        title={"Are you sure to archive service ticket?"}
                        text={"Your ticket will be moved to archive service tickets if you proceed"}
                        handleClose={handleClose}
                        handleConfirm={handleConfirm}
                        actionButtonName="Archive"
                    />
			    )}
            </div>
        }
            {/* exit mode */}
            <div className='gradient_box_holder'>
                <div className='alert_holder'>
                    <p className='pr-10'>You are now in focus mode</p>
                    <Button className='gradient_animated_button' onClick={()=>router.back()}>Exit</Button>
                </div>
            </div>
        </>
	);
}

export default ListView1